import React from "react";

const Eye = () => (
  <path
    d="M6 10c0-2.188 1.781-4 4-4 2.188 0 4 1.813 4 4 0 2.219-1.813 4-4 4-2.219 0-4-1.781-4-4Zm4 2.5c1.375 0 2.5-1.094 2.5-2.5 0-1.375-1.125-2.5-2.5-2.5h-.094c.063.188.094.344.094.5 0 1.125-.906 2-2 2-.188 0-.344 0-.5-.063V10c0 1.406 1.094 2.5 2.5 2.5ZM3.969 5.531C5.438 4.156 7.469 3 10 3c2.5 0 4.531 1.156 6 2.531 1.469 1.344 2.438 2.969 2.906 4.094.094.25.094.531 0 .781C18.438 11.5 17.47 13.125 16 14.5c-1.469 1.375-3.5 2.5-6 2.5-2.531 0-4.563-1.125-6.031-2.5-1.469-1.375-2.438-3-2.906-4.094a1.12 1.12 0 0 1 0-.781C1.53 8.5 2.5 6.875 3.969 5.531ZM10 4.5c-2.063 0-3.719.938-5 2.125A11.176 11.176 0 0 0 2.531 10c.438.938 1.25 2.281 2.469 3.406 1.281 1.188 2.938 2.094 5 2.094 2.031 0 3.688-.906 4.969-2.094C16.188 12.281 17 10.937 17.438 10a11.177 11.177 0 0 0-2.47-3.375C13.689 5.437 12.032 4.5 10 4.5Z"
    fill="currentColor"
  />
);

export default Eye;
