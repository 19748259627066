import { useCallback, useEffect, useMemo, useState } from "react";

import { SerialisedMessage } from "../api/messaging";
import { get, post } from "./request";

export interface Message {
  id: number;
  createdAt: Date;
  body: string;
  redacted?: boolean;
  fromCaseworker: boolean;
  fromReporter: boolean;
}

export default function useMessages(authCode: string) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);
  const [messages, setMessages] = useState<Message[]>([]);

  const ingestMessages = useCallback((...newMessages: SerialisedMessage[]) => {
    setMessages((existingMessages) => {
      const newList = [...existingMessages];
      for (const { id, createdAt, body, redacted, fromCaseworker } of newMessages) {
        const i = newList.findIndex((message) => message.id === id);
        if (i !== -1) newList.splice(i, 1);
        newList.push({
          id,
          createdAt: new Date(createdAt),
          body,
          redacted,
          fromCaseworker,
          fromReporter: !fromCaseworker,
        });
      }
      return newList;
    });
  }, []);

  const fetchMessages = useCallback(async () => {
    const result = await get<{ messages: SerialisedMessage[] }>("/messages", authCode);
    ingestMessages(...result.messages);
  }, [authCode, ingestMessages]);

  useEffect(() => {
    setLoading(true);
    fetchMessages()
      .then(() => setError(false))
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  }, [fetchMessages]);

  useEffect(() => {
    const h = setInterval(() => fetchMessages(), 60000);
    return () => clearInterval(h);
  }, [fetchMessages]);

  const postMessage = useCallback(
    async (message: string) => {
      const result = await post<{ message: SerialisedMessage }>("/messages", authCode, { message });
      ingestMessages(result.message);
    },
    [authCode, ingestMessages],
  );

  const sortedMessages = useMemo(
    () => messages.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()),
    [messages],
  );

  return useMemo(
    () => ({ loading, error, messages: sortedMessages, post: postMessage }),
    [error, loading, postMessage, sortedMessages],
  );
}
