import React from "react";

const FolderDownload = () => (
  <path
    d="M16 5h-5.406L9.063 3.594A1.988 1.988 0 0 0 7.655 3H4c-1.125 0-2 .906-2 2v10c0 1.125.875 2 2 2h12c1.094 0 2-.875 2-2V7c0-1.094-.906-2-2-2Zm.5 10c0 .281-.25.5-.5.5H4a.494.494 0 0 1-.5-.5V5c0-.25.219-.5.5-.5h3.656c.125 0 .25.063.344.156L10 6.5h6c.25 0 .5.25.5.5v8Zm-4.531-4.531-1.219 1.25V8.5a.76.76 0 0 0-.75-.75.74.74 0 0 0-.75.75v3.219L8 10.469c-.313-.281-.781-.281-1.063 0-.312.312-.312.781 0 1.062l2.5 2.5a.753.753 0 0 0 .563.219.622.622 0 0 0 .5-.219l2.5-2.5a.684.684 0 0 0 0-1.031.709.709 0 0 0-1.031-.031Z"
    fill="currentColor"
  />
);

export default FolderDownload;
