import React from "react";

const Clock = () => (
  <path
    d="M9.25 5.75A.74.74 0 0 1 10 5a.76.76 0 0 1 .75.75v3.875l2.656 1.75c.344.25.438.719.188 1.063a.718.718 0 0 1-1.031.187l-3-2A.77.77 0 0 1 9.25 10V5.75ZM10 2c4.406 0 8 3.594 8 8 0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8 0-4.406 3.563-8 8-8Zm-6.5 8c0 3.594 2.906 6.5 6.5 6.5 3.563 0 6.5-2.906 6.5-6.5 0-3.563-2.938-6.5-6.5-6.5-3.594 0-6.5 2.938-6.5 6.5Z"
    fill="currentColor"
  />
);

export default Clock;
