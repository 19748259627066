import React, {
  CSSProperties,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import useGeneratedId from "../../../hooks/useGeneratedId";
import { Translatable, useTranslate } from "../../translation";
import { Closable } from "./closable";
import styles from "./styles.module.scss";

const tabContext = createContext<{
  registerTab: (id: string, label: string, hasAlert: boolean) => void;
  unregisterTab: (id: string) => void;
  currentTab: string;
  requestTab: (tab: string) => void;
}>({
  registerTab() {
    throw new Error("You should not use Tab outside TabbedContent");
  },
  unregisterTab() {
    throw new Error("You should not use Tab outside TabbedContent");
  },
  currentTab: "",
  requestTab() {
    throw new Error("You should not use Tab outside TabbedContent");
  },
});

interface TabDefinition {
  id: string;
  label: string;
  active: boolean;
  hasAlert: boolean;
}

function useCurrentTab(
  defaultTab: string | undefined,
  param: string | undefined,
  tabs: TabDefinition[],
  clearSearch: boolean,
) {
  const urlParams = useParams();
  const navigate = useNavigate();

  // Ignored if `param` is set
  const [stateTab, setStateTab] = useState(defaultTab ?? "");

  const currentTab = useMemo(() => {
    if (param && urlParams[param]) return urlParams[param]!;
    if (!param && stateTab) return stateTab;
    if (defaultTab) return defaultTab;
    if (tabs.length) return tabs[0].id;
    return "";
  }, [defaultTab, param, stateTab, tabs, urlParams]);

  const [urlParts, setUrlParts] = useState<{ [id: string]: { search: string; hash: string } }>({});

  const navToTab = useCallback(
    (tab: string) => {
      if (tab === currentTab) return;
      const parts = document.location.pathname.split("/");
      if (!parts[parts.length - 1]) parts.pop();
      if (param! in urlParams) parts.pop();
      parts.push(tab);
      if (clearSearch) {
        setUrlParts((state) => ({
          ...state,
          [currentTab]: { search: document.location.search, hash: document.location.hash },
        }));
        const newParts = urlParts[tab] ?? { search: "", hash: "" };
        navigate(parts.join("/") + newParts.search + newParts.hash);
      } else {
        navigate(parts.join("/") + document.location.search + document.location.hash);
      }
    },
    [currentTab, param, urlParams, clearSearch, urlParts, navigate],
  );

  if (param) {
    return [urlParams[param] ?? defaultTab ?? tabs[0]?.id ?? "", navToTab] as const;
  } else {
    return [stateTab || (tabs[0]?.id ?? ""), setStateTab] as const;
  }
}

export interface TabbedContentProps {
  defaultTab?: string;
  tab?: string;
  onChangeTab?: (tab: string) => void;
  variant?: "default" | "progress";
  className?: string;
  style?: CSSProperties;
  urlParam?: string;
  /** if urlParam is set, this controls whether ?search=and#hash parts of the URL should be kept or cleared when changing tabs */
  clearSearch?: boolean;
}

export default function TabbedContent({
  defaultTab,
  tab,
  onChangeTab,
  children,
  variant = "default",
  className,
  style,
  urlParam,
  clearSearch = false,
}: PropsWithChildren<TabbedContentProps>) {
  const tabPanelId = useGeneratedId();

  const [tabs, setTabs] = useState<TabDefinition[]>([]);
  const [currentTab, setCurrentTab] = useCurrentTab(defaultTab, urlParam, tabs, clearSearch);

  const registerTab = useCallback((id: string, label: string, hasAlert: boolean) => {
    const newTab = { id, label, active: true, hasAlert };
    setTabs((tabs) => {
      // Support renaming tabs...
      if (tabs.some((tab) => tab.id === id)) {
        return tabs.map((tab) => (tab.id === id ? newTab : tab));
      }
      // ... but otherwise append the new tab.
      return [...tabs, newTab];
    });
  }, []);

  const unregisterTab = useCallback((id: string) => {
    // Rather than removing the tab, just flag it as inactive. This is because otherwise renaming a tab would cause it to jump to the end of the list as it gets unregistered and then re-registered.
    setTabs((tabs) => tabs.map((tab) => (tab.id === id ? { ...tab, active: false } : tab)));
  }, []);

  useEffect(() => {
    // This allows you to use the component in a managed way.
    if (tab) setCurrentTab(tab);
  }, [tab, setCurrentTab]);

  // Wrapping curried functions in useCallback doesn't work and it's not worth creating a nested component to fix this one
  function changeTab(id: string) {
    return () => {
      setCurrentTab(id);
      onChangeTab?.(id);
    };
  }

  // If multiple tabs request focus at the same time, prioritise the first in the list.
  const [requestedTab, setRequestedTab] = useState<string | null>(null);
  const requestTab = useCallback(
    (newTab: string) =>
      setRequestedTab((currentTab: string | null) => {
        if (!currentTab) return newTab;
        const currentI = tabs.findIndex((tab) => tab.id === currentTab);
        const newI = tabs.findIndex((tab) => tab.id === newTab);
        return currentI > newI ? newTab : currentTab;
      }),
    [tabs],
  );
  useEffect(() => {
    if (requestedTab) {
      setCurrentTab(requestedTab);
      setRequestedTab(null);
      onChangeTab?.(requestedTab);
    }
  }, [onChangeTab, setCurrentTab, requestedTab]);

  const activeTabs = useMemo(() => tabs.filter(({ active }) => active), [tabs]);

  return (
    <tabContext.Provider value={{ registerTab, unregisterTab, currentTab, requestTab }}>
      {activeTabs.length > 1 ? (
        <div className={`${styles.TabBar} ${styles[variant]}`} style={style}>
          <ul className={styles.TabBarList} role="tablist">
            {activeTabs.map(({ id, label, hasAlert }) => (
              <li key={id} className={`${hasAlert ? styles.hasAlert : ""} ${styles.TabBarItem}`}>
                <button
                  className={`${styles.TabBarLink} ${currentTab === id ? styles.active : ""}`}
                  onClick={changeTab(id)}
                  aria-selected={currentTab === id}
                  type="button"
                  role="tab"
                  aria-controls={tabPanelId}
                >
                  {variant === "progress" ? <div className={styles.TabBarLinkBefore} /> : null}
                  <span className={styles.TabBarLinkLabel}>{label}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      <div className={className} id={tabPanelId}>
        {children}
      </div>
    </tabContext.Provider>
  );
}

export interface TabProps {
  id?: string;
  label: Translatable;
  headerClass?: string;
  longTitle?: Translatable;
  className?: string;
  hasAlert?: boolean;
}

export function Tab({
  id: idProp,
  label,
  children,
  className,
  headerClass,
  longTitle,
  hasAlert = false,
}: PropsWithChildren<TabProps>) {
  const translate = useTranslate();

  const { registerTab, unregisterTab, currentTab, requestTab } = useContext(tabContext);

  const id = useGeneratedId(idProp);

  useEffect(() => {
    registerTab(id, translate(label), hasAlert);
    return () => unregisterTab(id);
  }, [id, label, registerTab, unregisterTab, translate, hasAlert]);

  const header = useRef<HTMLHeadingElement>(null);
  const ref = useRef<HTMLElement>(null);

  // We want to focus the header when moving from another tab to this tab — not on first render, and not when the tab first registers and the view moves from the default "no tab" state to this tab.
  const [anotherTabHasBeenSelected, setAnotherTabHasBeenSelected] = useState(false);
  useEffect(() => {
    if (currentTab && currentTab !== id) setAnotherTabHasBeenSelected(true);
  }, [currentTab, id]);
  useEffect(() => {
    if (currentTab === id && anotherTabHasBeenSelected) header.current?.focus();
  }, [currentTab, anotherTabHasBeenSelected, id]);

  // If the tabs are in a form, and the form is submitted, and an element is invalid, we want to open this tab so the user can see that it's invalid.
  const onInvalid = useCallback(() => {
    requestTab(id);
    const invalidElement: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement = ref.current!.querySelector(
      "input:invalid, select:invalid, textarea:invalid",
    )!;
    if (invalidElement) setTimeout(() => invalidElement.focus(), 10);
  }, [id, requestTab]);

  // Only hide the tabs that aren't current — leave the components in the DOM. This makes it behave more like a simple page and lets the tab be more of a visual tidying-up, and it means if we've got tabs in a form then the invalid components are still mounted.
  return (
    <Closable isOpen={currentTab === id}>
      <section
        className={`${className ?? ""} ${currentTab === id ? "" : "ds-hidden"}`}
        role="tabpanel"
        onInvalid={onInvalid}
        ref={ref}
      >
        <h3 tabIndex={-1} className={headerClass ?? "is-sr-only"} ref={header}>
          {translate(longTitle ?? label)}
        </h3>
        {children}
      </section>
    </Closable>
  );
}
