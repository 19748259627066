import React, { FormEvent, useCallback, useEffect, useState } from "react";

import Button from "../../../shared/components/design-system/Button";
import StringInput from "../../../shared/components/design-system/TextInput/StringInput";
import { useTranslate } from "../../translation/frontend";

export default function LoginCode({ sendLoginCode }: { sendLoginCode: (reportCode: string) => Promise<boolean> }) {
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);
  const [code, setCode] = useState("");

  const handleFormSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setErrored(false);
      setLoading(true);
      try {
        setErrored(!(await sendLoginCode(code)));
      } finally {
        setLoading(false);
      }
    },
    [sendLoginCode, code],
  );

  useEffect(() => setErrored(false), [code]);

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="messaging__code-container">
        <StringInput
          password
          onChange={setCode}
          required
          value={code}
          label={translate("messagingCode")}
          labelVisuallyHidden
          serverValidity={errored && translate("messagingCodeInvalid")}
          className="messaging__input-container"
        />
        <Button submit label={translate("messagingCodeSubmit")} loading={loading} />
      </div>
    </form>
  );
}
