import React from "react";

const CircleNotch = () => (
  <path
    d="M8.719 2.844a.782.782 0 0 1-.531.937C5.467 4.531 3.5 7.062 3.5 10c0 3.594 2.906 6.5 6.5 6.5 3.563 0 6.5-2.906 6.5-6.5 0-2.938-2-5.469-4.719-6.219a.782.782 0 0 1-.531-.937.782.782 0 0 1 .938-.531C15.53 3.28 18 6.375 18 10c0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8 0-3.625 2.438-6.719 5.781-7.688a.782.782 0 0 1 .938.532Z"
    fill="currentColor"
  />
);

export default CircleNotch;
