import React, { FunctionComponent, MouseEvent, useCallback, useMemo } from "react";

import { AnswerTemplate } from "../../db/models/Answer";
import Question from "../../db/models/Question";
import { useTranslate } from "../translation/frontend";
import { QuestionTitle } from "./Question";
import useInvalidQuestions, { ValidityWarning, warningId } from "./useInvalidQuestions";

const ErrorSummary: FunctionComponent<{
  questions: Question[];
  answers: Record<string, AnswerTemplate[]>;
}> = ({ questions, answers }) => {
  const translate = useTranslate();
  const { warnings } = useInvalidQuestions();

  const handleOnClick = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const el = document.getElementById(`${new URL(event.currentTarget.href).hash.slice(1)}`);
    el?.scrollIntoView(true);
  }, []);

  // Sort the warnings into the order they appear on the page and de-dupe them
  const filteredWarnings = useMemo(() => {
    const filtered: ValidityWarning[] = [];
    for (const question of questions) {
      const questionWarnings = warnings.filter((warning) => warning.question === question);
      if (questionWarnings.length >= 1) filtered.push(questionWarnings[0]);
    }
    return filtered;
  }, [questions, warnings]);

  if (!warnings.length) return null;

  return (
    <div className="error-summary" aria-labelledby="error-summary-title" role="alert">
      <h2 className="error-summary__title" id="error-summary-title">
        {translate("errorSummaryTitle", { count: questions.length })}
      </h2>
      <div className="error-summary__body">
        <ul className="error-summary__list">
          {filteredWarnings.map((warning) => (
            <li key={warning.question.id}>
              <a href={`#${warningId(warning)}`} onClick={handleOnClick}>
                <QuestionTitle question={warning.question} answers={answers} />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ErrorSummary;
