import React from "react";

const ChevronRight = () => (
  <path
    d="m7.3 3.238 5.72 5.97c.125.155.218.343.218.53a.737.737 0 0 1-.218.5l-5.72 5.97a.746.746 0 0 1-1.062.03c-.312-.28-.312-.75-.03-1.062l5.25-5.469-5.25-5.437a.746.746 0 0 1 .03-1.063.746.746 0 0 1 1.063.031Z"
    fill="currentColor"
  />
);

export default ChevronRight;
