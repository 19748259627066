import React, { FunctionComponent } from "react";

const NoMessagesIcon: FunctionComponent<{ colour?: string }> = ({ colour }) => (
  <svg width="168" height="91" viewBox="0 0 168 91" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M160.5 20.0408C164.366 20.0408 167.5 23.1748 167.5 27.0408C167.5 30.9068 164.366 34.0408 160.5 34.0408H120.5C124.366 34.0408 127.5 37.1748 127.5 41.0408C127.5 44.9068 124.366 48.0408 120.5 48.0408H142.5C146.366 48.0408 149.5 51.1748 149.5 55.0408C149.5 58.9068 146.366 62.0408 142.5 62.0408H132.326C127.452 62.0408 123.5 65.1748 123.5 69.0408C123.5 71.6182 125.5 73.9515 129.5 76.0408C133.366 76.0408 136.5 79.1748 136.5 83.0408C136.5 86.9068 133.366 90.0408 129.5 90.0408H46.5C42.634 90.0408 39.5 86.9068 39.5 83.0408C39.5 79.1748 42.634 76.0408 46.5 76.0408H7.5C3.63401 76.0408 0.5 72.9068 0.5 69.0408C0.5 65.1748 3.63401 62.0408 7.5 62.0408H47.5C51.366 62.0408 54.5 58.9068 54.5 55.0408C54.5 51.1748 51.366 48.0408 47.5 48.0408H22.5C18.634 48.0408 15.5 44.9068 15.5 41.0408C15.5 37.1748 18.634 34.0408 22.5 34.0408H62.5C58.634 34.0408 55.5 30.9068 55.5 27.0408C55.5 23.1748 58.634 20.0408 62.5 20.0408H160.5ZM160.5 48.0408C164.366 48.0408 167.5 51.1748 167.5 55.0408C167.5 58.9068 164.366 62.0408 160.5 62.0408C156.634 62.0408 153.5 58.9068 153.5 55.0408C153.5 51.1748 156.634 48.0408 160.5 48.0408Z"
      fill="#E5E3F6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M72.1186 58.6653C72.0404 59.2186 72 59.7839 72 60.3587C72 66.9861 77.3726 72.3587 84 72.3587C90.6274 72.3587 96 66.9861 96 60.3587C96 59.7839 95.9596 59.2186 95.8815 58.6653H125V85.3587C125 87.0156 123.657 88.3587 122 88.3587H46C44.3431 88.3587 43 87.0156 43 85.3587V58.6653H72.1186Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M97 59.3587C97 66.5384 91.1797 72.3587 84 72.3587C76.8203 72.3587 71 66.5384 71 59.3587C71 59.1261 71.0061 58.8949 71.0182 58.6653H43L52.5604 30.3976C52.9726 29.1789 54.1159 28.3587 55.4023 28.3587H112.598C113.884 28.3587 115.027 29.1789 115.44 30.3976L125 58.6653H96.9818C96.9939 58.8949 97 59.1261 97 59.3587Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M95.0976 60.3132C95.0976 65.8612 90.129 71.3587 84 71.3587C77.871 71.3587 72.9024 65.8612 72.9024 60.3132C72.9024 60.1335 72.9077 58.9549 72.918 58.7775H52L60.1614 38.9342C60.5132 37.9925 61.4891 37.3587 62.5873 37.3587H105.413C106.511 37.3587 107.487 37.9925 107.839 38.9342L116 58.7775H95.082C95.0923 58.9549 95.0976 60.1335 95.0976 60.3132Z"
      fill="#E5E3F6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M44.25 58.871V85.3587C44.25 86.3252 45.0335 87.1087 46 87.1087H122C122.966 87.1087 123.75 86.3252 123.75 85.3587V58.871L114.255 30.798C114.015 30.0872 113.348 29.6087 112.598 29.6087H55.4023C54.6519 29.6087 53.985 30.0872 53.7446 30.798L44.25 58.871Z"
      stroke="#7268CA"
      stroke-width="2.5"
    />
    <path
      d="M57 58.3587C60.9366 58.3587 65.1849 58.3587 69.7449 58.3587C71.6212 58.3587 71.6212 59.6773 71.6212 60.3587C71.6212 66.9861 77.1174 72.3587 83.8973 72.3587C90.6772 72.3587 96.1734 66.9861 96.1734 60.3587C96.1734 59.6773 96.1734 58.3587 98.0496 58.3587H123M49.5737 58.3587H52H49.5737Z"
      stroke="#7268CA"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M109.1 5.66144L98 18.1146M83.0996 1.3587V18.1146V1.3587ZM57 5.66144L68.0996 18.1146L57 5.66144Z"
      stroke="#ADA7E0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default NoMessagesIcon;
