import React from "react";

const Ballot = () => (
  <>
    <path
      d="M9.25 5.25h4.5a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75h-4.5A.722.722 0 0 1 8.5 6a.74.74 0 0 1 .75-.75Zm0 4h4.5a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75h-4.5A.722.722 0 0 1 8.5 10a.74.74 0 0 1 .75-.75Zm0 4h4.5a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75h-4.5A.722.722 0 0 1 8.5 14a.74.74 0 0 1 .75-.75ZM6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2h10c1.094 0 2 .906 2 2v12c0 1.125-.906 2-2 2H5c-1.125 0-2-.875-2-2V4c0-1.094.875-2 2-2Zm10 14.5c.25 0 .5-.219.5-.5V4c0-.25-.25-.5-.5-.5H5c-.281 0-.5.25-.5.5v12c0 .281.219.5.5.5h10Z"
      fill="currentColor"
    />
  </>
);

export default Ballot;
