import React from "react";

const TriangleExclamation = () => (
  <>
    <path
      d="M17.832 15.031 11.175 3.656c-.53-.875-1.812-.875-2.312 0L2.176 15.031C1.676 15.906 2.3 17 3.332 17h13.343a1.316 1.316 0 0 0 1.157-1.969Z"
      fill="currentColor"
    />
    <path
      d="M10.02 6.5a.74.74 0 0 0-.75.75v4c0 .438.374.75.75.75a.74.74 0 0 0 .75-.75v-4a.76.76 0 0 0-.75-.75Zm-1 7.531a.98.98 0 0 0 1 .969.974.974 0 0 0 .968-.969.974.974 0 0 0-.969-.969.98.98 0 0 0-1 .97Z"
      fill="#fff"
    />
  </>
);

export default TriangleExclamation;
