import React, { useState, useContext, createContext, useMemo, PropsWithChildren } from "react";

import { QuestionType } from "../../__generated__/graphql";
import Question from "../../db/models/Question";
import { Field, validationType } from "../../shared/questions/validation";
import { useTranslate } from "../translation/frontend";

// Defines an error message and what part of the form it applies to
export interface ValidityWarning extends Field {
  warning: string;
}

function isSameField(a: Field, b: Field) {
  return a.question === b.question && a.option === b.option;
}

// Finds the default error message for an input if none is provided
export function useErrorLabel(type?: QuestionType) {
  const translate = useTranslate();
  return useMemo(() => {
    switch (type) {
      case "TEXT":
        return translate("textQuestionInvalid");
      case "EMAIL":
        return translate("emailQuestionInvalid");
      case "PHONE":
        return translate("phoneQuestionInvalid");
      case "IMAGE":
        return translate("imageQuestionInvalid");
      case "RADIO":
        return translate("radioQuestionInvalid");
      case "CHECKLIST":
        return translate("checkboxQuestionInvalid");
      default:
        return translate("defaultQuestionInvalid");
    }
  }, [translate, type]);
}

// A fake validity context value, partly to placate TypeScript and partly for use in unit tests
const placeholder = {
  warnings: [],
  addWarning: () => undefined,
  removeWarning: () => undefined,
  warningAt: () => undefined,
  questionIsInvalid: () => false,
};

const validityContext = createContext<{
  warnings: ValidityWarning[];
  addWarning: (warning: ValidityWarning) => void;
  removeWarning: (warning: Field) => void;
  warningAt: (warning: Field) => string | undefined;
  questionIsInvalid: (question: Question) => boolean;
}>(placeholder);

export function ValidityProvider({ children }: PropsWithChildren<unknown>) {
  const [warnings, setWarnings] = useState<ValidityWarning[]>([]);
  const contextValue = useMemo(
    () => ({
      warnings,
      addWarning: (warning: ValidityWarning) => {
        if (!warnings.some((other) => isSameField(warning, other))) {
          setWarnings([...warnings, warning]);
        }
      },
      removeWarning: (warning: Field) => {
        if (warnings.some((other) => isSameField(warning, other))) {
          setWarnings([...warnings.filter((other) => !isSameField(warning, other))]);
        }
      },
      questionIsInvalid: (question: Question) => warnings.some((warning) => warning.question === question),
      warningAt: (field: Field) => warnings.find((other) => isSameField(field, other))?.warning,
    }),
    [warnings],
  );

  return <validityContext.Provider value={contextValue}>{children}</validityContext.Provider>;
}

export default function useInvalidQuestions() {
  return useContext(validityContext);
}

// A simpler version of useInvalidQuestions designed for use within a single input component
// The paramater is destructured and rebuild every time just so it needn't be reference equal every time as long as the inner properties are:
export function useInputValidity({ question, option }: Field) {
  const iq = useInvalidQuestions();
  const defaultWarning = useErrorLabel(validationType({ question, option }));
  return useMemo(
    () => ({
      invalid: iq.warningAt({ question, option }),
      markValid: () => iq.removeWarning({ question, option }),
      markInvalid: (message?: string) => iq.addWarning({ question, option, warning: message ?? defaultWarning }),
    }),
    [iq, question, option, defaultWarning],
  );
}

// Generates DOM IDs for error messages — deosn't matter what they are but they're used for linking to them so they have to be the same everywhere
export function warningId(warning: Field) {
  if (warning.option) {
    return `error-message-${warning.question.id}-${warning.option.id}`;
  }
  return `error-message-${warning.question.id}`;
}
