import React from "react";

import ErrorStateIcon from "../../assets/Icons/ErrorState";
import NoMessagesIcon from "../../assets/Icons/NoMessages";
import styles from "./styles.module.scss";

export type EmptyStateVariant = "empty" | "error";
export interface EmptyStateBannerProps {
  title: string;
  body?: string;
  variant?: EmptyStateVariant;
}

export default function EmptyStateBanner({ title, body, variant = "empty" }: EmptyStateBannerProps) {
  return (
    <div className={styles.EmptyStateBanner}>
      <div className={styles.Icon}>{variant === "empty" ? <NoMessagesIcon /> : <ErrorStateIcon />}</div>
      <div className={styles.Information}>{title ? <span className={styles.Title}>{title}</span> : null}</div>
      <p className={styles.BodyText}>{body}</p>
    </div>
  );
}
