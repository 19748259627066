import React from "react";

const SquareInfo = () => (
  <>
    <path
      d="M15 3H5c-1.125 0-2 .906-2 2v10c0 1.125.875 2 2 2h10c1.094 0 2-.875 2-2V5c0-1.094-.906-2-2-2Z"
      fill="currentColor"
    />
    <path
      d="M11 7c0-.531-.469-1-1-1-.563 0-1 .469-1 1 0 .563.438 1 1 1 .531 0 1-.438 1-1Zm-2.25 7h2.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75h-.5V9.75A.76.76 0 0 0 10 9H9a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h.25v2h-.5a.74.74 0 0 0-.75.75c0 .438.313.75.75.75Z"
      fill="#fff"
    />
  </>
);

export default SquareInfo;
