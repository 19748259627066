import React from "react";

const Image = () => (
  <path
    d="M6.75 5.75a1.5 1.5 0 0 0-1.5 1.5c0 .844.656 1.5 1.5 1.5a1.5 1.5 0 0 0 1.5-1.5c0-.813-.688-1.5-1.5-1.5ZM15.969 3h-12c-1.094 0-2 .906-2 2v10c0 1.125.906 2 2 2h12c1.094 0 2-.875 2-2V5c0-1.094-.875-2-2-2Zm.5 11.813L12.187 9a.499.499 0 0 0-.437-.25.607.607 0 0 0-.5.25l-3.313 4.5-1.156-1.438c-.125-.124-.281-.218-.468-.218-.188 0-.344.094-.47.219L3.5 15 3.469 5c0-.25.25-.5.5-.5h12c.281 0 .5.25.5.5v9.813Z"
    fill="currentColor"
  />
);

export default Image;
