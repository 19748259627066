import React from "react";

const XmarkLarge = () => (
  <path
    d="m10 8.969 5.719-5.719a.684.684 0 0 1 1.031 0 .684.684 0 0 1 0 1.031L11.031 10l5.719 5.719c.313.312.313.781 0 1.062a.684.684 0 0 1-1.031 0L10 11.063 4.25 16.78a.684.684 0 0 1-1.031 0c-.313-.281-.313-.75 0-1.062L8.938 10l-5.72-5.719a.684.684 0 0 1 0-1.031.684.684 0 0 1 1.032 0L10 8.969Z"
    fill="currentColor"
  />
);

export default XmarkLarge;
