import React from "react";

const UserCircle = () => (
  <path
    d="M10 5.5a2.754 2.754 0 0 0-2.75 2.75A2.734 2.734 0 0 0 10 11c1.5 0 2.75-1.219 2.75-2.75 0-1.5-1.25-2.75-2.75-2.75Zm0 4c-.719 0-1.25-.531-1.25-1.25C8.75 7.562 9.281 7 10 7c.688 0 1.25.563 1.25 1.25 0 .719-.563 1.25-1.25 1.25ZM10 2c-4.438 0-8 3.594-8 8 0 4.438 3.563 8 8 8 4.406 0 8-3.563 8-8 0-4.406-3.594-8-8-8Zm0 14.5a6.503 6.503 0 0 1-3.906-1.281A3.087 3.087 0 0 1 8.875 13.5h2.219c1.187 0 2.25.688 2.781 1.719A6.479 6.479 0 0 1 10 16.5Zm5-2.344C14.156 12.844 12.719 12 11.094 12H8.875c-1.625 0-3.063.844-3.906 2.156C4.03 13.031 3.5 11.594 3.5 10c0-3.563 2.906-6.5 6.5-6.5 3.563 0 6.5 2.938 6.5 6.5a6.444 6.444 0 0 1-1.5 4.156Z"
    fill="currentColor"
  />
);

export default UserCircle;
