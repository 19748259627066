import React from "react";

const ChevronLeft = () => (
  <path
    d="m11.938 16.238-5.72-5.968A.857.857 0 0 1 6 9.707c0-.156.063-.344.188-.5l5.718-5.969a.746.746 0 0 1 1.063-.03c.312.28.312.75.031 1.062L7.781 9.707l5.25 5.5A.746.746 0 0 1 13 16.27a.746.746 0 0 1-1.063-.032Z"
    fill="currentColor"
  />
);

export default ChevronLeft;
