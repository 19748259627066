import ArrowLeft from "./ArrowLeft";
import ArrowRight from "./ArrowRight";
import ArrowToBottom from "./ArrowToBottom";
import ArrowUp from "./ArrowUp";
import ArrowUpRightFromSquare from "./ArrowUpRightFromSquare";
import Ballot from "./Ballot";
import Bold from "./Bold";
import BoxArchive from "./BoxArchive";
import Browser from "./Browser";
import Calendar from "./Calendar";
import Chart from "./Chart";
import Check from "./Check";
import ChevronDown from "./ChevronDown";
import ChevronLeft from "./ChevronLeft";
import ChevronRight from "./ChevronRight";
import ChevronUp from "./ChevronUp";
import CircleCheck from "./CircleCheck";
import CircleExclamation from "./CircleExclamation";
import CircleInfo from "./CircleInfo";
import CircleNotch from "./CircleNotch";
import Clock from "./Clock";
import Clone from "./Clone";
import Columns3 from "./Columns3";
import Edit from "./Edit";
import Ellipsis from "./Ellipsis";
import Eye from "./Eye";
import EyeStrikethrough from "./EyeStrikethrough";
import File from "./File";
import FilePlus from "./FilePlus";
import Filter from "./Filter";
import FlipHorizontal from "./FlipHorizontal";
import FlipVertical from "./FlipVertical";
import Folder from "./Folder";
import FolderDownload from "./FolderDownload";
import Gear from "./Gear";
import GripDotsVertical from "./GripDotsVertical";
import Image from "./Image";
import Info from "./Info";
import Italic from "./Italic";
import Lines from "./Lines";
import Link from "./Link";
import Message from "./Message";
import MessageStrikeThrough from "./MessageStrikethrough";
import Minus from "./Minus";
import Navicon from "./Navicon";
import OctogonExclamation from "./OctogonExclamation";
import Organisations from "./Organisations";
import Plus from "./Plus";
import RadioSelection from "./RadioSelection";
import Redo from "./Redo";
import RotateAnticlockwise from "./RotateAnticlockwise";
import RotateClockwise from "./RotateClockwise";
import Search from "./Search";
import SignOut from "./SignOut";
import Sliders from "./Sliders";
import SmallChevronDown from "./SmallChevronDown";
import SortAlt from "./SortAlt";
import SortAscendingAlphabetical from "./SortAscendingAlphabetical";
import SortAscendingNumeric from "./SortAscendingNumeric";
import SortDescendingAlphabetical from "./SortDescendingAlphabetical";
import SortDescendingNumeric from "./SortDescendingNumeric";
import SquareCheck from "./SquareCheck";
import SquareInfo from "./SquareInfo";
import Table from "./Table";
import TableCellsLarge from "./TableCellsLarge";
import Tick from "./Tick";
import Trash from "./Trash";
import TrashXmark from "./TrashXmark";
import TriangleExclamation from "./TriangleExclamation";
import Underline from "./Underline";
import Undo from "./Undo";
import UploadArrow from "./UploadArrow";
import User from "./User";
import UserCircle from "./UserCircle";
import UserGroup from "./UserGroup";
import UserPlus from "./UserPlus";
import Xmark from "./Xmark";
import XmarkLarge from "./XmarkLarge";

const iconComponents = {
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowToBottom: ArrowToBottom,
  arrowUp: ArrowUp,
  arrowUpRightFromSquare: ArrowUpRightFromSquare,
  ballot: Ballot,
  browser: Browser,
  bold: Bold,
  boxArchive: BoxArchive,
  calendar: Calendar,
  chart: Chart,
  check: Check,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  smallChevronDown: SmallChevronDown,
  circleCheck: CircleCheck,
  circleExclamation: CircleExclamation,
  circleInfo: CircleInfo,
  circleNotch: CircleNotch,
  clock: Clock,
  clone: Clone,
  columns3: Columns3,
  edit: Edit,
  ellipsis: Ellipsis,
  eye: Eye,
  eyeStrikethrough: EyeStrikethrough,
  file: File,
  filePlus: FilePlus,
  filter: Filter,
  flipHorizontal: FlipHorizontal,
  flipVertical: FlipVertical,
  folder: Folder,
  folderDownload: FolderDownload,
  gear: Gear,
  gripDotsVertical: GripDotsVertical,
  minus: Minus,
  plus: Plus,
  image: Image,
  info: Info,
  italic: Italic,
  lines: Lines,
  link: Link,
  message: Message,
  messageStrikeThrough: MessageStrikeThrough,
  navicon: Navicon,
  octogonExclamation: OctogonExclamation,
  organisations: Organisations,
  radioSelection: RadioSelection,
  redo: Redo,
  rotateAnticlockwise: RotateAnticlockwise,
  rotateClockwise: RotateClockwise,
  search: Search,
  signOut: SignOut,
  sliders: Sliders,
  sortAlt: SortAlt,
  sortAscendingAlphabetical: SortAscendingAlphabetical,
  sortAscendingNumeric: SortAscendingNumeric,
  sortDescendingAlphabetical: SortDescendingAlphabetical,
  sortDescendingNumeric: SortDescendingNumeric,
  squareCheck: SquareCheck,
  squareInfo: SquareInfo,
  table: Table,
  tableCellsLarge: TableCellsLarge,
  tick: Tick,
  trash: Trash,
  trashXmark: TrashXmark,
  triangleExclamation: TriangleExclamation,
  underline: Underline,
  undo: Undo,
  uploadArrow: UploadArrow,
  userCircle: UserCircle,
  userGroup: UserGroup,
  userPlus: UserPlus,
  user: User,
  xmarkLarge: XmarkLarge,
  xmark: Xmark,
};

export default iconComponents;
