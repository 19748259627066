import React from "react";

import { useTranslate } from "../translation/frontend";

export interface UploadWarningProps {
  tooLarge: number;
  wrongType: number;
}

export default function UploadWarning({ tooLarge, wrongType }: UploadWarningProps) {
  const translate = useTranslate();

  if (tooLarge === 0 && wrongType === 0) return null;

  if (wrongType === 0) {
    return (
      <p
        className="warning-message warning-message--icon"
        dangerouslySetInnerHTML={{ __html: translate("someFilesTooLargeError", { limit: 25, count: tooLarge }) }}
      />
    );
  }

  if (tooLarge === 0) {
    return (
      <p
        className="warning-message warning-message--icon"
        dangerouslySetInnerHTML={{ __html: translate("someFilesNotImagesError", { count: wrongType }) }}
      />
    );
  }

  return (
    <p
      className="warning-message warning-message--icon"
      dangerouslySetInnerHTML={{ __html: translate("someFilesRejectedError", { count: tooLarge + wrongType }) }}
    />
  );
}
