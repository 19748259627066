import { useFocusRing } from "@react-aria/focus";

import linkStyles from "./link.module.scss";

export type LinkClassProps = {
  variant?: string;
  className?: string;
  isQuiet?: boolean;
  isOverBg?: boolean;
};

export function useLinkProps({ className = "", variant = "primary", isQuiet, isOverBg }: LinkClassProps) {
  const { isFocusVisible, focusProps } = useFocusRing();

  return {
    className: `
      ${linkStyles.Link}
      ${linkStyles[variant]}
      ${className ?? ""}
      ${isQuiet ? linkStyles.quiet : ""}
      ${isOverBg ? linkStyles.overBg : ""}
      ${isFocusVisible ? linkStyles.focusVisible : ""}
    `,
    ...focusProps,
  };
}
