import classNames from "classnames";
import React, { FunctionComponent } from "react";

import "./styles.scss";

export interface DividerProps {
  label?: string;
  tight?: boolean;
}

const Divider: FunctionComponent<DividerProps> = ({ label, tight }) => (
  <div className={classNames({ "c-divider": true, "c-divider--tight": tight })}>
    {label ? <span className="c-divider__label">{label}</span> : null}
  </div>
);

export default Divider;
