import { useCallback, useEffect, useMemo, useState } from "react";

import { Report } from "../../utils/types";
import { get } from "./request";

export default function useReport(authCode: string) {
  const [loadingReport, setLoading] = useState(true);
  const [errorReport, setError] = useState<unknown>(null);
  const [report, setReport] = useState<Report>();
  const fetchReport = useCallback(async () => {
    const result = await get<Report>("/report", authCode);

    setReport(result);
  }, [authCode]);

  useEffect(() => {
    setLoading(true);
    fetchReport()
      .then(() => setError(false))
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  }, [fetchReport]);

  return useMemo(
    () => ({
      loadingReport,
      errorReport,
      report,
    }),
    [errorReport, loadingReport, report],
  );
}
