import React from "react";

const ArrowLeft = () => (
  <path
    d="M16.969 9.988c0 .438-.344.782-.75.782H5.594l4.156 3.937a.746.746 0 0 1 .031 1.063c-.281.312-.75.312-1.062.03l-5.5-5.25A.753.753 0 0 1 3 9.989a.73.73 0 0 1 .219-.53l5.5-5.25c.312-.282.781-.282 1.062.03a.746.746 0 0 1-.031 1.063L5.594 9.238h10.625a.74.74 0 0 1 .75.75Z"
    fill="currentColor"
  />
);

export default ArrowLeft;
