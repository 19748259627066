import React from "react";

import { FormInterstitial } from "../../../db/models/Form";
import { useTranslate } from "../../translation/frontend";
import Captcha from "../Captcha";
import SkipToEndButton from "../SkipToEndButton";
import { useForm } from "../context";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import CheckIcon from "../icons/CheckIcon";
import { Container, ContinueButton, InterstitialBody } from "./components";

export interface InterstitialProps {
  page: FormInterstitial;
}

export default function RegularInterstitial({ page }: InterstitialProps) {
  const translate = useTranslate();

  const { disableNextButton, loading, verifiedSession, isLastPage, showValidationError, onBack, isSurvey } = useForm();

  const disableForwardButtons =
    disableNextButton || loading || (!!(page.hasCaptcha || page.hasLoginGate) && !verifiedSession);

  return (
    <Container>
      <InterstitialBody body={page.body} />
      {page.allowSkipToPage ? (
        <section className="section">
          <div className="report-paging__skip-to-end">
            <SkipToEndButton disabled={disableForwardButtons} />
          </div>
        </section>
      ) : null}
      {page.hasCaptcha ? <Captcha /> : null}
      <div className="report-details__paging">
        {page.confirm ? (
          <div className="field field--isRequired field--isSingle">
            <div className="options__option options__option--interstitial">
              <div className="options__label">
                <input
                  type="checkbox"
                  className="form-control options__check options__checkbox"
                  id="confirm"
                  required
                />
                <CheckIcon />
                <div className="options__label-target">
                  <label htmlFor="confirm">{page.confirm}</label>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {isLastPage ? (
          <div className="report-details__submit">
            {showValidationError ? (
              <span className="report-paging__error continue is-invalid" role="alert">
                {translate("pageIncompleteError")}
              </span>
            ) : null}
            <button className="btn btn--continue" name="_action" value="next" disabled={disableForwardButtons}>
              <span>{translate(isSurvey ? "sendSurveyLabel" : "sendReportLabel")}</span>
              <span className="btn__arrow">
                <ArrowRightIcon />
              </span>
            </button>
          </div>
        ) : (
          <div className="report-details__continue">
            {showValidationError ? (
              <span className="report-paging__error continue is-invalid" role="alert">
                {translate("pageIncompleteError")}
              </span>
            ) : null}
            <ContinueButton disabled={disableForwardButtons} />
          </div>
        )}
        {onBack ? (
          <button
            className="btn btn--back btn--arrow"
            name="_action"
            value="back"
            formNoValidate
            onClick={onBack}
            disabled={loading || disableNextButton}
          >
            <span className="btn__arrow">
              <ArrowRightIcon />
            </span>
            <span>{translate("backLabel")}</span>
          </button>
        ) : null}
      </div>
    </Container>
  );
}
