import React, { ReactElement, useRef } from "react";

import { useTranslate } from "../translation/frontend";
import Progress from "./Progress";
import QuestionGroup from "./QuestionGroup";
import ReportFormMain from "./ReportFormMain";
import UploadWarning from "./UploadWarning";
import { FormProvider, ReportFormProps, useForm } from "./context";
import Interstitial from "./interstitials/Interstitial";

export default function ReportForm(props: ReportFormProps): ReactElement {
  const formRef = useRef<HTMLFormElement>(null);
  const scrollRef = useRef<HTMLElement>(null);

  return (
    <FormProvider {...props} formRef={formRef} scrollRef={scrollRef}>
      <Progress scrollRef={scrollRef} />
      <Warnings />
      <ReportFormMain>
        <Page />
      </ReportFormMain>
    </FormProvider>
  );
}

function Warnings() {
  const { hideWarnings, uploadWarning, csrfFailure } = useForm();
  const translate = useTranslate();

  if (hideWarnings) return null;

  return (
    <>
      {uploadWarning ? (
        <section className="form-group-intro section">
          <div className="container--slim">
            <div className="content-styles">
              <UploadWarning {...uploadWarning} />
            </div>
          </div>
        </section>
      ) : null}
      {csrfFailure ? (
        <section className="form-group-intro section">
          <div className="container--slim">
            <div className="content-styles">
              <p className="warning-message warning-message--icon">{translate("csrfFailure")}</p>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}

function Page() {
  const { page, currentPage } = useForm();

  // The form has a "key" prop so that React re-renders it when the page changes. Re-rendering the component, rather than simply updating its content, causes the browser to treat the change more like a new page load. That means both screenreaders and keyboard navigation reset focus to the top of the page. Not sure yet how that affects scrolling. The new page number is announced by the <Progress> element.

  if (page.type === "QUESTION_GROUP") {
    return <QuestionGroup key={currentPage} page={page} />;
  }

  if (page.type === "INTERSTITIAL") {
    return <Interstitial key={currentPage} page={page} />;
  }

  // This code should be unreachable but we should do *something*, so:
  return null;
}
