import React from "react";

const EyeStrikethrough = () => (
  <path
    d="M1.2 2.17L4.7 4.89C6.11 3.83 7.86 2.98 10.02 2.98C12.52 2.98 14.55 4.14 16.02 5.52C17.48 6.86 18.45 8.48 18.92 9.61C19.02 9.86 19.02 10.14 18.92 10.39C18.52 11.39 17.67 12.83 16.42 14.08L19.7 16.67C20.05 16.92 20.11 17.39 19.83 17.7C19.58 18.05 19.11 18.11 18.8 17.83L0.3 3.33C-0.05 3.08 -0.11 2.61 0.17 2.3C0.42 1.95 0.89 1.89 1.2 2.17ZM5.92 5.86L7.36 6.98C8.08 6.36 8.98 5.98 10.02 5.98C12.2 5.98 14.02 7.8 14.02 9.98C14.02 10.67 13.86 11.3 13.55 11.83L15.23 13.14C16.33 12.08 17.08 10.86 17.45 9.98C17.05 9.05 16.2 7.73 14.98 6.61C13.7 5.42 12.05 4.48 10.02 4.48C8.42 4.48 7.08 5.05 5.92 5.86ZM12.33 10.89C12.45 10.61 12.52 10.3 12.52 9.98C12.52 8.61 11.39 7.48 10.02 7.48C9.98 7.48 9.95 7.48 9.95 7.48C9.98 7.67 10.02 7.83 10.02 7.98C10.02 8.33 9.92 8.61 9.8 8.89L12.33 10.89ZM13.95 15.98C12.8 16.61 11.48 16.98 10.02 16.98C7.48 16.98 5.45 15.86 3.98 14.48C2.52 13.11 1.55 11.48 1.08 10.39C0.98 10.14 0.98 9.86 1.08 9.61C1.39 8.89 1.89 7.98 2.61 7.05L3.77 7.98C3.2 8.7 2.8 9.42 2.55 9.98C2.98 10.92 3.8 12.27 5.02 13.39C6.3 14.58 7.95 15.48 10.02 15.48C10.95 15.48 11.83 15.3 12.64 14.95L13.95 15.98ZM11.2 13.83C10.83 13.92 10.42 13.98 9.98 13.98C7.8 13.98 5.98 12.2 5.98 9.98C5.98 9.92 6.02 9.83 6.02 9.73L7.77 11.11C8.08 11.77 8.7 12.27 9.45 12.42L11.2 13.83Z"
    fill="currentColor"
  />
);

export default EyeStrikethrough;
