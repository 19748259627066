import React, { PropsWithChildren, createContext, useContext, useEffect } from "react";

import useRefOf from "../../../hooks/useRefOf";

type Listener = () => void;

const closableContext = createContext<boolean>(true);

/** Returns false if this element is in an inactive tab anywhere in its hierarchy, and true otherwise. */
export function useParentIsOpen() {
  return useContext(closableContext);
}

/** Runs some code when the element closes. */
export default function useOnParentClose(onClose?: Listener | null) {
  const parentIsOpen = useParentIsOpen();
  const onCloseRef = useRefOf(onClose);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (parentIsOpen) return () => void onCloseRef.current?.();
  }, [onCloseRef, parentIsOpen]);
}

/** Runs some code when the element opens. */
export function useOnParentOpen(onOpen?: Listener | null) {
  const parentIsOpen = useParentIsOpen();
  const onOpenRef = useRefOf(onOpen);
  useEffect(() => {
    if (parentIsOpen) onOpenRef.current?.();
  }, [onOpenRef, parentIsOpen]);
}

export function Closable({ children, isOpen }: PropsWithChildren<{ isOpen: boolean }>) {
  const parentIsOpen = useContext(closableContext);
  return <closableContext.Provider value={parentIsOpen && isOpen}>{children}</closableContext.Provider>;
}
