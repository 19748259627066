import React from "react";

const Tick = () => (
  <path
    d="M15.5 6.20166C15.9062 6.57666 15.9062 7.23291 15.5 7.60791L8.95312 14.1548C8.57812 14.561 7.92188 14.561 7.54688 14.1548L4.5 11.1079C4.09375 10.7329 4.09375 10.0767 4.5 9.70166C4.875 9.29541 5.53125 9.29541 5.90625 9.70166L8.23438 12.0298L14.0938 6.20166C14.4688 5.79541 15.125 5.79541 15.5 6.20166Z"
    fill="currentColour"
  />
);

export default Tick;
