import { Auth } from "aws-amplify";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { FormInterstitial } from "../../../db/models/Form";
import { useTranslate } from "../../translation/frontend";
import { useForm } from "../context";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import { Container, ContinueButton, InterstitialBody } from "./components";

export interface LoginInterstitialProps {
  page: FormInterstitial;
}

// Note that this MUST be the first page of the form
export default function LoginInterstitial({ page }: LoginInterstitialProps) {
  const translate = useTranslate();

  const { verifiedSession, setVerifiedSession, formId, sessionId, ssoProvider } = useForm();

  const { result: jwt, loading } = useCurrentAuthSession();
  useJwtToReportingSession(jwt, verifiedSession, setVerifiedSession, formId, sessionId);

  const signIn = useCallback(() => {
    void Auth.federatedSignIn({ customProvider: ssoProvider! });
  }, [ssoProvider]);

  if (verifiedSession) {
    return (
      <Container>
        <InterstitialBody body={page.body} />
        <div className="report-details__paging">
          <div className="field field--isRequired field--isSingle field--auth field--auth__loggedIn">
            <div className="auth--loggedIn">{translate("auth.signedIn")}</div>
          </div>
          <div className="report-details__continue">
            <ContinueButton />
          </div>
        </div>
      </Container>
    );
  }

  // If ssoProvider is not set that means (a) the form is set up wrongly, or, hopefully more probably (b) we're rendering on the server. In the latter case, we just want to wait for the client-side JS to load in, so render the loading version of hte page for now and hopefully it'll kick in in a moment.
  if (!ssoProvider || loading) {
    return (
      <Container>
        <InterstitialBody body={page.body} />
        <div className="report-details__paging">
          <div className="field field--isRequired field--isSingle field--auth field--auth__loggedIn">
            <div className="auth--loading">{translate("auth.loading")}</div>
          </div>
          <div className="report-details__continue">
            <ContinueButton disabled />
          </div>
        </div>
      </Container>
    );
  }

  // Render a fairly normal interstitial, but replacing the "continue" button with a sign-in button that we control
  return (
    <Container>
      <InterstitialBody body={page.body} />
      <div className="report-details__paging">
        <div className="report-details__continue">
          <button onClick={signIn} type="button" className="btn btn--continue btn--swipe btn--arrow">
            <span>{translate("auth.signIn")}</span>
            <span className="btn__arrow">
              <ArrowRightIcon />
            </span>
          </button>
        </div>
      </div>
    </Container>
  );
}

function useJwtToReportingSession(
  jwt: string | null,
  verifiedSession: boolean,
  setVerifiedSession: (value: boolean) => void,
  formId: string,
  sessionId: string,
) {
  const [failed, setFailed] = useState(false);
  useEffect(() => {
    if (!jwt || verifiedSession) return;
    fetch("/sessions", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ jwt, formId, sessionId }),
    })
      .then((response) => {
        if (response.status === 200) setVerifiedSession(true);
        else setFailed(true);
      })
      .catch((error) => {
        console.error(error);
        setFailed(true);
      })
      // Log the user out when we're done so that their session isn't hanging around the browser's memory for other users to see
      .finally(() => localStorage.clear());
  }, [jwt, verifiedSession, setVerifiedSession, formId, sessionId]);
  return failed;
}

function useCurrentAuthSession() {
  return useOnFirstRender(async () => {
    const session = await Auth.currentSession();
    if (!session.isValid()) return null;
    return session.getIdToken().getJwtToken();
  });
}

function useOnFirstRender<T>(callback: () => Promise<T>) {
  const [firstRender, setFirstRender] = useState(true);
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<T | null>(null);
  const [error, setError] = useState<unknown>(null);
  if (firstRender) {
    setFirstRender(false);
    callback()
      .then((result: T) => setResult(result))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }
  return useMemo(() => ({ loading, error, result }), [loading, error, result]);
}
