import React, { FunctionComponent, PropsWithChildren, ReactNode } from "react";

import { TextElement, Translatable, isTranslatable } from "../../translation";
import Button, { ButtonProps } from "../Button";
import AlertDangerIcon from "./icons/AlertDangerIcon";
import AlertInfoIcon from "./icons/AlertInfoIcon";
import AlertSuccessIcon from "./icons/AlertSuccessIcon";
import AlertWarningIcon from "./icons/AlertWarningIcon";
import styles from "./styles.module.scss";

export type AlertVariant = "danger" | "success" | "info" | "warning" | "default";

export type AlertProps = {
  id?: string;
  variant?: AlertVariant;
  title?: Translatable | null;
  onClickClose?: (id?: string) => void;
  className?: string | undefined;
  actions?: ButtonProps[];
  icon?: ReactNode;
} & ({ message: Translatable | ReactNode } | PropsWithChildren<{ message?: undefined }>);

const AlertIcons: Record<string, FunctionComponent> = {
  danger: AlertDangerIcon,
  info: () => <AlertInfoIcon />,
  plain: () => <AlertInfoIcon colour="#7268CA" />,
  success: AlertSuccessIcon,
  warning: AlertWarningIcon,
  default: () => <AlertInfoIcon colour="#7268CA" />,
};

interface BaseAlertProps {
  id?: string;
  variant?: AlertVariant;
  title?: Translatable | null;
  onClickClose?: (id?: string) => void;
  className?: string | undefined;
  actions?: ButtonProps[];
  icon?: ReactNode;
}

/** This component can accept children, but please only use them when the alert contains a link, or other formatted text. In general the 'message' tag is preferred. Under no circumstances it is acceptable to use the 'children' property to inject other elements such as buttons into the toast. If you think you need to do that then bad luck, the design system does not support it. Think of another solution, or raise a ticket to change the design system. */
export default function Alert(props: BaseAlertProps & { message: Translatable | ReactNode }): JSX.Element;
export default function Alert(props: PropsWithChildren<BaseAlertProps>): JSX.Element;
export default function Alert({
  id,
  variant = "default",
  title,
  message,
  children,
  onClickClose,
  className,
  actions,
  icon,
}: PropsWithChildren<BaseAlertProps & { message?: Translatable | ReactNode }>) {
  const Icon = AlertIcons[variant];

  const handleCloseButtonClick = () => onClickClose?.(id);

  return (
    <div className={`${styles.Alert} ${variant ? styles[variant] : ""} ${className ?? ""}`}>
      <span aria-hidden="true" className={styles.Icon}>
        {icon ?? <Icon />}
      </span>

      <span className="is-sr-only">{variant}</span>

      <div
        className={`
          ${styles.Main}
          ${title ? styles.hasTitle : ""}
          ${icon ? styles.hasCustomIcon : ""}
        `}
      >
        <div className={styles.Content}>
          {title ? <TextElement tag="h2" className={styles.Title} props={title} /> : null}
          <AlertContent message={message} children={children} />
        </div>

        {actions ? (
          <div className={styles.Actions}>
            {actions.map((action, i) => (
              <Button key={i} {...action} size="small" />
            ))}
          </div>
        ) : null}
      </div>

      {onClickClose ? (
        <Button
          variant="ghost"
          iconButton
          icon="xmarkLarge"
          onClick={handleCloseButtonClick}
          className={styles.Close}
          label="Close"
          size="small"
        />
      ) : null}
    </div>
  );
}

function AlertContent({ message, children }: PropsWithChildren<{ message?: Translatable | ReactNode }>) {
  // If you pass in children, they must be a ReactNode, and you can't also pass in a message, so just render the children.
  if (children) return <p className={styles.Message}>{children}</p>;

  // Normally, if you pass in a message, it should be a Translatable.
  if (isTranslatable(message)) return <TextElement tag="p" className={styles.Message} props={message} />;

  // The one exception to that is that we allow a ReactNode to be passed in on the `message` prop (because it makes the code in the toast manager way simpler), so if neither of the above cases matched, render the message as if it were the children:

  return <p className={styles.Message}>{message}</p>;
}
