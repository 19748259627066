import React, { FormEvent, useCallback, useEffect, useRef, useState } from "react";

import { QuestionType } from "../../__generated__/graphql";
import { useLanguage } from "../../shared/components/translation";
import { Field, validationType } from "../../shared/questions/validation";
import { useTranslate } from "../translation/frontend";
import { translateDbString } from "../translation/shared/Translated";
import ErrorMessage from "./ErrorMessage";
import { useInputValidity } from "./useInvalidQuestions";

const types: Record<QuestionType, string | undefined> = {
  TEXT: "text",
  EMAIL: "email",
  PHONE: "phone",
  DATE: "date",
  DATETIME: "datetime-local",
  TIME: "time",
  TEXTAREA: undefined,
  // These will never come up, but Typescript does not know that:
  IMAGE: undefined,
  CHECKLIST: undefined,
  RADIO: undefined,
};

const autoCompletes: Record<QuestionType, string | undefined> = {
  TEXT: undefined,
  EMAIL: "email",
  PHONE: "tel",
  DATE: undefined,
  DATETIME: undefined,
  TIME: undefined,
  TEXTAREA: undefined,
  IMAGE: undefined,
  CHECKLIST: undefined,
  RADIO: undefined,
};

const patterns: Record<QuestionType, string | undefined> = {
  TEXT: undefined,
  EMAIL: undefined,
  PHONE: /^\+?[\d\s]{6,}/.source,
  DATE: undefined,
  DATETIME: undefined,
  TIME: undefined,
  TEXTAREA: undefined,
  IMAGE: undefined,
  CHECKLIST: undefined,
  RADIO: undefined,
};

const inBrowser = typeof window !== "undefined";

export default function TextInput({
  field,
  defaultValue,
  hidden,
}: {
  field: Field;
  defaultValue?: string;
  hidden?: boolean;
}) {
  const language = useLanguage();
  const { question, option } = field;

  const { invalid, markValid, markInvalid } = useInputValidity(field);

  const ref = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  useEffect(() => ref.current?.setCustomValidity(invalid ?? ""), [invalid]);

  const type = validationType(field);
  const characterLimit = (option ? option.otherCharacterLimit : question.characterLimit) ?? null;
  const id = option ? `${option.id}-other` : question.id;
  const name = option ? option.id : question.id;
  const placeholder = option
    ? translateDbString(option.otherDescriptionTranslations, language) ?? option.otherDescription
    : "";
  const required = option ? inBrowser && !option.otherOptional && !hidden : question.required;

  const [value, setValue] = useState(defaultValue ?? "");
  const translate = useTranslate();

  const onChange = useCallback(
    (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const input: HTMLInputElement | HTMLTextAreaElement = e.currentTarget;
      setValue(input.value);
      // Check these fields explicitly rather than validity.valid as that includes the custom validity which we might have already set. "typeMismatch" is the email address checker
      const { valueMissing, patternMismatch, typeMismatch } = input.validity;
      if (valueMissing || patternMismatch || typeMismatch) {
        markInvalid();
      } else if (characterLimit && input.value.length > characterLimit) {
        markInvalid(translate("maxCharactersError", { count: characterLimit }));
      } else {
        markValid();
      }
      if (type === "DATETIME") {
        // This is needed to work around a Mobile Safari bug
        // https://stackoverflow.com/questions/43747521/mobile-safari-10-3-1-datetime-local-enter-a-valid-value-error
        input.value = input.value.substring(0, 16);
      }
    },
    [characterLimit, markValid, markInvalid, translate, type],
  );

  const Tag = type === "TEXTAREA" ? "textarea" : "input";

  // For some reason, possibly to do with React attempting to respect the Dom received from the server, setting the "maxLength" tag depending on whether or not we are in the browser does not work. This blanks it immediately after the initial render, which means that the server-side no-JS version never reaches it, and the client-side JS version never sees the maxlength attribute.
  const [characterLimitParam, setCharacterLimitParam] = useState(characterLimit ?? undefined);
  useEffect(() => setCharacterLimitParam(undefined), []);

  return (
    <>
      <ErrorMessage field={field} />
      <Tag // @ts-ignore — the ref type is right but this dynamic tag choice doesn't seem to support that
        ref={ref}
        type={types[type]}
        autoComplete={autoCompletes[type]}
        pattern={patterns[type]}
        spellCheck={type === "TEXT"}
        className="form-control form-control__text"
        id={id}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        required={required}
        onChange={onChange}
        onInvalid={onChange}
        aria-describedby={`${id}--max-chars ${id}--chars-left ${id}--chars-shorthand`}
        maxLength={characterLimitParam}
      />
      <CharacterLimit characterLimit={characterLimit} value={value} id={id} />
    </>
  );
}

function CharacterLimit({ characterLimit, value, id }: { characterLimit: number | null; value: string; id: string }) {
  const translate = useTranslate();

  if (!characterLimit) return null;

  const length = value.length;
  const left = characterLimit - length;
  const leftThreshold = Math.min(characterLimit - 100, characterLimit * 0.75);

  return (
    <div className={`character-limit ${length > characterLimit ? "character-limit--invalid" : ""}`}>
      <span className="character-limit__maximum" id={`${id}--max-chars`}>
        {translate("maxCharacters", { count: characterLimit })}
      </span>
      <span
        className={`character-limit__remaining ${length >= leftThreshold ? "" : "character-limit__remaining--hidden"}`}
        aria-live="polite"
        id={`${id}--chars-left`}
      >
        {left >= 0 ? translate("charactersLeft", { count: left }) : translate("charactersOver", { count: -left })}
      </span>
      <span className="character-limit__shorthand" aria-live="polite" id={`${id}--chars-shorthand`}>
        {translate("maxCharactersShorthand", { used: length, limit: characterLimit })}
      </span>
    </div>
  );
}
