import React from "react";

const SortAlt = () => (
  <path
    d="M8.438 12.5 6.75 14.344V3.78C6.75 3.344 6.406 3 6 3c-.438 0-.75.344-.75.781v10.563L3.531 12.5A.732.732 0 0 0 3 12.281a.849.849 0 0 0-.531.188c-.313.281-.313.75-.031 1.062l2.968 3.25a.755.755 0 0 0 1.094 0l2.969-3.25c.281-.312.281-.781-.031-1.062-.25-.281-.72-.25-1 .031Zm9.093-6-2.968-3.25a.755.755 0 0 0-1.094 0L10.438 6.5c-.282.313-.282.781.03 1.063A.848.848 0 0 0 11 7.75a.66.66 0 0 0 .531-.25l1.719-1.813V16.25c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75V5.687l1.688 1.844a.774.774 0 0 0 1.062.032c.313-.282.313-.75.031-1.063Z"
    fill="currentColor"
  />
);

export default SortAlt;
