import React from "react";

const SortDescendingNumeric = () => (
  <path
    d="M13 7.5C12.5625 7.5 12.25 7.8125 12.25 8.25C12.25 8.65625 12.5625 9 12.9688 9H15.5C15.9063 9 16.25 8.65625 16.25 8.25C16.25 7.8125 15.9063 7.5 15.5 7.5H15V3.75C15 3.46875 14.8438 3.21875 14.5938 3.09375C14.375 2.9375 14.0938 2.96875 13.8438 3.09375L12.5938 3.84375C12.25 4.0625 12.125 4.53125 12.3438 4.875C12.5625 5.21875 13 5.34375 13.375 5.125L13.4688 5.0625V7.5H13ZM14.25 10C12.7188 10 11.5 11.25 11.5 12.75C11.5 14.0937 12.4063 15.1875 13.625 15.4375L13.1563 16.0312C12.9063 16.375 12.9375 16.8437 13.25 17.0937C13.4063 17.2187 13.5625 17.25 13.75 17.25C13.9375 17.25 14.1563 17.1562 14.3125 17C16.5313 14.2812 17 13.9687 17 12.75C17 11.25 15.75 10 14.25 10ZM15.1563 13.5937L15.0938 13.6562C14.875 13.875 14.5625 14 14.25 14C13.5313 14 13 13.4687 13 12.75C13 12.0625 13.5313 11.5 14.25 11.5C14.9375 11.5 15.5 12.0625 15.5 12.75C15.5 13.0937 15.3438 13.375 15.1563 13.5937ZM6.53125 3.25C6.25 2.9375 5.71875 2.9375 5.4375 3.25L2.4375 6.5C2.15625 6.8125 2.15625 7.28125 2.46875 7.5625C2.625 7.6875 2.8125 7.75 3 7.75C3.1875 7.75 3.375 7.6875 3.53125 7.53125L5.25 5.6875V16.25C5.25 16.6875 5.5625 17.0312 6 17.0312C6.40625 17.0312 6.75 16.6875 6.75 16.25V5.6875L8.4375 7.53125C8.71875 7.8125 9.1875 7.84375 9.5 7.5625C9.8125 7.28125 9.8125 6.8125 9.53125 6.5L6.53125 3.25Z"
    fill="currentColor"
  />
);

export default SortDescendingNumeric;
