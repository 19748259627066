import React from "react";

const Edit = () => (
  <path
    d="M12.9375 3.5C13.8125 2.625 15.2188 2.625 16.0938 3.5L16.5 3.90625C17.375 4.78125 17.375 6.1875 16.5 7.0625L7.71877 15.8438C7.46877 16.0938 7.12502 16.3125 6.75002 16.4063L3.62502 17.3125C3.37502 17.375 3.09377 17.3125 2.90627 17.0938C2.68752 16.9063 2.62502 16.625 2.68752 16.375L3.59377 13.25C3.68752 12.875 3.90627 12.5313 4.15627 12.2813L12.9375 3.5ZM15.0313 4.5625C14.75 4.28125 14.2813 4.28125 14 4.5625L13.0625 5.46875L14.5313 6.9375L15.4375 6C15.7188 5.71875 15.7188 5.25 15.4375 4.96875L15.0313 4.5625ZM5.03127 13.6563L4.50002 15.5L6.34377 14.9688C6.46877 14.9375 6.56252 14.875 6.65627 14.7813L13.4688 7.96875L12.0313 6.53125L5.21877 13.3438C5.12502 13.4375 5.06252 13.5313 5.03127 13.6563Z"
    fill="currentColor"
  />
);

export default Edit;
