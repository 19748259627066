import Amplify, { Auth, Hub } from "aws-amplify";

let samlError: Error | null = null;

if (window.env?.cognitoSsoProvider && window.cognitoSettings?.clientId) {
  console.log("Setting up Amplify...");

  Amplify.configure({
    aws_cognito_region: window.env.cognitoRegion,
    aws_project_region: window.env.cognitoRegion,
    aws_user_pools_id: window.env.cognitoUserPool,
    aws_user_pools_web_client_id: window.cognitoSettings.clientId,
  });

  const formSlug = window.location.pathname.split("/")[2] ?? null;

  Auth.configure({
    authenticationFlowType: window.env.cognitoLocal ? "USER_PASSWORD_AUTH" : "USER_SRP_AUTH",
    endpoint: window.env.cognitoLocal,
    oauth: {
      domain: window.cognitoSettings.clientUrl,
      redirectSignIn: window.location.origin + "/auth/" + formSlug,
      redirectSignOut: window.location.origin,
      responseType: "code",
    },
  });

  // we can't use the real type as the signature here https://github.com/aws-amplify/amplify-js/issues/7188
  Hub.listen("auth", (data: { payload: { event: string; data?: Error } }) => {
    console.log("auth event", data);
    if (data.payload.event === "cognitoHostedUI_failure") {
      samlError = data.payload.data!;
      return;
    }
    void userIsLoggedIn().then((isLoggedIn) => {
      if (!isLoggedIn) return;
      window.location.href = "/report/" + formSlug;
    });
  });
}

async function withSamlError<T>(promise: Promise<T>) {
  try {
    return await promise;
  } catch (error) {
    if (samlError) {
      const message = decodeURIComponent(samlError.message.replaceAll("+", " "));
      console.error(error);
      throw new Error("Saml error: " + message);
    }
    throw error;
  }
}

export async function userIsLoggedIn() {
  return !!(await withSamlError(Auth.currentAuthenticatedUser()));
}

export async function getJwt() {
  const session = await withSamlError(Auth.currentSession());
  return session.getIdToken().getJwtToken();
}

export async function logOut() {
  // TODO: we need this, fairly obviously.
  // Not sure if this is the right approach but:
  localStorage.clear();
}
