import React, { FunctionComponent } from "react";

const CheckIcon: FunctionComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.413 17.243" aria-hidden>
    <title>tick</title>
    <path
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="5"
      d="M1.768 7.77l5.937 5.938 11.94-11.94"
    ></path>
  </svg>
);

export default CheckIcon;
