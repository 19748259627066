import React, { Fragment } from "react";

import Badge from "../../../../shared/components/design-system/Badge";
import Icon from "../../../../shared/components/design-system/Icon";
import { useTranslate } from "../../../translation/frontend";
import { AnswerList } from "./AnsweredQuestion";
import styles from "./styles.module.scss";

export default function FreeTextAnswer({ answers, redacted }: { answers: AnswerList; redacted: boolean }) {
  const translate = useTranslate();
  return (
    <div className={styles.answerWrapper}>
      <Icon icon="lines" className={styles.reportAnswerIcon} size={20} />
      <div>
        {redacted ? <Badge label={translate("redacted")} variant="warning" className="ds-mb-3" /> : null}
        {answers.map(({ answers }, i) => (
          <Fragment key={i}>
            {answers.map((answer, index) =>
              answer.freeText && answer.freeText.trim().length > 0 ? (
                <div className={styles.redactableReportAnswer} key={index}>
                  <span className={styles.reportAnswer__text}>{answer.freeText}</span>
                </div>
              ) : !redacted ? (
                <Badge label={translate("notAnswered")} variant="warning" key={index} />
              ) : null,
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
}
