import React, { PropsWithChildren } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

import { Translatable, Translation } from "../../translation";
import Icon from "../Icon";
import { LinkClassProps, useLinkProps } from "./styles";

type LinkProps = LinkClassProps & {
  to: string;
  onClick?: () => void;
} & ({ children?: undefined; label: Translatable } | PropsWithChildren<{ label?: undefined }>);

export function InternalLink({
  to,
  onClick,
  label,
  children,
  state,
  ...props
}: LinkProps & Pick<RouterLinkProps, "state">) {
  const linkProps = useLinkProps(props);
  return (
    <RouterLink {...linkProps} to={to} onClick={onClick} state={state}>
      {label === undefined ? children : <Translation props={label} />}
    </RouterLink>
  );
}

export function ExternalLink({
  to,
  onClick,
  label,
  children,
  suppressIcon,
  ...props
}: LinkProps & { suppressIcon?: boolean }) {
  const linkProps = useLinkProps(props);
  return (
    <a onClick={onClick} {...linkProps} href={to} target="_blank" rel="noreferrer noopener">
      {label === undefined ? children : <Translation props={label} />}
      {suppressIcon ? null : <Icon inline icon="arrowUpRightFromSquare" size={20} className="ds-ml-3" />}
    </a>
  );
}
