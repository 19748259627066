import React from "react";

const ArrowRight = () => (
  <path
    d="m11.25 4.207 5.5 5.25c.156.156.25.344.25.563 0 .187-.094.375-.25.53l-5.5 5.25a.746.746 0 0 1-1.063-.03.746.746 0 0 1 .032-1.063l4.156-3.937H3.75a.74.74 0 0 1-.75-.75c0-.438.313-.75.75-.75h10.625L10.219 5.3a.746.746 0 0 1-.031-1.062c.28-.312.75-.312 1.062-.03Z"
    fill="currentColor"
  />
);

export default ArrowRight;
