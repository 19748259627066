import React from "react";

const PlusSmall = () => (
  <path
    d="m15 10c0 0.4375-0.3438 0.75-0.75 0.75h-3.5v3.5c0 0.4375-0.3438 0.7812-0.75 0.7812-0.4375 0-0.75-0.3437-0.75-0.7812v-3.5h-3.5c-0.4375 0-0.75-0.3125-0.75-0.7188 0-0.43745 0.3125-0.7812 0.75-0.7812h3.5v-3.5c0-0.40625 0.3125-0.71875 0.75-0.71875 0.4062 0 0.75 0.3125 0.75 0.71875v3.5h3.5c0.4062 0 0.75 0.34375 0.75 0.75z"
    fill="currentColor"
  />
);

export default PlusSmall;
