import React, { FunctionComponent, useCallback, useState } from "react";

import Alert from "../../../../shared/components/design-system/Alert";
import { ExternalLink } from "../../../../shared/components/design-system/Button/Link";
import { useTranslate } from "../../../translation/frontend";
import styles from "./styles.module.scss";

const ImageAnswer: FunctionComponent<{
  imageUrl: string;
}> = ({ imageUrl }) => {
  const translate = useTranslate();
  const [imageLoadFailed, setImageLoadFailed] = useState<boolean>(false);

  const imageErrored = useCallback(() => {
    setImageLoadFailed(true);
  }, []);

  return (
    <div className={`ds-vstack ${styles.imageContainer}`}>
      {imageLoadFailed ? (
        <Alert variant="warning" message={translate("invalidImage")} />
      ) : (
        <ExternalLink to={imageUrl} suppressIcon className={styles.externalLink}>
          <img className={styles.image} src={imageUrl} alt={translate("newTab")} onError={imageErrored} />
        </ExternalLink>
      )}
    </div>
  );
};

export default ImageAnswer;
