import React, { Fragment } from "react";

import Alert from "../../../../shared/components/design-system/Alert";
import Button from "../../../../shared/components/design-system/Button";
import Divider from "../../../../shared/components/design-system/Divider";
import Icon from "../../../../shared/components/design-system/Icon";
import { SectionHeader } from "../../../../shared/components/design-system/component-groups/section-header-footer";
import { useTranslate } from "../../../translation/frontend";
import { Report } from "../../../utils/types";
import AnsweredQuestion from "./AnsweredQuestion";
import styles from "./styles.module.scss";

function ReportPanel({ report }: { report: Report }) {
  const translate = useTranslate();

  const redacted = report.report.questions
    .flatMap(({ answers }) => answers)
    .some((answers) => answers && answers.redacted);

  const exportReport = async () => {
    window.print();
  };

  return (
    <div className="settingsContainer">
      <div className="messaging__container reportMessaging messagingPanel text">
        <SectionHeader title={translate("yourReport")}>
          <Button icon={"arrowToBottom"} label="Download" variant="ghost" onClick={exportReport} />
        </SectionHeader>
        <div className="print-report">
          <SectionHeader title={translate("yourReport")} className="hidden-unless-print" />
          {redacted ? <Alert className="ds-mb-7" variant="warning" message={translate("reportAmended")} /> : null}
          <div className={styles.questionWrapper}>
            <div>
              <p className={styles.questionTitle}>{translate("form")}</p>
            </div>
            <div className={styles.answerWrapper}>
              <Icon icon="radioSelection" className={`${styles.reportAnswerIcon} ${styles.circular}`} size={20} />
              <div className={styles.reportAnswer}>{report.report.form.name}</div>
            </div>
          </div>
          {report.report.questions.map((question, i) => (
            <Fragment key={i}>
              <Divider tight />
              <AnsweredQuestion key={question.question.id} answeredQuestion={question} report={report} />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ReportPanel;
