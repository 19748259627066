import React from "react";

const FlipVertical = () => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.625 9.25v-5.5a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 .75.75v5.5h.5a.74.74 0 0 1 .75.75.76.76 0 0 1-.75.75h-14.5a.74.74 0 0 1-.75-.75c0-.438.313-.75.75-.75h.5Zm1.5 0V4.5h10.5v4.75h-10.5Z"
      fill="currentColor"
    />
    <path
      d="M3.375 16c0 .531.438 1 1 1 .531 0 1-.469 1-1 0-.563-.469-1-1-1-.563 0-1 .438-1 1Zm3 0c0 .531.438 1 1 1 .531 0 1-.469 1-1 0-.563-.469-1-1-1-.563 0-1 .438-1 1Zm7 1c.531 0 1-.469 1-1 0-.563-.469-1-1-1-.563 0-1 .438-1 1 0 .531.438 1 1 1Zm-3 0c.531 0 1-.469 1-1 0-.563-.469-1-1-1-.563 0-1 .438-1 1 0 .531.438 1 1 1Zm6 0c.531 0 1-.469 1-1 0-.563-.469-1-1-1-.563 0-1 .438-1 1 0 .531.438 1 1 1Zm-1-4c0 .531.438 1 1 1 .531 0 1-.469 1-1 0-.563-.469-1-1-1-.563 0-1 .438-1 1Zm-11 1c.531 0 1-.469 1-1 0-.563-.469-1-1-1-.563 0-1 .438-1 1 0 .531.438 1 1 1Z"
      fill="currentColor"
    />
  </>
);

export default FlipVertical;
