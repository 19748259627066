import { AnswerTemplate } from "../../db/models/Answer";
import { FormInterstitial, FormQuestionGroup, QuestionGroup } from "../../db/models/Form";

type Page = QuestionGroup | FormQuestionGroup | FormInterstitial;
type Pages = Array<FormQuestionGroup | FormInterstitial> | Array<QuestionGroup | FormInterstitial>;

export function onlyIfMatches(
  onlyIf: Record<string, string> | Record<string, string[]>,
  answers: Record<string, AnswerTemplate[]>,
) {
  return Object.entries(onlyIf).every(([questionId, matches]: [string, string | string[]]) =>
    Array.isArray(matches)
      ? matches.some((match) => anyAnswerMatches(answers[questionId], match))
      : anyAnswerMatches(answers[questionId], matches),
  );
}

// Checks if the list of answers for a particular question contains a particular option
function anyAnswerMatches(answers: AnswerTemplate[] | undefined, optionId: string): boolean {
  return answers ? answers.some((answer) => answer.optionId === optionId) : false;
}

export function shouldSkipPage(page: Page, answers: Record<string, AnswerTemplate[]>) {
  if (!page.onlyIf) {
    return false;
  }
  return !onlyIfMatches(page.onlyIf, answers);
}

export function nextPage(currentPage: number, pages: Pages, answers: Record<string, AnswerTemplate[]>) {
  do {
    currentPage += 1;
  } while (currentPage <= pages.length && shouldSkipPage(pages[currentPage - 1], answers));
  return currentPage;
}

export function skipToPage(currentPage: number, pages: Pages) {
  // Submit the form straight away if we're already on the last page (or later???)
  if (currentPage >= pages.length) return pages.length + 1;

  const targetPageId = pages[currentPage].allowSkipToPage;
  const targetPageIndex = pages.findIndex((p) => p.id === targetPageId);

  // Submit the form if we're asked to do so
  if (targetPageId === "submit") return pages.length;

  // If we've been asked for a particular page, skip there
  if (targetPageIndex >= 0) return targetPageIndex + 1;

  // If we got here then we have a valid target page ID, but it doesn't appear in the form. Either the form has been edited while it was being filled in, or (more probably) this is a legacy form and the IDs are generated every time we access it — in that case we don't know which page it was supposed to be pointing at, but we can work it out by implementing the old logic. This is also safe behaviour for the "form edited while being filled in" case, so let's do that.
  if (pages[pages.length - 1].type === "INTERSTITIAL") return pages.length;
  else return pages.length + 1;
}

export function endPage(pages: Pages) {
  if (pages[pages.length - 1].type === "INTERSTITIAL") {
    return pages.length;
  }
  return pages.length + 1;
}

export function shownQuestions(pages: Pages, answers: Record<string, AnswerTemplate[]>) {
  // Run through the form starting at page 1, and note all the question IDs we encounter, in the order that we encounter them.
  const questionsToUse: string[] = [];
  for (let page = 1; page <= pages.length; page = nextPage(page, pages, answers)) {
    const group = pages[page - 1];
    if (group.type === "INTERSTITIAL") continue;
    for (const question of group.questions) {
      const questionId = typeof question === "string" ? question : question.id;
      if (!questionsToUse.includes(questionId)) {
        questionsToUse.push(questionId);
      }
    }
  }
  return questionsToUse;
}
