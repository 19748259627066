import React from "react";

const ChevronDown = () => (
  <path
    d="M16.4698 8.60098L10.501 14.3197C10.3135 14.476 10.126 14.5385 9.96976 14.5385C9.78226 14.5385 9.59476 14.476 9.43851 14.351L3.43851 8.60098C3.12601 8.31973 3.12601 7.81973 3.40726 7.53848C3.68851 7.22598 4.18851 7.22598 4.46976 7.50723L9.96976 12.7572L15.4385 7.50723C15.7198 7.22598 16.2198 7.22598 16.501 7.53848C16.7823 7.81973 16.7823 8.31973 16.4698 8.60098Z"
    fill="currentColor"
  />
);

export default ChevronDown;
