import * as Sentry from "@sentry/browser";
import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from "react";

import Button from "../../../../shared/components/design-system/Button";
import TextArea from "../../../../shared/components/design-system/TextArea";
import { useToast } from "../../../../shared/components/design-system/Toaster/context";
import { useTranslate } from "../../../translation/frontend";

const ReporterSendMessage: FunctionComponent<{
  post: (message: string) => Promise<void>;
  setLoadState: (state: boolean) => void;
}> = ({ post, setLoadState }) => {
  const toast = useToast();
  const translate = useTranslate();

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoadState(true);
  });

  const handleSendMessage = useCallback(
    async (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (loading) return;
      setLoading(true);
      try {
        await post(message);
      } catch (e) {
        // TODO: translate this
        toast.error("An error occurred while sending your message");
        Sentry.captureException(e);
        console.error(e);
      } finally {
        setLoading(false);
      }
      setMessage("");
    },
    [loading, post, message, toast],
  );

  return (
    <div className="content">
      <form onSubmit={handleSendMessage}>
        <div>
          <TextArea
            label={translate("reportMessagingSendMessage")}
            visuallyHideLabel
            value={message}
            onChange={setMessage}
            required
          />
          <div className="ds-hstack ds-my-5 ds-justify-end">
            <Button label={translate("reportMessagingSendMessage")} loading={loading} submit />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReporterSendMessage;
