import i18next, { ResourceLanguage, TOptions } from "i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";

import optionalFieldProcessor from "./audit-log/processor";
import { format } from "./format";

// Note: we use "cy" here because changing it now would be very hard,
// but "cy-GB" for the JSON files because the third-party translation tool we use requires it.
type LanguageCode = "en-GB" | "cy";
export const DEFAULT_LANGUAGE = "en-GB";

export type TranslateFunction = (key: string, options?: TOptions) => string;
export type TranslationSet = Partial<Record<LanguageCode, ResourceLanguage>>;

// Actually initialise the translation system
i18next.use(intervalPlural);
i18next.use(optionalFieldProcessor);
let initialised = false;
const i18nextPromise = i18next
  .init({ fallbackLng: DEFAULT_LANGUAGE, interpolation: { format, alwaysFormat: true } })
  .then(() => (initialised = true));

/** In the case that you know for certain i18next has already been initialised, this will get it for you. In general you should call getI18nextAsync instead. In fact in general, you shouldn't access i18next directly, you should call literally any other function in this file. */
export function getI18next() {
  if (!initialised) throw new Error("Translations have not been initialised");
  return i18next;
}

/** Waits for i18next to be initialised and then returns it. In general, though, you shouldn't access i18next directly, you should call literally any other function in this file. */
export async function getI18nextAsync() {
  return i18nextPromise;
}

const ongoingImports: Array<Promise<void>> = [];

/** Adds a bundle of translations to the translator — the idea is that we initialise the translation system in the same way everywhere, and then whatever part of the app happens to be using it can import the translation strings it needs as it loads. This is idempotent, so don't worry about loading the same translations over and over. */
export function importTranslations(namespace: string, translations: TranslationSet) {
  const i18next = getI18next();
  for (const language in translations) {
    if (translationsAreLoaded(language, namespace)) continue;
    i18next.addResourceBundle(language, namespace, translations[language as LanguageCode]);
    // Run any callbacks that are waiting (and dequeue them)
    listeners = listeners.filter((listener) => {
      if (listener.namespace !== namespace) return true;
      if (listener.language !== language) return true;
      listener.callback();
      return false;
    });
  }
}

/** Adds a bundle of translations to the translator — the idea is that we initialise the translation system in the same way everywhere, and then whatever part of the app happens to be using it can import the translation strings it needs as it loads. This is idempotent, so don't worry about loading the same translations over and over. Unlike importTranslations, but ensures the system has initialised first, which makes it slightly safer to call. */
export async function importTranslationsAsync(namespace: string, translations: TranslationSet) {
  await i18nextPromise;
  importTranslations(namespace, translations);
}

/** Returns immediately, then in the background waits for translations to initialise and then imports them. This is safe to use in synchronous settings as long as you don't need the translations to be available immediately. A wrapper around importTranslationsAsync which handles the promise — since it seems like the regular compiler is interpreting "void fn()" as "(void fn)()" and then complaining that undefined is not a function. Obviously, this is absurd, but here we are. */
export function importTranslationsBackground(namespace: string, translations: TranslationSet) {
  const promise = importTranslationsAsync(namespace, translations);
  if (process.env.NODE_ENV === "test") ongoingImports.push(promise);
  promise.catch((error) => {
    console.error("Error importing", namespace, "translations:", error);
    // This should basically never fail —
    // or if it does then it's because something more fundamental has already failed —
    // so there's not much need to handle this error properly
    // (and doing so would be hard because we don't know if we're in the browser or on the server).
  });
}

/** Tells you if a set of translations have already been imported */
export function translationsAreLoaded(namespace: string, language: string) {
  return initialised && i18next.hasResourceBundle(language, namespace);
}

/** Returns a promise that returns when all (requested) translations have been imported. Call beforeAll(waitForAllOngoingImports) in tests to prevent components re-rendering when the translations load and the testing library whining that you didn't wrap the call in "act" */
export async function waitForAllOngoingImports() {
  await i18nextPromise;
  await Promise.all(ongoingImports);
}

/** Tells you if the translation system is initialised yet */
export function translationIsInitialised() {
  return initialised;
}

let listeners: Array<{ namespace: string; language: string; callback: () => void }> = [];
/** Pass in a callback, and it'll be called when we import those translations. Does not run the callback immediately if the resource is already loaded */
export function listenForTranslations(namespace: string, language: string, callback: () => void) {
  if (translationsAreLoaded(language, namespace)) return;
  listeners.push({ namespace, language, callback });
}
