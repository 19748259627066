import React from "react";

import iconComponents from "./icons";
import smallIconComponents from "./small-icons";
import styles from "./styles.module.scss";

export type IconSize = 16 | 20 | 24 | 32;
export type IconSlug = keyof typeof iconComponents;

function getIcon(slug: IconSlug, size: IconSize) {
  if (size < 20) {
    const icon = smallIconComponents[slug];
    if (icon) {
      return {
        viewBox: "2 2 16 16",
        SvgPath: icon,
      };
    }
  }
  return {
    viewBox: "0 0 20 20",
    SvgPath: iconComponents[slug],
  };
}

const Icon = ({
  icon,
  size = 20,
  className,
  inline,
}: {
  icon: IconSlug | null;
  size?: IconSize;
  className?: string;
  inline?: boolean;
}) => {
  if (!icon) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        className={`${inline ? styles.Icon_inline : ""} ${className ?? ""}`}
        aria-hidden="true"
      />
    );
  }
  const { viewBox, SvgPath } = getIcon(icon, size);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={viewBox}
      className={`${inline ? styles.Icon_inline : ""} ${className ?? ""}`}
      aria-hidden="true"
    >
      <SvgPath />
    </svg>
  );
};

export default Icon;
