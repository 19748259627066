import React, { PropsWithChildren } from "react";

import { useTranslate } from "../../translation/frontend";
import ArrowRightIcon from "../icons/ArrowRightIcon";

export function Container({ children }: PropsWithChildren<unknown>) {
  return (
    <section className="form-group section report-details report-details--left">
      <div className="container--slim">
        <div className="content-styles">{children}</div>
      </div>
    </section>
  );
}

export function InterstitialBody({ body }: { body: string }) {
  return <div className="content-styles allow-cross-form-links" dangerouslySetInnerHTML={{ __html: body }} />;
}

export function ContinueButton({ disabled }: { disabled?: boolean }) {
  const translate = useTranslate();
  return (
    <button className="btn btn--continue btn--swipe btn--arrow" name="_action" value="next" disabled={disabled}>
      <span>{translate("continueLabel")}</span>
      <span className="btn__arrow">
        <ArrowRightIcon />
      </span>
    </button>
  );
}
