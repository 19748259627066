import React from "react";

const TrashXmark = () => (
  <path
    d="M16.25 4.5h-2.344l-1.062-1.75c-.281-.469-.75-.75-1.281-.75H8.405c-.531 0-1 .281-1.281.75L6.062 4.5H3.75a.74.74 0 0 0-.75.75c0 .438.313.75.75.75H4l.656 10.594c.032.812.688 1.406 1.5 1.406h7.657c.812 0 1.468-.594 1.5-1.406L16 6h.25a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75Zm-7.844-1h3.156l.594 1H7.812l.594-1Zm5.406 13H6.157L5.5 6h8.969l-.656 10.5ZM7.47 14.031a.736.736 0 0 0 1.031 0l1.469-1.469 1.5 1.47a.736.736 0 0 0 1.031 0c.313-.313.313-.782 0-1.063l-1.47-1.469 1.469-1.469A.684.684 0 0 0 12.5 9c-.281-.313-.75-.313-1.063 0L9.97 10.469l-1.47-1.5c-.313-.281-.781-.281-1.063 0-.312.312-.312.781 0 1.062l1.5 1.469L7.47 13a.684.684 0 0 0 0 1.031Z"
    fill="currentColor"
  />
);

export default TrashXmark;
