import React, { PropsWithChildren } from "react";

import { useTranslate } from "../translation/frontend";
import { useForm } from "./context";

export default function ReportFormMain({ children }: PropsWithChildren<unknown>) {
  const translate = useTranslate();
  const { loading, onNext, formRef, csrfToken, fullPageHistory, sessionId, answers } = useForm();

  return (
    <form
      method="post"
      encType="multipart/form-data"
      className={`form ${loading ? "loading" : ""}`}
      onSubmit={onNext}
      ref={formRef}
    >
      {loading ? (
        <div className="loading-cover">
          <div className="loading-cover__modal" role="status">
            <span className="loading-cover__main">{translate("sendingReport")}</span>
            <span className="loading-cover__detail">{translate("doNotCloseTab")}</span>
          </div>
        </div>
      ) : null}
      <input type="hidden" name="_csrf" value={csrfToken} />
      <input type="hidden" name="_page" value={fullPageHistory.join(",")} />
      <input type="hidden" name="sessionId" value={sessionId} />
      <input type="hidden" name="previous-answers" value={JSON.stringify(answers)} className="hidden-answer-field" />
      {children}
    </form>
  );
}
