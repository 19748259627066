import React from "react";

const ArrowUpRightFromSquare = () => (
  <path
    d="M11.75 4.5a.722.722 0 0 1-.75-.75.74.74 0 0 1 .75-.75h4.5a.76.76 0 0 1 .75.75v4.5a.74.74 0 0 1-.75.75.722.722 0 0 1-.75-.75V5.562l-6.25 6.22a.684.684 0 0 1-1.031 0c-.313-.282-.313-.75 0-1.063L14.438 4.5H11.75ZM3 5.75C3 4.812 3.781 4 4.75 4h3.5a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75h-3.5a.246.246 0 0 0-.25.25v9.5c0 .156.094.25.25.25h9.5c.125 0 .25-.094.25-.25v-3.5a.74.74 0 0 1 .75-.75.76.76 0 0 1 .75.75v3.5c0 .969-.813 1.75-1.75 1.75h-9.5C3.781 17 3 16.219 3 15.25v-9.5Z"
    fill="currentColor"
  />
);

export default ArrowUpRightFromSquare;
