import React, { useContext } from "react";

/*
In the dashboard we use SCSS Modules, which are great for client-rendered code.

In the reporting sites, we use PostCSS, which is good for server-rendered code (and fine for client-rendered code).

As much as we'd like to swithc to SCSS for everything, we probably won't be able to use modules in the reporting sites, because we need to be able to run without client-side JS. So this file is kind of a wrapper that allows the two to play nicely.

So, for this component:

  function SomeComponent() {
    const style = useStyle("StyleName");
    return <div className={style} />;
  }

In the dashboard:

  .StyleName { ... }

  import styles from "./styles.module.scss";
  // ...
  <ThemeProvider value={{ styles, stringFallback: false }}>
    <SomeComponent />
  </ThemeProvider>

In the sites:

  .cs-some-component { ... }

  <link rel="stylesheet" href="./styles.css">
  // ...
  <ThemeProvider value={{ styles: { StyleName: "cs-some-component" }, stringFallback: false }}>
    <SomeComponent />
  </ThemeProvider>

If you use this hook without a theme provider, it'll just return nothing and no styling will happen, which might be what you want.

*/

export type Theme = { styles: Record<string, string>; stringFallback: boolean };

const themeContext = React.createContext<Theme>({ styles: {}, stringFallback: false });

export default function useStyle(className: string) {
  const { styles, stringFallback } = useContext(themeContext);
  return styles[className] ?? (stringFallback ? className : "");
}

export const ThemeProvider = themeContext.Provider;
