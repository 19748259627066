import React from "react";

const Italic = () => (
  <path
    d="M16.2 3.75a.74.74 0 0 1-.75.75h-2.125l-4.594 11h2.72a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75h-6.5a.722.722 0 0 1-.75-.75.74.74 0 0 1 .75-.75h2.187l4.593-11h-2.78a.722.722 0 0 1-.75-.75.74.74 0 0 1 .75-.75h6.5a.76.76 0 0 1 .75.75Z"
    fill="currentColor"
  />
);

export default Italic;
