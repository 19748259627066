import React, { ReactNode, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import { useDebouncedFunction } from "../../../hooks/useDebounce";
import Alert from "../Alert";
import type { ToastData, ToastExtra } from "./context";
import styles from "./styles.module.scss";

const SPACING = 16;

export type ToasterMarketingPromptProps = {
  extraMarketingPromptBody?: ReactNode;
  extraMarketingPromptCta?: string;
  extraMarketingPromptLink?: string;
  extraMarketingPromptFeatureId: string;
};

const container = (document.getElementById("toaster") ??
  (process.env.NODE_ENV === "test" ? document.createElement("div") : null))!;

function Toaster({
  toasts,
  extras,
  remove,
}: {
  toasts: ToastData[];
  extras: ToastExtra[];
  remove: (id: string) => void;
}) {
  const extraToasts = useRef<HTMLDivElement>(null);

  const [{ totalHeight, ...positions }, setPositions] = useState<Record<string, number>>({});

  // It's good to debounce this but also it's also important since when the children props change, we don't want to recalculate the toast positions until the children have rerendered and we know how tall they are. We also don't want a long debounce delay because between the props update and the debounced recalculation, you can get some quite ugly positionings.
  const updatePositions = useDebouncedFunction(
    () =>
      setPositions((oldPositions) => {
        const newPositions: Record<string, number> = {};
        let totalHeight = 0;
        if (extraToasts.current?.childElementCount) {
          totalHeight += extraToasts.current.getBoundingClientRect().height + SPACING;
        }
        for (let i = 0; i < toasts.length; ++i) {
          const { id, closed } = toasts[i];
          const el = document.getElementById(id);
          const height = el ? el.getBoundingClientRect().height + SPACING : 0;
          if (!closed) {
            // The current toast's position is the total height of the ones below it.
            newPositions[id] = totalHeight;
            totalHeight += height;
          } else {
            // Closed toasts animate off horizontally, so even if another toast changes where they *should* be, we don't update their position.
            newPositions[id] = oldPositions[id];
            // Toasts above them shouldn't fall into their space until their closing animation is finished, but they *should* be pushed up by new toasts, so if the next toast is already higher than our current running total height, skip to that position and carry on.
            const nextToast = toasts[i + 1];
            if (nextToast) {
              const nextToastPos = oldPositions[nextToast.id];
              if (totalHeight < nextToastPos) totalHeight = nextToastPos;
            }
          }
        }
        return { ...newPositions, totalHeight };
      }),
    10,
  );

  useEffect(() => {
    window.addEventListener("resize", updatePositions);
    return window.removeEventListener("resize", updatePositions);
  }, [updatePositions]);

  useEffect(updatePositions, [updatePositions, toasts, extras]);

  return ReactDOM.createPortal(
    <div className={styles.Toaster} role="status" style={{ height: totalHeight }}>
      <div className={styles.toastContainer}>
        {[...toasts].reverse().map(({ id, options: { variant, title, actions }, content, closed }: ToastData) => (
          <div
            id={id}
            key={id}
            aria-hidden={closed}
            className={closed ? styles.closedToast : styles.toast}
            style={{ bottom: positions[id] ?? "-10em" }}
          >
            <Alert
              className={styles.toastAlert}
              id={id}
              variant={variant}
              title={title}
              message={content}
              onClickClose={() => remove(id)}
              actions={actions}
            />
          </div>
        ))}
        <div className={styles.extraToastContainer} ref={extraToasts}>
          {extras.map((extra) => (
            <extra.Component {...extra.props} key={extra.id} />
          ))}
        </div>
      </div>
    </div>,
    container,
  );
}

export default Toaster;
