import * as Sentry from "@sentry/browser";
import { useCallback, useMemo, useState } from "react";

import { useToast } from "../../../shared/components/design-system/Toaster/context";
import HttpError from "../../HttpError";
import { SerialisedAccount } from "../api/account";
import { del, get } from "./request";

export default function useAccount() {
  const [authCode, setAuthCode] = useState<string>("");
  const [account, setAccount] = useState<SerialisedAccount | null>();
  const [showAccountDeleteWarning, setShowAccountDeletedWarning] = useState<boolean>(false);
  const toast = useToast();

  // Even though we generally know the auth code because it's in state,
  // this function takes it as an argument so that it can be used to test an auth code
  // before we store it.
  const logIn = useCallback(
    async (authCode: string) => {
      try {
        const result = await get<SerialisedAccount>("/account", authCode);
        setAuthCode(authCode);
        setAccount(result);
        return true;
      } catch (e) {
        if (e instanceof HttpError && e.code === 401) return false;
        // TODO: translate this
        toast.error("An unexpected error occurred while logging you in.");
        Sentry.captureException(e);
        console.error(e);
        throw e;
      }
    },
    [toast],
  );

  const deleteAccount = useCallback(async () => {
    try {
      await del<SerialisedAccount>("/account", authCode);
      setAuthCode("");
      setAccount(null);
      setShowAccountDeletedWarning(true);
    } catch (e) {
      toast.error("An unexpected error occurred whilst deleting your account");
      Sentry.captureException(e);
      console.error(e);
      throw e;
    }
  }, [toast, authCode]);

  return useMemo(
    () => ({
      loggedIn: !!account,
      account: account ?? null,
      logIn,
      authCode,
      deleteAccount,
      showAccountDeleteWarning,
    }),
    [account, logIn, authCode, deleteAccount, showAccountDeleteWarning],
  );
}
