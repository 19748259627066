import { useTranslate as useTranslateWithNamespace } from "../../shared/components/translation";
import { importTranslationsBackground } from "../../shared/translation/initialisation";
import welsh from "./translations/cy-GB.json";
import english from "./translations/en-GB.json";

importTranslationsBackground("sites", { "en-GB": english, cy: welsh });

export function useTranslate() {
  return useTranslateWithNamespace("sites");
}
