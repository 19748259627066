import React from "react";

const Undo = () => (
  <path
    d="M3.25 2.5a.76.76 0 0 1 .75.75v3.219a6.956 6.956 0 0 1 6-3.438c3.844 0 7 3.125 7 6.969 0 3.875-3.156 7-7 7a7.066 7.066 0 0 1-4.5-1.594.779.779 0 0 1-.094-1.062c.282-.313.75-.375 1.063-.094.968.813 2.218 1.25 3.531 1.25 3.031 0 5.5-2.438 5.5-5.5 0-3.031-2.469-5.5-5.5-5.5-2.094 0-3.969 1.219-4.906 3H8.25a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75h-5a.722.722 0 0 1-.75-.75v-5a.74.74 0 0 1 .75-.75Z"
    fill="currentColor"
  />
);

export default Undo;
