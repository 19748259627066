import React from "react";

const Message = () => (
  <path
    d="M15.969 2h-12c-1.125 0-2 .906-2 2v8.969c0 1.094.875 1.969 2 1.969h3v2.624c0 .313.343.47.593.282l3.907-2.907h4.5c1.094 0 2-.906 2-1.968V4c0-1.094-.875-2-2-2Zm.531 11c0 .281-.25.5-.5.5h-5l-2.5 1.875V13.5H4a.494.494 0 0 1-.5-.5V4c0-.25.219-.5.5-.5h12c.25 0 .5.25.5.5v9Z"
    fill="currentColor"
  />
);

export default Message;
