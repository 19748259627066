import React, { ChangeEvent, useCallback, useState } from "react";

import { AnswerTemplate } from "../../db/models/Answer";
import Question from "../../db/models/Question";
import { useTranslate } from "../translation/frontend";
import ErrorMessage from "./ErrorMessage";
import { useInputValidity } from "./useInvalidQuestions";

const FILE_SIZE_LIMIT = 25; // MB

export default function QuestionImage({ question, answers }: { question: Question; answers: AnswerTemplate[] }) {
  const translate = useTranslate();
  const { markValid, markInvalid } = useInputValidity({ question });

  const handleOnInvalid = useCallback(() => markInvalid(), [markInvalid]);

  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  const uploadedFiles = answers.filter((answer) => "fileContent" in answer);
  return (
    <>
      <ErrorMessage field={{ question }} />
      <input
        className="form-control"
        id={question.id}
        name={question.id}
        required={question.required && uploadedFiles.length === 0}
        type="file"
        accept="image/*"
        multiple
        onChange={(ev: ChangeEvent<HTMLInputElement>) => {
          if (!ev.currentTarget.files) {
            markValid();
            return;
          }
          const files = Array.from(ev.currentTarget.files);
          if (files.reduce((a, file) => a + file.size, 0) > FILE_SIZE_LIMIT * 1_000_000) {
            setWarningMessage("filesTooLargeError");
            ev.currentTarget.value = "";
            return;
          }
          if (files.some((file) => !file.type.startsWith("image/"))) {
            setWarningMessage("imageFormatError");
            ev.currentTarget.value = "";
            return;
          }
          setWarningMessage(null);
          markValid();
        }}
        onInvalid={handleOnInvalid}
      />
      {warningMessage ? (
        <p
          className="warning-message warning-message--icon"
          dangerouslySetInnerHTML={{ __html: translate(warningMessage, { limit: FILE_SIZE_LIMIT }) }}
        />
      ) : null}
      {uploadedFiles.length > 0 ? <p>{translate("selectedFileCount", { count: uploadedFiles.length })}</p> : null}
    </>
  );
}
