import React from "react";

const Browser = () => (
  <path
    d="M2.219 4.063c.187-.313.437-.594.781-.782.219-.125.5-.219.781-.25C3.844 3.031 3.906 3 4 3h12c1.094 0 2 .906 2 2v10c0 1.125-.906 2-2 2H4c-1.125 0-2-.875-2-2V4.812a1.91 1.91 0 0 1 .219-.75ZM3.5 15c0 .281.219.5.5.5h12c.25 0 .5-.219.5-.5V7h-13v8Z"
    fill="currentColor"
  />
);

export default Browser;
