import React from "react";

const UserPlus = () => (
  <path
    d="M7 10c2.188 0 4-1.781 4-4 0-2.188-1.813-4-4-4-2.219 0-4 1.813-4 4 0 2.219 1.781 4 4 4Zm0-6.5c1.375 0 2.5 1.125 2.5 2.5 0 1.406-1.125 2.5-2.5 2.5A2.468 2.468 0 0 1 4.5 6c0-1.375 1.094-2.5 2.5-2.5Zm1.563 8H5.405c-3 0-5.406 2.438-5.406 5.438C0 17.53.469 18 1.063 18h11.843C13.5 18 14 17.531 14 16.937c0-3-2.438-5.437-5.438-5.437Zm-7.063 5c.219-1.938 1.875-3.5 3.906-3.5h3.157c2.03 0 3.656 1.563 3.906 3.5H1.5Zm17.75-8.25h-1.5v-1.5A.76.76 0 0 0 17 6a.74.74 0 0 0-.75.75v1.5h-1.5A.74.74 0 0 0 14 9c0 .438.313.75.75.75h1.5v1.5c0 .438.313.75.75.75a.74.74 0 0 0 .75-.75v-1.5h1.5A.74.74 0 0 0 20 9a.76.76 0 0 0-.75-.75Z"
    fill="currentColor"
  />
);

export default UserPlus;
