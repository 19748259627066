import React, { PropsWithChildren } from "react";

import { TranslationSet } from "../../db/models/Question";
import { useLanguage } from "../../shared/components/translation";

/** Given a language, and a set of translated values, finds the matching value. If none exists, returns null. */
export function translateDbString(translations: TranslationSet | undefined, language: string) {
  return translations?.find((translation) => translation.language === language)?.text ?? null;
}

/** <Translated translations={translationSet}>example</Translated> will render the appropriate localised value from translationSet, if there is one, and render the children ("example") otherwise. Uses the same langage context as the i18next translation system. */
export function Translated({
  translations,
  children,
}: PropsWithChildren<{ translations: TranslationSet | undefined }>) {
  const language = useLanguage();
  return <>{translateDbString(translations, language) ?? children ?? null}</>;
}
