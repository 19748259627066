import React from "react";

const Chart = () => (
  <path
    d="M16.5 16.25a.76.76 0 0 1-.75.75.74.74 0 0 1-.75-.75v-7.5c0-.438.313-.75.75-.75a.74.74 0 0 1 .75.75v7.5Zm-4 0a.76.76 0 0 1-.75.75.74.74 0 0 1-.75-.75V3.75c0-.438.313-.75.75-.75a.74.74 0 0 1 .75.75v12.5Zm-5.5 0v-7.5c0-.438.313-.75.75-.75a.74.74 0 0 1 .75.75v7.5a.76.76 0 0 1-.75.75.74.74 0 0 1-.75-.75Zm-2.5 0a.76.76 0 0 1-.75.75.74.74 0 0 1-.75-.75V3.75c0-.438.313-.75.75-.75a.74.74 0 0 1 .75.75v12.5Z"
    fill="currentColor"
  />
);

export default Chart;
