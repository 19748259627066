import React from "react";

const CircleInfo = () => (
  <path
    d="M10 2c-4.438 0-8 3.594-8 8 0 4.438 3.563 8 8 8 4.406 0 8-3.563 8-8 0-4.406-3.594-8-8-8Zm0 14.5A6.495 6.495 0 0 1 3.5 10c0-3.563 2.906-6.5 6.5-6.5 3.563 0 6.5 2.938 6.5 6.5 0 3.594-2.938 6.5-6.5 6.5Zm1.25-4h-.5V9.75A.76.76 0 0 0 10 9H9a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h.25v2h-.5a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h2.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75ZM10 8c.531 0 1-.438 1-1 0-.531-.469-1-1-1-.563 0-1 .469-1 1 0 .563.438 1 1 1Z"
    fill="currentColor"
  />
);

export default CircleInfo;
