import React from "react";

const Calendar = () => (
  <path
    d="M7.75 4h4.5V2.75A.74.74 0 0 1 13 2a.76.76 0 0 1 .75.75V4H15c1.094 0 2 .906 2 2v10c0 1.125-.906 2-2 2H5c-1.125 0-2-.875-2-2V6c0-1.094.875-2 2-2h1.25V2.75A.74.74 0 0 1 7 2a.76.76 0 0 1 .75.75V4ZM4.5 16c0 .281.219.5.5.5h10c.25 0 .5-.219.5-.5V8h-11v8Z"
    fill="currentColor"
  />
);

export default Calendar;
