import React from "react";

const Trash = () => (
  <path
    d="M16.25 4.5H13.9062L12.8438 2.75C12.5625 2.28125 12.0938 2 11.5625 2H8.40625C7.875 2 7.40625 2.28125 7.125 2.75L6.0625 4.5H3.75C3.3125 4.5 3 4.84375 3 5.25C3 5.6875 3.3125 6 3.75 6H4L4.65625 16.5938C4.6875 17.4062 5.34375 18 6.15625 18H13.8125C14.625 18 15.2812 17.4062 15.3125 16.5938L16 6H16.25C16.6562 6 17 5.6875 17 5.25C17 4.84375 16.6562 4.5 16.25 4.5ZM8.40625 3.5H11.5625L12.1562 4.5H7.8125L8.40625 3.5ZM13.8125 16.5H6.15625L5.5 6H14.4688L13.8125 16.5Z"
    fill="currentColour"
  />
);

export default Trash;
