import { AnswerTemplate } from "../../../db/models/Answer";
import Question from "../../../db/models/Question";
import { isUuid } from "../../../shared/validation/uuid";
import HttpError from "../../HttpError";

// entries in req.body and the value map more generally usually contain one or more input values, parsed into a string[]
// but these arrays might also have some Promise<string> elements thrown in, because parsing files to data URLs is asynchronous in the browser (see form/index.tsx)

export type AnswerMap = Record<string, AnswerTemplate[]>;
export type ValueList = Array<string | AnswerTemplate | Promise<string>>;
export type ValueMap = Record<string, string | AnswerTemplate | ValueList | AnswerMap> & {
  "previous-answers"?: string | string[];
};

export function getValueArray(values: ValueMap, id: string): ValueList {
  if (!values[id]) values[id] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!Array.isArray(values[id])) values[id] = [values[id] as any];
  return values[id] as ValueList;
}

async function getAnswerValues(values: ValueMap, id: string): Promise<string[] | null> {
  if (!(id in values)) return null;
  const answers = await Promise.all(getValueArray(values, id));
  for (const answer of answers) {
    if (typeof answer !== "string") {
      throw new HttpError(400, "Invalid answer");
    }
  }
  return answers as string[];
}

function getPreviousAnswers(values: ValueMap): AnswerMap {
  let previousAnswersValue: string | string[] | undefined = values["previous-answers"];

  // On (say) the first page, this won't exist and that's fine.
  if (previousAnswersValue === undefined) return {};

  // In the browser, everything is an array, but there's only one entry for this one so get it out of the array.
  if (Array.isArray(previousAnswersValue)) {
    if (previousAnswersValue.length !== 1) {
      throw new HttpError(400, "Only one value is allowed for previous answers");
    }
    previousAnswersValue = previousAnswersValue[0] as string;
  }

  // If there's anything other than a string at this point, something has gone wrong.
  if (typeof previousAnswersValue !== "string") {
    throw new HttpError(500, "previousAnswersValue should be a string");
  }

  // If it's a string, it should be a JSON-encoded AnswerMap.
  if (previousAnswersValue[0] !== "{") {
    throw new HttpError(400, "Previous answers must be an object");
  }
  const parsedAnswers: AnswerMap = (() => {
    try {
      return JSON.parse(previousAnswersValue as string);
    } catch {
      throw new HttpError(400, "Failed to parse previous answers");
    }
  })();

  // Do some validation to make sure it looks like an AnswerMap.
  for (const [id, values] of Object.entries(parsedAnswers)) {
    if (!isUuid(id)) {
      throw new HttpError(400, "Malformed question ID");
    }
    if (!Array.isArray(values)) {
      throw new HttpError(400, "Expected array in previous answer");
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    for (const value of values as any[]) {
      if (value.optionId && !isUuid(value.optionId)) {
        throw new HttpError(400, "Malformed option ID");
      }
      if (value.optionText && typeof value.optionText !== "string") {
        throw new HttpError(400, "optionText must be a string");
      }
      if (value.freeText && typeof value.freeText !== "string") {
        throw new HttpError(400, "freeText must be a string");
      }
      if (value.fileContents && typeof value.fileContents !== "string") {
        throw new HttpError(400, "fileContents must be a string");
      }
    }
  }

  return parsedAnswers;
}

export async function parseInputValues(questions: Question[], inputValues: ValueMap): Promise<AnswerMap> {
  // First we populate the answers field with anything from other pages.
  const answers = getPreviousAnswers(inputValues);

  // Lastly, look at each question and find any input values that correspond to it
  for (const question of questions) {
    const questionValues = await getAnswerValues(inputValues, question.id);
    if (!questionValues) continue;
    // Image questions should be passed through as data URLs
    if (question.questionType === "IMAGE") {
      answers[question.id] = questionValues.map((fileContents) => ({ fileContents }));
      continue;
    }
    const questionAnswers = questionValues.map(parseInputValue);
    // Now scan through the question's options and add any "other, please state" text in
    for (const optionId of allOptionIds(question)) {
      const inputs = (await getAnswerValues(inputValues, optionId)) ?? [];
      if (inputs.length === 0) continue;
      const freeText = inputs.join("\n\n"); // There should only be one but let's be safe.
      // Tag this text onto the appropriate checkbox entry if we can find it, otherwise just store it as its own entry.
      const existingAnswer = questionAnswers.find((answer) => answer.optionId === optionId && !answer.freeText);
      if (existingAnswer) existingAnswer.freeText = freeText;
      else questionAnswers.push({ optionId, freeText });
    }
    // Lastly, scan through any options that have an empty string "other" response but weren't checked. That's a submitting artefact and we can remove it.
    for (const optionId of allOptionIds(question)) {
      const answer = questionAnswers.find((answer) => answer.optionId === optionId);
      if (answer && answer.freeText === "" && !answer.optionText) {
        questionAnswers.splice(questionAnswers.indexOf(answer, 1));
      }
    }
    answers[question.id] = questionAnswers;
  }
  return answers;
}

// Find anything that could conceivably be an option ID — if there's an input matching an option that's been deleted or that doesn't expect "other" text then (a) capturing it is still desirable, and (b) the question having been changed since the form was loaded is more likely than a UUID collision.
function* allOptionIds(question: Question) {
  if (question.optionGroups) {
    for (const group of question.optionGroups) {
      for (const option of group.options) {
        yield option.id;
      }
    }
  }
  if (question.deletedOptions) {
    for (const option of question.deletedOptions) {
      yield option.id;
    }
  }
}

function parseInputValue(value: string): AnswerTemplate {
  // The value might be a JSON-encoded optionId/optionName pair — attempt to parse it and if it looks right then use the parsed version. If not then fall through to the next option.
  if (value[0] === "{") {
    try {
      const parsed = JSON.parse(value);
      if (
        typeof parsed.optionId === "string" &&
        typeof parsed.optionText === "string" &&
        Object.keys(parsed).length === 2
      ) {
        return parsed;
      }
    } catch {
      // Do nothing — maybe the user just typed something starting in a curly bracket 🤷
    }
  }
  // If it wasn't JSON, it's just a string.
  return { freeText: value };
}
