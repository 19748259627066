import React, { PropsWithChildren, ReactNode, useCallback } from "react";

import "./styles.scss";

/** This is for use with "real" controls — like actual HTML <input>, <textarea>, etc. It won't work for contenteditable divs (see below) */
export interface InputLabelProps {
  visuallyHidden?: boolean;
  /** This is optional — you can wrap the input in the label. */
  htmlFor?: string;
  className?: string;
}

/** This is for contenteditable divs — it might work with regular inputs but don't use it for them (see above). Currently this is only used in the inline lexical editor. */
export interface ContentEditableLabelProps {
  htmlFor: string;
  className?: string;
  /** For use with aria-labelled-by on an input component */
  id: string;
}

export default function InputLabel({
  children,
  htmlFor,
  className,
  visuallyHidden,
}: PropsWithChildren<InputLabelProps>) {
  return (
    <label className={`c-input-label ${visuallyHidden ? "is-sr-only" : ""} ${className}`} htmlFor={htmlFor}>
      {children}
    </label>
  );
}

export function ContentEditableLabel({
  children,
  htmlFor,
  className,
  id,
}: PropsWithChildren<ContentEditableLabelProps>) {
  const onClickHandler = useCallback(() => {
    if (!htmlFor) return;
    const labelEl = document.getElementById(htmlFor!);
    labelEl?.focus();
  }, [htmlFor]);

  return (
    <span onClick={onClickHandler} id={id} className={`c-input-label ${className}`}>
      {children}
    </span>
  );
}

type Level = 1 | 2 | 3 | 4 | 5 | 6;
/** A heading styled to look like a label (or just a div) */
export function LabelHeading({
  className,
  level,
  badges,
  children,
}: PropsWithChildren<{ className?: string; level?: Level; badges?: ReactNode }>) {
  const Tag: `h${Level}` | "div" = level ? `h${level}` : "div";
  return (
    <Tag className={`c-input-label ${className}`}>
      <span className="c-input-label__main">{children}</span>
      {badges}
    </Tag>
  );
}
