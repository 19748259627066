import { usePreventScroll } from "@react-aria/overlays";
import React, { useState, createContext, useMemo } from "react";
import { PropsWithChildren } from "react";

// The "root" provider tracks which modal is currently the topmost one — that is, the one that should get focus.

interface RootPayload {
  topmostModal: string | null;
  setTompostModal: (id: string | null) => void;
}

export const rootContext = createContext<RootPayload>({
  topmostModal: null,
  setTompostModal: () => {
    throw new Error("Do not use Modal without a RootProvider.");
  },
});

export default function RootProvider({ children }: PropsWithChildren<unknown>) {
  const [topmostModal, setTompostModal] = useState<string | null>(null);
  const payload = useMemo(() => ({ topmostModal, setTompostModal }), [topmostModal]);

  usePreventScroll({ isDisabled: !topmostModal });

  return (
    <div aria-hidden={!!topmostModal}>
      <rootContext.Provider value={payload}>{children}</rootContext.Provider>
    </div>
  );
}
