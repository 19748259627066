import React from "react";

const UserGroup = () => (
  <>
    <path
      d="M7 11c2.188 0 4-1.781 4-4 0-2.188-1.813-4-4-4-2.219 0-4 1.813-4 4 0 2.219 1.781 4 4 4Zm0-6.5c1.375 0 2.5 1.125 2.5 2.5 0 1.406-1.125 2.5-2.5 2.5A2.468 2.468 0 0 1 4.5 7c0-1.375 1.094-2.5 2.5-2.5Zm1.563 8H5.405c-3 0-5.406 2.438-5.406 5.438C0 18.53.469 19 1.063 19h11.843C13.5 19 14 18.531 14 17.937c0-3-2.438-5.437-5.438-5.437Zm-7.063 5c.219-1.938 1.875-3.5 3.906-3.5h3.157c2.03 0 3.656 1.563 3.906 3.5H1.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.707 3.215a4 4 0 1 1 .026 7.58.75.75 0 0 1 .475-1.424 2.5 2.5 0 1 0-.016-4.737.75.75 0 0 1-.485-1.42ZM13.25 13.25a.75.75 0 0 1 .75-.75h.904c.968 0 2.234.371 3.267 1.28C19.23 14.713 20 16.173 20 18.25a.75.75 0 0 1-.75.75h-3.604a.75.75 0 0 1 0-1.5h2.81c-.15-1.242-.674-2.063-1.276-2.593-.741-.653-1.648-.907-2.276-.907H14a.75.75 0 0 1-.75-.75Z"
      fill="currentColor"
    />
  </>
);

export default UserGroup;
