import React from "react";

import { DEFAULT_TITLE } from "../components/charts/ChartLegend";
import InDetailBarChart from "../components/charts/InDetailBarChart";
import InDetailTable from "../components/charts/InDetailTable";
import OverTimeBarChart from "../components/charts/OverTimeBarChart";
import OverTimeTable from "../components/charts/OverTimeTable";
import useStyle from "../components/theme";
import { InDetailChartBlockOptions, OverTimeChartBlockOptions } from "./chart-block-types";
import { ContentCaption } from "./react";
import { Caption } from "./types";

export function InDetailChartBlock({ options, caption }: { options: InDetailChartBlockOptions; caption: Caption }) {
  const className = useStyle("ChartContainer");

  if (options.chartType === "TABLE") {
    return (
      <div className="tableBlock">
        <InDetailTable data={options.data} options={options.options} />
        <ContentCaption caption={caption} />
      </div>
    );
  }

  return (
    <div className={className}>
      <InDetailBarChart
        data={options.data}
        options={options.options}
        legendTitle={DEFAULT_TITLE}
        showMultipleAnswerWarning={false}
      />
      <ContentCaption caption={caption} />
    </div>
  );
}

export function OverTimeChartBlock({ options, caption }: { options: OverTimeChartBlockOptions; caption: Caption }) {
  const className = useStyle("ChartContainer");

  if (options.chartType === "TABLE") {
    return (
      <div className="tableBlock">
        <OverTimeTable
          data={options.data}
          month={options.month}
          year={options.year}
          comparisonYear={options.comparisonYear}
        />
        <ContentCaption caption={caption} />
      </div>
    );
  }

  return (
    <div className={className}>
      <OverTimeBarChart
        year={options.year}
        comparisonYear={options.comparisonYear}
        startMonth={options.month}
        data={options.data}
      />
      <ContentCaption caption={caption} />
    </div>
  );
}
