import React from "react";

const SignOut = () => (
  <path
    d="M8 16.25a.74.74 0 0 1-.75.75H5c-1.688 0-3-1.313-3-3V6c0-1.656 1.313-3 3-3h2.25a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75H5A1.5 1.5 0 0 0 3.5 6v8c0 .844.656 1.5 1.5 1.5h2.25a.76.76 0 0 1 .75.75Zm9.781-6.75-3.968-4.25c-.282-.313-.75-.313-1.063-.031-.313.281-.313.75-.031 1.062L15.5 9.25H7.719C7.312 9.25 7 9.594 7 10c0 .438.313.75.719.75h7.75l-2.813 3c-.281.313-.281.781.031 1.063a.996.996 0 0 0 .532.187.732.732 0 0 0 .531-.219l3.969-4.25A.687.687 0 0 0 17.78 9.5Z"
    fill="currentColor"
  />
);

export default SignOut;
