import React from "react";

const GripDotsVertical = () => (
  <path
    d="M7 5c0-.531.438-1 1-1 .531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1Zm0 5c0-.531.438-1 1-1 .531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1Zm2 5c0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1 .531 0 1 .469 1 1Zm2-10c0-.531.438-1 1-1 .531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1Zm2 5c0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1 .531 0 1 .469 1 1Zm-2 5c0-.531.438-1 1-1 .531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1Z"
    fill="currentColor"
  />
);

export default GripDotsVertical;
