import type { TOptions } from "i18next";
import React, {
  createContext,
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { useForceRerender } from "../hooks/useForceRerender";
import {
  getI18next,
  getI18nextAsync,
  listenForTranslations,
  translationIsInitialised,
} from "../translation/initialisation";

export const DEFAULT_LANGUAGE = "en-GB";

export type FullTranslatable = TOptions & { t: string };

/** A simple wrapper around a string to say "don't translate this". It's designed for when we have a component which will assume plain strings are translation keys — normally that's fine because the string won't have a translation key associated and will just fall through to render itself, but on the off chance a user enters "sites:downloadButtonLabel" or whatever, this can save you. */
export interface Untranslatable {
  literal: string;
}

/** This can be: an object with key and options, just the key as a string, or at a pinch, just the untranslatable text as a string */
export type Translatable = string | FullTranslatable | Untranslatable;

const languageContext = createContext(DEFAULT_LANGUAGE);
/** Put this element somewhere near the root of your React app so that the hooks know what language to translate things into. */
export function LanguageProvider({ language, children }: PropsWithChildren<{ language: string }>) {
  return <languageContext.Provider value={language}>{children}</languageContext.Provider>;
}

/** Find out what the current language is */
export function useLanguage() {
  return useContext(languageContext);
}

/** If the given namespace isn't already loaded, forces a re-render when it loads. If no namespace is provided, does nothing. */
function useNamespaceListener(namespace?: string) {
  const rerender = useForceRerender();
  const language = useLanguage();
  if (namespace) listenForTranslations(namespace, language, rerender);
}

/** Returns a function that translates text — in the unlikely event that the translation system isn't initialised yet, it will update when it is. It won't track newly imported strings, though, so make sure you do that before rendering the page. */
function useTranslateFunction(namespace?: string) {
  useNamespaceListener(namespace);
  const lng = useLanguage();
  const isInitialised = useTranslationIsInitialised();
  return useCallback(
    (t: string, args?: TOptions): string => {
      if (!isInitialised) return t;
      if (namespace) t = `${namespace}:${t}`;
      return getI18next().t(t, { ...args, lng });
    },
    [isInitialised, lng, namespace],
  );
}

/** Normally, returns true. If translations haven't been initialised yet then returns false, then re-renders when they have. */
export function useTranslationIsInitialised() {
  const rerender = useForceRerender();
  const isInitialised = translationIsInitialised();
  if (!isInitialised) void getI18nextAsync().then(rerender);
  return isInitialised;
}

/** This function (and everything in this file) assumes that i18next has been initialised correctly with everything you're going to need. */
export function useTranslate(namespace?: string) {
  const doTranslate = useTranslateFunction(namespace);

  // We can't use useCallback here because there are too many signatures to define
  return useMemo(() => {
    /** Translates the string based on the key and the arguments */
    function translate(key: string, args: TOptions): string;
    /** Translates the string based on the key if it exists */
    function translate(keyOrUntranslatedText: Translatable): string;
    /** Translates the string based on the key if it exists, or returns null if the key was missing */
    function translate(key: Translatable | null | undefined): string | null;

    function translate(options?: Translatable | null | undefined, args: TOptions = {}) {
      if (options === null || options === undefined) return null;
      // TODO: When we start getting serious about localisation, this should console.warn when it's returning the original string.
      if (typeof options === "string") return doTranslate(options, args) ?? options;

      if (!("t" in options) && "literal" in options) return options.literal;

      const { t, ...opts } = options;
      return doTranslate(t, { ...opts, ...args });
    }
    return translate;
  }, [doTranslate]);
}

/**
 * <Translation t="someString" param="4" />
 * or if you've got a translatable in a variable then
 * <Translation props={translatable} />
 **/
export function Translation(props: FullTranslatable | { props: Translatable | null | undefined }) {
  const translate = useTranslate();
  return <>{translate("t" in props ? props : props.props)}</>;
}

/** Just a <Translation> wrapped in a <tag>, because it comes up quite a bit. Not you can't use <TextElement tag="p" t="someString" param="4" /> because TypeScript gets upset — use <TextElement tag="p" props={{ t: "someString", param: "4" }} /> instead. */
export function TextElement({
  className,
  style,
  tag: Tag,
  ...props
}: { className?: string; style?: CSSProperties; tag: "p" | "h2" | "h3" | "span" } & (
  | { t: string }
  | { props: Translatable | null | undefined }
)) {
  return (
    <Tag className={className} style={style}>
      <Translation {...props} />
    </Tag>
  );
}

/** If you have a React component whose children/contents can be either translatable strings or more React nodes, this will tell you which you've received */
export function isTranslatable(message: Translatable | ReactNode): message is Translatable {
  if (!message) return false;
  if (typeof message === "string") return true;
  // @ts-ignore - this is safe but TypeScript doesn't know that
  if ("t" in message || "literal" in message) return true;
  return false;
}
