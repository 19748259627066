import React from "react";

const BoxArchive = () => (
  <path
    d="M16.5 3C17.3125 3 18 3.6875 18 4.5V6.53125C18 7.0625 17.5312 7.5 17 7.5V15C17 16.125 16.0938 17 15 17H5C3.875 17 3 16.125 3 15L2.96875 7.5C2.4375 7.5 2 7.0625 2 6.5V4.5C2 3.6875 2.65625 3 3.5 3H16.5ZM15 15.5C15.25 15.5 15.5 15.2812 15.5 15V7.5H4.5V15C4.5 15.2812 4.71875 15.5 5 15.5H15ZM16.5 6V4.5H3.5V6H16.5ZM7.71875 10.5C7.3125 10.5 7 10.1875 7 9.75C7 9.34375 7.3125 9 7.71875 9H12.25C12.6562 9 13 9.34375 13 9.75C13 10.1875 12.6562 10.5 12.2188 10.5H7.71875Z"
    fill="#383351"
  />
);

export default BoxArchive;
