import React from "react";

const Navicon = () => (
  <path
    d="M3 4.75A.74.74 0 0 1 3.75 4h12.5a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75H3.75A.722.722 0 0 1 3 4.75Zm0 5A.74.74 0 0 1 3.75 9h12.5a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75H3.75A.722.722 0 0 1 3 9.75Zm13.25 5.75H3.75a.722.722 0 0 1-.75-.75.74.74 0 0 1 .75-.75h12.5a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75Z"
    fill="currentColor"
  />
);

export default Navicon;
