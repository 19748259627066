import React from "react";

const Ellipsis = () => (
  <path
    d="M13.6489 9.71448C13.6489 8.90198 14.3052 8.21448 15.1489 8.21448C15.9614 8.21448 16.6489 8.90198 16.6489 9.71448C16.6489 10.5582 15.9614 11.2145 15.1489 11.2145C14.3052 11.2145 13.6489 10.5582 13.6489 9.71448ZM8.64893 9.71448C8.64893 8.90198 9.30518 8.21448 10.1489 8.21448C10.9614 8.21448 11.6489 8.90198 11.6489 9.71448C11.6489 10.5582 10.9614 11.2145 10.1489 11.2145C9.30518 11.2145 8.64893 10.5582 8.64893 9.71448ZM6.64893 9.71448C6.64893 10.5582 5.96143 11.2145 5.14893 11.2145C4.30518 11.2145 3.64893 10.5582 3.64893 9.71448C3.64893 8.90198 4.30518 8.21448 5.14893 8.21448C5.96143 8.21448 6.64893 8.90198 6.64893 9.71448Z"
    fill="currentColor"
  />
);

export default Ellipsis;
