import { useCallback, useEffect, useRef, useState } from "react";

import useRefOf from "./useRefOf";

// There's no need to pass a useCallback'd function into this.
// The debounced function will always be the same and we'll only actually run the callback when that function is called.
// Be aware, though, that it's possible the values could change between the debounced function being called and the callback being invoked.
// In normal use that shouldn't be an issue but if it could be then use a ref to make sure you're not closuring old values.
export function useDebouncedFunction(callback: () => void | Promise<void>, delay: number) {
  const timeoutId = useRef<ReturnType<typeof setTimeout> | undefined>();
  const callbackRef = useRefOf(callback);
  const delayRef = useRefOf(delay);
  // All the dependencies of this callback are refs so it'll never update
  return useCallback(() => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(callbackRef.current, delayRef.current);
  }, [timeoutId, callbackRef, delayRef]);
}

export function useDebouncedEffect(callback: () => void | Promise<void>, deps: unknown[], delay: number) {
  // We can ignore the dependency check on useEffect because the ESLint config enforces one on useDebouncedEffect
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(useDebouncedFunction(callback, delay), deps);
}

export function useDebouncedValue<T>(value: T, delay: number, prebounceValue?: T) {
  const [debouncedValue, setDebouncedValue] = useState<T>(prebounceValue === undefined ? value : prebounceValue);
  useDebouncedEffect(() => setDebouncedValue(value), [value], delay);
  return debouncedValue;
}
