import { OtherType, QuestionType } from "../../__generated__/graphql";
import type Question from "../../db/models/Question";
import type { Option } from "../../db/models/Question";

// Defines aplace in the form where an input might exist
export interface Field {
  question: Question;
  option?: Option;
}

// Works out what type of question or other-box exists at a given field
export function validationType({ question, option }: Field) {
  if (!option) return question.questionType;
  return optionValidationType(option);
}
export function optionValidationType(option: Option) {
  if (option.otherType === OtherType.Phone) return QuestionType.Phone as const;
  if (option.otherType === OtherType.Email) return QuestionType.Email as const;
  return QuestionType.Text as const;
}
