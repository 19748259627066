import { importTranslations } from "../../translation/initialisation";
import welshChartResources from "./translations/cy-GB.json";
import englishChartResources from "./translations/en-GB.json";

let loadedTranslations = false;

/** Ensures that the translations are loaded before the chart is drawn */
export default function useTranslations() {
  if (loadedTranslations) return;
  importTranslations("chart", { "en-GB": englishChartResources, cy: welshChartResources });
  loadedTranslations = true;
}
