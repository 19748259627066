export const cookieAllowed = () => document.cookie.split(";").some((item) => item.includes("gaallowed=1"));
export const cookieBlocked = () => document.cookie.split(";").some((item) => item.includes("gaallowed=0"));
export const setCookie = (allowed: boolean) => {
  document.cookie = `gaallowed=${allowed ? 1 : 0}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
};

let gaLoaded = false;
export function loadGoogleAnalytics(universalAnalytics: boolean, googleTag: string | undefined) {
  if (gaLoaded) {
    return;
  }
  if (universalAnalytics) {
    const gaScript = document.createElement("script");
    gaScript.src = "https://www.google-analytics.com/analytics.js";
    document.body.appendChild(gaScript);
  }
  if (googleTag) {
    const gaScript = document.createElement("script");
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleTag}`;
    document.body.appendChild(gaScript);
  }
  gaLoaded = true;
}

export default class CookieControl {
  private readonly elem: HTMLInputElement;

  public constructor(elem: HTMLInputElement) {
    this.elem = elem;
    this.onChange = this.onChange.bind(this);
  }

  public init() {
    this.elem.checked = cookieAllowed();
    this.elem.addEventListener("change", this.onChange);
  }

  private onChange(ev: Event) {
    if (ev.currentTarget) {
      setCookie((ev.currentTarget as HTMLInputElement).checked);
    }
  }
}
