import { useTranslate } from "../translation";

const monthKeys = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"] as const;

// startMonth is a string from '01' (note the leading zero) to '12'
export function getMonthKey(startMonth: string, month: number) {
  const start = parseInt(startMonth, 10);
  return monthKeys[(start + month - 1) % 12];
}

export function useMonthShortNames(startMonth: string) {
  const translate = useTranslate();
  return (i: number) => translate(`chart:${getMonthKey(startMonth, i)}`);
}

// This is only used in the dashboard but it makes sense to put it here:
export function getEnglishMonthShortName(startMonth: string, month: number) {
  const name = getMonthKey(startMonth, month);
  return name[0].toUpperCase() + name.substring(1);
}

export function yearLabel(year: string, startMonth: string, duration: string | number) {
  if (parseInt(startMonth, 10) + Number(duration) <= 12) return year.toString();
  const yearAsInt = parseInt(year, 10);
  return `${year}/${(yearAsInt + 1).toString().substring(2)}`;
}
