import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useRef } from "react";

import { useAutoSize } from "../../../hooks/useAutoSize";
import useGeneratedId from "../../../hooks/useGeneratedId";
import InputLabel from "../InputLabel";
import "./styles.scss";

export interface TextAreaProps {
  value: string;
  label: string;
  visuallyHideLabel?: boolean;
  autoSize?: boolean;
  rows?: number;
  minHeight?: number;
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  customValidity?: string | false;
}

const TextArea: FunctionComponent<TextAreaProps> = ({
  visuallyHideLabel,
  className,
  label,
  value,
  autoSize = false,
  minHeight,
  rows,
  onChange,
  customValidity,
  ...textAreaProps
}) => {
  const id = useGeneratedId();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value), [onChange]);

  useAutoSize(autoSize, textareaRef, minHeight, value);

  useEffect(() => textareaRef.current?.setCustomValidity(customValidity || ""), [customValidity]);

  return (
    <div className={`c-textarea ${className ?? ""}`}>
      <InputLabel htmlFor={id} visuallyHidden={visuallyHideLabel}>
        {label}
      </InputLabel>
      <textarea
        ref={textareaRef}
        {...textAreaProps}
        className="c-textarea__input"
        id={id}
        rows={autoSize ? undefined : rows}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextArea;
