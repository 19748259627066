import React from "react";

const Lines = () => (
  <path
    d="M5.5331 7.12976C5.07727 7.12976 4.75 7.49291 4.75 7.91287C4.75 8.36192 5.08406 8.69597 5.5331 8.69597H14.4182C14.8381 8.69597 15.2013 8.3687 15.2013 7.91287C15.2013 7.48603 14.845 7.12976 14.4182 7.12976H5.5331ZM5.5331 13.25H14.4182C14.8381 13.25 15.2013 12.9227 15.2013 12.4669C15.2013 12.0401 14.845 11.6838 14.4182 11.6838H5.5331C5.07727 11.6838 4.75 12.0469 4.75 12.4669C4.75 12.9159 5.08406 13.25 5.5331 13.25Z"
    fill="currentColour"
  />
);

export default Lines;
