import React from "react";

const Organisations = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 2.25a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5h-9ZM0 17c0-2.188 1.781-4 4-4h2c2.188 0 4 1.813 4 4 0 .563-.469 1-1 1H1c-.563 0-1-.438-1-1Zm4-2.5c-1.219 0-2.219.875-2.469 2h6.907c-.25-1.125-1.25-2-2.438-2H4ZM8 9c0 1.688-1.344 3-3 3-1.688 0-3-1.344-3-3s1.313-3 3-3a3 3 0 0 1 3 3ZM6.5 9c0-.813-.688-1.5-1.5-1.5A1.5 1.5 0 0 0 3.5 9c0 .844.656 1.5 1.5 1.5A1.5 1.5 0 0 0 6.5 9Zm2.75-2.5a.75.75 0 0 1 .75-.75h9a.75.75 0 0 1 0 1.5h-9a.75.75 0 0 1-.75-.75ZM10 9.25a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5h-9Z"
    fill="currentColor"
  />
);

export default Organisations;
