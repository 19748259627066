import React, {
  PropsWithChildren,
  RefObject,
  SyntheticEvent,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { createPortal } from "react-dom";

import useGeneratedId from "../../../hooks/useGeneratedId";
import useRefOf from "../../../hooks/useRefOf";
import useOnParentClose from "../tabs/closable";
import { rootContext } from "./RootProvider";
import styles from "./styles.module.scss";

/*
This component assumes that modals will open and close, mount and unmount, but not move around. It also assumes that modals will be triggered by user interaction only, which means that if two modals are open simultaneously, one must be a child of the other in the React component tree. This means when a modal closes, we know its parent will get focus
*/

interface ModalRecord {
  id: string;
  position: number;
  ref: RefObject<HTMLDivElement>;
  parent: ModalRecord | null;
  descendents: Set<string>;
  /** If this is set then the modal is not permitted to have children */
  isLeaf: boolean;
}

const modalParentContext = createContext<ModalRecord | null>(null);

export interface ModalPropsBase {
  isOpen: boolean;
  /** Code that closes the modal and does nothing else. If you need a confirm box or whateverr, use onClickClose. */
  onClose?: () => void;
  /** Code to run when the close button is clicked, if different to onClose. This should call onClose (assuming the closure isn't cancelled for some reason) */
  onClickClose?: () => void;
  /** If set, the modal will treat clicking outside the modal the same as clicking the "close" button */
  closeOnClickOutside?: boolean;
  /** A flag that sets an Aria tag to say "this is an alert box rather than just a regular modal dialog" */
  alert?: boolean;
}

/** A wrapper that makes something modal. Doesn't render any actual content, just creates a context for things to live in. */
export default function Modal({
  children,
  isOpen,
  dimBackground = false,
  onClose,
  onClickClose,
  closeOnClickOutside,
  alert,
  className = "",
  labelledBy,
  labelRef,
  isLeaf,
}: PropsWithChildren<
  ModalPropsBase & {
    dimBackground?: boolean;
    closeOnClickOutside?: boolean;
    className?: string;
    labelledBy: string;
    labelRef: React.RefObject<HTMLElement>;
    /** If this is set then the modal is not permitted to have children */
    isLeaf: boolean;
  }
>) {
  const id = useGeneratedId();
  const ref = useRef<HTMLDivElement>(null);
  const { topmostModal, setTompostModal } = useContext(rootContext);
  const isTopmost = topmostModal === id;

  useOnParentClose(onClose);

  const parent = useContext(modalParentContext);

  if (parent?.isLeaf) {
    // If you are getting this error, it usually this means you are trying to put a dialog box inside some smaller modal container such as a pop-up menu, and the correct solution is to place the dialog outside the menu and have the menu activate it. This is because the system that activates and deactivates modals assumes that they follow the React tree — meaning you can't have an open modal inside a closed modal any more than you can have a visible div inside a hidden one.
    throw new Error("You cannot create a modal in this position");
  }

  const currentModal: ModalRecord = useMemo(
    () => ({
      id,
      ref,
      position: parent ? parent.position + 1 : 1,
      parent,
      descendents: new Set(),
      isLeaf,
    }),
    [id, isLeaf, parent],
  );

  // Each modal should know all its descendents. We know the parent modal, so we can use that to find all our ancestors, and register ourselves as a descendent.
  useEffect(() => {
    for (let ancestor = parent; ancestor !== null; ancestor = ancestor.parent) {
      ancestor.descendents.add(id);
    }
    return () => {
      for (let ancestor = parent; ancestor !== null; ancestor = ancestor.parent) {
        ancestor.descendents.delete(id);
      }
    };
  }, [id, parent]);

  useEffect(() => {
    if (!isOpen) return;
    // When a modal opens, add it to the stack, and focus its header.
    setTompostModal(id);
    const savedFocus = document.activeElement as HTMLElement | null;
    labelRef.current?.focus();
    return () => {
      // When it closes, remove it from the stack and restore focus to wherever it was before.
      setTompostModal(parent?.id ?? null);
      savedFocus?.focus();
    };
  }, [id, isOpen, labelRef, parent, setTompostModal]);

  // Prevent focus from leaving the active modal
  const openRef = useRefOf(isOpen);
  useEffect(() => {
    if (!isTopmost) return;

    function rootFocusHandler() {
      // When the user requests to close the modal, there's a moment before this handler is unregistered when we try to return focus to the parent modal. Checking openRef.current lets us prevent that.
      if (!openRef.current) return;
      if (document.activeElement === document.body) return;
      // Check if the focussed element is in this modal or one of its descendents...
      for (let el = document.activeElement; el; el = el.parentElement) {
        if (el.id === id || currentModal.descendents.has(el.id)) {
          return;
        }
      }
      // ...if not, drag focus back to this modal — the title element will do.
      labelRef.current?.focus();
    }

    document.addEventListener("focusin", rootFocusHandler);
    return () => document.removeEventListener("focusin", rootFocusHandler);
  }, [ref, labelRef, id, currentModal, isTopmost, openRef]);

  // Close the topmost modal only if the user presses 'escape'
  useEffect(() => {
    if (!isTopmost || !onClose) return;
    function keydownHandler(event: KeyboardEvent) {
      if (event.key === "Escape") (onClickClose ?? onClose)!();
    }
    window.addEventListener("keydown", keydownHandler);
    return () => window.removeEventListener("keydown", keydownHandler);
  }, [onClose, onClickClose, isTopmost]);

  return createPortal(
    <div
      id={id}
      className={`
        ${styles.container}
        ${isOpen ? "" : styles.closed}
      `}
      onSubmit={swallowEvent}
      onPaste={swallowEvent}
      style={{ zIndex: currentModal.position + 1000 }}
    >
      <div
        className={`
          ${styles.cover}
          ${dimBackground && isOpen ? styles.dimBackground : ""}
        `}
        onClick={closeOnClickOutside ? onClickClose ?? onClose : undefined}
      />
      <div
        role={isTopmost ? (alert ? "alertdialog" : "dialog") : undefined}
        aria-modal={isTopmost}
        aria-hidden={!isTopmost}
        aria-labelledby={labelledBy}
        className={className}
        ref={ref}
      >
        <modalParentContext.Provider value={currentModal}>{children}</modalParentContext.Provider>
      </div>
    </div>,
    (document.getElementById("modal-container") ??
      (process.env.NODE_ENV === "test" ? document.createElement("div") : null))!,
  );
}

// Prevent events from bubbling out of modals and triggering forms outside the modal
function swallowEvent(ev: SyntheticEvent) {
  ev.stopPropagation();
}
