import { useEffect } from "react";

export function useAutoSize(
  isEnabled: boolean,
  elementRef: React.RefObject<HTMLTextAreaElement>,
  minHeight: number | null | undefined,
  value: string,
) {
  useEffect(() => {
    if (!isEnabled || !elementRef.current) return;

    elementRef.current.style.height = "auto";
    const heightToSet = Math.max(elementRef.current.scrollHeight + 2, minHeight ?? 0);
    elementRef.current.style.height = `${heightToSet}px`;
  }, [minHeight, elementRef, value, isEnabled]);
}
