import React from "react";

const Minus = () => (
  <path
    d="m16.5 10c0 0.4375-0.3438 0.75-0.75 0.75h-11.5c-0.4375 0-0.75-0.3125-0.75-0.7188 0-0.43745 0.3125-0.7812 0.75-0.7812h11.5c0.4062 0 0.75 0.34375 0.75 0.75z"
    fill="currentColor"
  />
);

export default Minus;
