import React from "react";

const UploadArrow = () => (
  <path
    d="M14.3 15.6H1.70005C1.17505 15.6 0.800049 15.975 0.800049 16.5C0.800049 17.025 1.17505 17.4 1.70005 17.4H14.3C14.7875 17.4 15.2 17.025 15.2 16.5C15.2 16.0125 14.7875 15.6 14.3 15.6ZM12.125 6.93748L8.90005 3.48748V12.6C8.90005 13.125 8.48755 13.5375 8.00005 13.5375C7.47505 13.5375 7.10005 13.125 7.10005 12.6V3.48748L3.83755 6.93748C3.65005 7.12498 3.42505 7.19998 3.20005 7.19998C2.97505 7.19998 2.75005 7.12498 2.56255 6.97498C2.18755 6.63748 2.18755 6.07498 2.52505 5.69998L7.32505 0.599976C7.66255 0.224976 8.30005 0.224976 8.63755 0.599976L13.4375 5.69998C13.775 6.07498 13.775 6.63748 13.4 6.97498C13.025 7.31248 12.4625 7.31248 12.125 6.93748Z"
    fill="#383351"
  />
);

export default UploadArrow;
