import React from "react";

const Columns3 = () => (
  <path
    d="M2 5c0-1.094.875-2 2-2h12c1.094 0 2 .906 2 2v10c0 1.125-.906 2-2 2H4c-1.125 0-2-.875-2-2V5Zm11.25 10.5H16c.25 0 .5-.219.5-.5V5c0-.25-.25-.5-.5-.5h-2.75v11Zm-1.5-11h-3.5v11h3.5v-11Zm-5 11v-11H4c-.281 0-.5.25-.5.5v10c0 .281.219.5.5.5h2.75Z"
    fill="currentColor"
  />
);

export default Columns3;
