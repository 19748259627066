import React from "react";

const Bold = () => (
  <path
    d="M13.969 9.563c.062-.063.156-.094.219-.157.687-.719 1.093-1.656 1.062-2.687C15.25 4.687 13.562 3 11.5 3H5.25a.74.74 0 0 0-.75.75c0 .438.313.75.75.75H6v11h-.75a.74.74 0 0 0-.75.75c0 .438.313.75.75.75H12c2.188 0 4-1.781 4-4 0-1.469-.844-2.75-2.031-3.438ZM11.5 4.5c1.25 0 2.25 1 2.25 2.25.031.594-.219 1.156-.625 1.594A2.24 2.24 0 0 1 11.5 9h-4V4.5h4Zm.5 11H7.5v-5H12c1.375 0 2.5 1.125 2.5 2.531 0 1.375-1.125 2.469-2.5 2.469Z"
    fill="currentColor"
  />
);

export default Bold;
