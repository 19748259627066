// This looks for links from one form to another, intercepts the click event, and wraps it in a form submission so that the user's answers are passed to the new form.

function findParentWhere(start: HTMLElement, condition: (el: HTMLElement) => boolean) {
  for (let el: HTMLElement | null = start; el; el = el.parentElement) {
    if (condition(el)) {
      return el;
    }
  }
  return null;
}

function addInput(form: HTMLFormElement, name: string, value: string) {
  const input = document.createElement("input");
  input.setAttribute("type", "hidden");
  input.setAttribute("name", name);
  input.setAttribute("value", value);
  form.appendChild(input);
}

export default function wireUpFormLinks() {
  // Attach listener to the root so that we include clicks on links React hasn't rendered yet as they bubble up through the Dom.
  document.body.addEventListener("click", (ev: MouseEvent) => {
    // Make sure the user clicked on a link to another report (or a child of such a link).
    const link = findParentWhere(ev.target as HTMLElement, (el) => el.tagName === "A");
    if (!link || !/^\/report\//.test(link.getAttribute("href")!)) {
      return;
    }

    // Make sure the link is one that we should be wiring up.
    if (!findParentWhere(link, (el) => el.classList.contains("allow-cross-form-links"))) {
      return;
    }

    // Find the container with answer and CSRF data
    const data = document.querySelector<HTMLElement>("[data-answers][data-csrf-token]")?.dataset;
    if (!data) {
      // This should never happen but this callback should generally fail silently rather than crash.
      return;
    }

    // Get the answers — from the live inputs if possible, but from the data attributes if they're not available. (Both are needed because the data attributes don't update when the form is filled in client-side, but the inputs aren't rendered on the "thank you" screen.)
    const input = document.querySelector<HTMLInputElement>(".hidden-answer-field");
    const answerJson = input?.value ?? data.answers!;

    // Build a form and submit it
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("enctype", "multipart/form-data");
    form.setAttribute("action", link.getAttribute("href")!);
    addInput(form, "previous-answers", answerJson);
    addInput(form, "_csrf", data.csrfToken!);
    document.body.appendChild(form);
    form.submit();

    // If that all worked, prevent the normal link navigation from firing.
    ev.preventDefault();
  });
}
