export const TOTAL_COLOUR = "#B1ACE2";

export const OUTER_BAR_COLOUR = "#2020400b";

export const PALETTE = [
  "#4F4A7C",
  "#A091F8",
  "#E95540",
  "#18C48A",
  "#E7A108",
  "#4FBDC9",
  "#FC944A",
  "#005E3F",
  "#BE9041",
  "#CA80DC",
  "#1192E8",
  "#822316",
  "#8791A0",
  "#F5639B",
];
