import React from "react";

const ChevronUp = () => (
  <path
    d="m3.238 11.969 5.97-5.719c.187-.156.374-.25.562-.25.187 0 .344.063.5.188l5.968 5.718c.313.313.313.781.032 1.063-.281.312-.75.312-1.063.031L9.77 7.812l-5.5 5.25a.746.746 0 0 1-1.063-.03c-.281-.313-.281-.782.031-1.063Z"
    fill="currentColor"
  />
);

export default ChevronUp;
