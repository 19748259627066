import React, { FunctionComponent } from "react";

const AlertDangerIcon: FunctionComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <circle cx="12" cy="12" r="12" fill="#E95540" />
    <rect width="2" height="8" fill="#fff" rx="1" transform="translate(11 6)" />
    <rect width="2" height="3" fill="#fff" rx="1" transform="translate(11 15)" />
  </svg>
);

export default AlertDangerIcon;
