import React, { useMemo } from "react";

import TextInput, { TextInputProps, TextInputType } from "./base";

const string: TextInputType<string> = {
  inputType: "text",
  toString(value) {
    return value;
  },
  parse(value) {
    return { invalid: false, value };
  },
};

/**
 * Allows users to input a string value
 */
export default function StringInput({
  validate,
  password,
  ...props
}: TextInputProps<string> & { password?: boolean; validate?: (value: string) => false | string }) {
  const type = useMemo(
    () => ({
      ...string,
      inputType: password ? "password" : "text",
      parse(value: string) {
        // Don't validate empty input — this helps make the control feel more natural. If you need to forbid empty input, use the 'required' flag.
        if (!value) return { invalid: false as const, value };
        if (!validate) return { invalid: false as const, value };
        const validationResult = validate(value);
        if (validationResult === false) return { invalid: false as const, value };
        return { invalid: validationResult, value: null };
      },
    }),
    [validate, password],
  );
  return <TextInput type={type} {...props} />;
}

/**
 * Allows users to read a string value
 */
export function ReadOnlyStringInput(
  props: Omit<TextInputProps<string>, "onChange" | "disabled" | "required"> & { visuallyEnabled?: boolean },
) {
  return (
    <TextInput
      type={string}
      {...props}
      disabled
      onChange={noOp}
      className={`
        ${props.visuallyEnabled ? "c-text-input--visually-enabled" : ""}
        ${props.className ?? ""}
      `}
    />
  );
}

function noOp() {
  // Do nothing
}
