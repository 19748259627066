import type { FormatFunction, InterpolationOptions } from "i18next";

/** This string is designed to render acceptably (in English anyway), but it should never appear as it should be filtered out by something like the optional formatting postprocessor. To that end it contains a zero-width non-breaking space so that we can identify it easily and uniquely. */
export const NONE_PLACEHOLDER = "(none)";

/** This function formats values such as dates and numbers when they appear in translatable strings. See https://www.i18next.com/translation-function/formatting for more */
export const format: FormatFunction = (
  /** The value to be formatted. Eg, if you call i18next.t(key, { count: 5 }) and the unformatted translated value reads "you have {{count}} items", then this will be 5. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  /** The string passed in with it, if any. Eg, if the translated value reads "you have {{count, text}} items" then `format` will be set to "text" */
  format?: string,
  /** The language we are translating into */
  lng?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: InterpolationOptions & { [key: string]: any },
) => {
  // Format numbers according to the appropriate in-built locale rules — using digit separators, etc.
  if (typeof value === "number") {
    try {
      return value.toLocaleString(lng);
    } catch (e) {
      // handle case when locale is unsupported by this browser, default to user's locale
      if (e instanceof Error && e.name === "RangeError") {
        return value.toLocaleString();
      }
      throw e;
    }
  }

  // Slight hack here: the optional postprocessor uses | as a special character so let's quietly strip those out if we find them, to avoid a Little Bobby Tables scenario.
  if (typeof value === "string") return value.replace(/\|/g, "");

  // Fallback
  return value?.toString() ?? NONE_PLACEHOLDER;
};
