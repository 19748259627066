import React from "react";

const Undo = () => (
  <path
    d="M18.2 3.25v5a.74.74 0 0 1-.75.75h-5a.722.722 0 0 1-.75-.75.74.74 0 0 1 .75-.75h3.125c-.938-1.781-2.813-3-4.875-3A5.488 5.488 0 0 0 5.2 10c0 3.063 2.437 5.5 5.5 5.5a5.454 5.454 0 0 0 3.5-1.25c.313-.281.781-.219 1.063.094a.779.779 0 0 1-.094 1.062 7.066 7.066 0 0 1-4.5 1.594c-3.844 0-6.97-3.125-6.97-7a6.975 6.975 0 0 1 6.97-6.969c2.5 0 4.781 1.344 6.031 3.406V3.25a.74.74 0 0 1 .75-.75.76.76 0 0 1 .75.75Z"
    fill="currentColor"
  />
);

export default Undo;
