import React from "react";

const Gear = () => (
  <path
    d="M7 10c0-1.656 1.312-3 3-3a3 3 0 0 1 0 6 2.98 2.98 0 0 1-3-3Zm3-1.5A1.5 1.5 0 0 0 8.5 10c0 .844.656 1.5 1.5 1.5a1.5 1.5 0 0 0 1.5-1.5c0-.813-.688-1.5-1.5-1.5ZM11.156 2c.688 0 1.281.5 1.438 1.156l.25 1.031a4.3 4.3 0 0 1 .75.438l1.031-.313A1.515 1.515 0 0 1 16.344 5L17.5 7.031a1.475 1.475 0 0 1-.281 1.813l-.75.719c0 .156.031.312.031.437 0 .156-.031.313-.031.438l.75.75c.5.468.625 1.218.281 1.812l-1.156 2.031a1.515 1.515 0 0 1-1.719.688l-1.031-.313a4.297 4.297 0 0 1-.75.438l-.25 1.031A1.474 1.474 0 0 1 11.156 18H8.812a1.474 1.474 0 0 1-1.437-1.125l-.25-1.031a4.3 4.3 0 0 1-.75-.438l-1.031.313a1.515 1.515 0 0 1-1.719-.688L2.469 13a1.475 1.475 0 0 1 .281-1.813l.75-.75v-.875l-.75-.718a1.475 1.475 0 0 1-.281-1.813L3.625 5a1.515 1.515 0 0 1 1.719-.688l1.031.313c.219-.156.469-.313.75-.438l.25-1.03C7.531 2.5 8.125 2 8.812 2h2.344Zm-2.75 3.25-.344.156a4.158 4.158 0 0 0-1.093.625l-.313.25-1.719-.531-1.156 2.031L5.062 9l-.03.375C5 9.594 5 9.813 5 10c0 .219 0 .438.031.656l.031.375-1.28 1.219 1.155 2.031 1.72-.531.312.25c.312.25.687.469 1.093.625l.344.156.406 1.719h2.344l.406-1.719.344-.156A4.156 4.156 0 0 0 13 14l.313-.25 1.718.531 1.156-2.031-1.28-1.219.03-.375A4.55 4.55 0 0 0 15 10c0-.188-.031-.406-.063-.625L14.908 9l1.28-1.219-1.156-2.031-1.719.531-.312-.25a4.157 4.157 0 0 0-1.094-.625l-.344-.156-.406-1.75H8.812l-.406 1.75Z"
    fill="currentColor"
  />
);

export default Gear;
