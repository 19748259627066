import React, { FunctionComponent } from "react";

import { Field } from "../../shared/questions/validation";
import { useTranslate } from "../translation/frontend";
import { useInputValidity, warningId } from "./useInvalidQuestions";

const ErrorMessage: FunctionComponent<{ field: Field }> = ({ field }) => {
  const translate = useTranslate();
  const { invalid } = useInputValidity(field);

  if (!invalid) return null;

  return (
    <span id={`${warningId(field)}`} className="error-message">
      <svg
        className="error-message__icon"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g transform="translate(-147 -271)">
          <circle cx="12" cy="12" r="12" transform="translate(147 271)" fill="#a53c2d" />
          <rect width="2" height="8" rx="1" transform="translate(158 277)" fill="#fff" />
          <rect width="2" height="3" rx="1" transform="translate(158 286)" fill="#fff" />
        </g>
      </svg>
      <span className="is-sr-only">{translate("errorLabelTitle")}</span> {invalid}
    </span>
  );
};

export default ErrorMessage;
