import React from "react";

const SortAscendingAlphabetical = () => (
  <path
    d="M15.7188 15.5H13.7813L16.3125 12.2188C16.5 12 16.5313 11.6875 16.4063 11.4375C16.2813 11.1875 16.0313 11 15.7188 11H12.25C11.8125 11 11.5 11.3438 11.5 11.75C11.5 12.1875 11.8438 12.5 12.25 12.5H14.1875L11.6563 15.8125C11.4688 16.0312 11.4375 16.3438 11.5625 16.5938C11.6875 16.8438 11.9375 17 12.2188 17H15.6875C16.0938 17 16.4063 16.6875 16.4063 16.25C16.4063 15.8438 16.1563 15.5 15.7188 15.5ZM16.9063 7.9375L14.6875 3.4375C14.4375 2.90625 13.5938 2.90625 13.3438 3.4375L11.125 7.9375C10.9375 8.3125 11.0938 8.75 11.4688 8.9375C11.8438 9.125 12.2813 8.96875 12.4688 8.59375L12.5625 8.25H15.4063L15.5625 8.59375C15.6875 8.875 15.9688 9 16.25 9C16.3438 9 16.4688 9 16.5625 8.9375C16.9375 8.75 17.0938 8.3125 16.9063 7.9375ZM13.3125 6.75L13.9688 5.4375L14.6563 6.75H13.3125ZM8.4375 12.5L6.75 14.3438V3.78125C6.75 3.34375 6.40625 3 6 3C5.5625 3 5.25 3.34375 5.25 3.78125V14.3438L3.53125 12.5C3.375 12.3438 3.1875 12.2812 3 12.2812C2.8125 12.2812 2.625 12.3438 2.46875 12.4688C2.15625 12.75 2.15625 13.2188 2.4375 13.5312L5.40625 16.7812C5.6875 17.0938 6.21875 17.0938 6.5 16.7812L9.46875 13.5312C9.75 13.2188 9.75 12.75 9.4375 12.4688C9.1875 12.1875 8.71875 12.2188 8.4375 12.5Z"
    fill="currentColor"
  />
);

export default SortAscendingAlphabetical;
