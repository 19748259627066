import { loadGoogleAnalytics, setCookie } from "./cookieControl";

export default class CookieBanner {
  private readonly elem: HTMLDivElement;

  public constructor(elem: HTMLDivElement) {
    this.elem = elem;
    this.acceptCookie = this.acceptCookie.bind(this);
    this.declineCookie = this.declineCookie.bind(this);
  }

  public init() {
    this.elem.classList.add("is-visible");
    this.elem.querySelector(".btn--accept")!.addEventListener("click", this.acceptCookie);
    this.elem.querySelector(".btn--decline")!.addEventListener("click", this.declineCookie);
  }

  private acceptCookie() {
    this.elem.classList.remove("is-visible");
    setCookie(true);
    const universalAnalyticsBlock = document.getElementById("universal-analytics-block");
    const googleTagBlock = document.getElementById("google-tag-block");
    loadGoogleAnalytics(!!universalAnalyticsBlock, googleTagBlock?.dataset.googleTagId);
  }

  private declineCookie() {
    this.elem.classList.remove("is-visible");
    setCookie(false);
  }
}
