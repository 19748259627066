import React from "react";

const Info = () => (
  <path
    d="M12.25 15.5h-1.5V7.25A.76.76 0 0 0 10 6.5H8.75a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h.5v7.5h-1.5a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h4.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75ZM10 5a1.5 1.5 0 0 0 1.5-1.5c0-.813-.688-1.469-1.5-1.469-.844 0-1.5.656-1.5 1.5C8.5 4.344 9.156 5 10 5Z"
    fill="currentColor"
  />
);

export default Info;
