import React from "react";

import { MONTH_INDICES } from "../OverTimeBarChart";
import { useMonthShortNames, yearLabel } from "../months";
import { OverTimeData } from "../types";
import useTranslations from "../useTranslations";

export default function OverTimeTable({
  data,
  month,
  year,
  comparisonYear,
  className,
}: {
  data: OverTimeData;
  month: string;
  year: string;
  comparisonYear?: string | null;
  className?: string;
}) {
  useTranslations();

  const monthName = useMonthShortNames(month);
  const monthCount = data.years[0]?.months.length ?? 0;

  return (
    <div className="tableContainer">
      <table className={className} tabIndex={0}>
        {comparisonYear ? (
          <tr>
            <th tabIndex={0}></th>
            <th tabIndex={0}>{yearLabel(year, month, monthCount)}</th>
            <th tabIndex={0}>{yearLabel(comparisonYear, month, monthCount)}</th>
          </tr>
        ) : null}
        {MONTH_INDICES.slice(0, monthCount).map((i) => (
          <tr key={i}>
            <th className={comparisonYear ? "columnHeader" : ""} tabIndex={0}>
              {monthName(i)}
            </th>
            <td tabIndex={0}>{data.years[0].months[i].count}</td>
            {comparisonYear ? <td>{data.years[1].months[i].count}</td> : null}
          </tr>
        ))}
      </table>
    </div>
  );
}
