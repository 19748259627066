import React from "react";

const Plus = () => (
  <path
    d="m16.5 10c0 0.4375-0.3438 0.75-0.75 0.75h-5v5c0 0.4375-0.3438 0.7812-0.75 0.7812-0.4375 0-0.75-0.3437-0.75-0.7812v-5h-5c-0.4375 0-0.75-0.3125-0.75-0.7188 0-0.43745 0.3125-0.7812 0.75-0.7812h5v-5c0-0.40625 0.3125-0.71875 0.75-0.71875 0.4062 0 0.75 0.3125 0.75 0.71875v5h5c0.4062 0 0.75 0.34375 0.75 0.75z"
    fill="currentColor"
  />
);

export default Plus;
