import React, { FunctionComponent } from "react";

const AlertSuccessIcon: FunctionComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <circle cx="12" cy="12" r="12" fill="#18C48A" />
    <path
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17.005 8.5l-7.149 8-2.851-3.559"
    />
  </svg>
);

export default AlertSuccessIcon;
