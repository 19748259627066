import React from "react";

const User = () => (
  <path
    d="M11.5 11.5h-3A5.488 5.488 0 0 0 3 17c0 .563.438 1 1 1h12c.531 0 1-.438 1-1 0-3.031-2.469-5.5-5.5-5.5Zm-7 5a4.021 4.021 0 0 1 4-3.5h3a4.014 4.014 0 0 1 3.969 3.5H4.5ZM10 10c2.188 0 4-1.781 4-4 0-2.188-1.813-4-4-4-2.219 0-4 1.813-4 4 0 2.219 1.781 4 4 4Zm0-6.5c1.375 0 2.5 1.125 2.5 2.5 0 1.406-1.125 2.5-2.5 2.5A2.468 2.468 0 0 1 7.5 6c0-1.375 1.094-2.5 2.5-2.5Z"
    fill="currentColor"
  />
);

export default User;
