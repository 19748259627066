import React from "react";

const SmallChevronDown = () => (
  <path
    d="M15.5176 8.80094L11.0499 13.0187C10.8624 13.1749 10.6749 13.2374 10.5187 13.2374C10.3312 13.2374 10.1437 13.1749 9.98741 13.0499L5.48847 8.80097C5.17597 8.51972 5.17597 8.01972 5.45722 7.73847C5.73847 7.42597 6.23847 7.42597 6.51972 7.70722L10.5187 11.4562L14.4864 7.70719C14.7676 7.42594 15.2676 7.42594 15.5489 7.73844C15.8301 8.01969 15.8301 8.51969 15.5176 8.80094Z"
    fill="#145B63"
  />
);

export default SmallChevronDown;
