import React from "react";

const ArrowUp = () => (
  <path
    d="M6.61961 10.6853L6.61964 10.6853L6.62204 10.6828L9.12497 8.03604V15.3157C9.12497 15.8154 9.4868 16.2219 9.99997 16.2219C10.4786 16.2219 10.875 15.8189 10.875 15.3157V8.03869L13.3454 10.6815C13.6779 11.0499 14.2258 11.0431 14.5836 10.7211C14.953 10.3887 14.9464 9.84014 14.6241 9.48205L14.6242 9.48203L14.6222 9.48L10.6232 5.23098C10.2923 4.86474 9.67635 4.86474 9.34553 5.23098L5.34645 9.48L5.34642 9.47998L5.34456 9.48205C5.02228 9.84014 5.01574 10.3887 5.3851 10.7211L5.38496 10.7212L5.39063 10.7258C5.56941 10.8688 5.78455 10.9407 5.99997 10.9407C6.21698 10.9407 6.43746 10.8675 6.61961 10.6853Z"
    fill="currentColor"
  />
);

export default ArrowUp;
