import React from "react";

const MinusSmall = () => (
  <path
    d="M15 10C15 10.4375 14.6562 10.75 14.25 10.75H5.75C5.3125 10.75 5 10.4375 5 10.0312C5 9.59375 5.3125 9.25 5.75 9.25H14.25C14.6562 9.25 15 9.59375 15 10Z"
    fill="currentColor"
  />
);

export default MinusSmall;
