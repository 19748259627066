import React from "react";

const CircleExclamation = () => (
  <>
    <path
      d="M10 2c-4.438 0-8 3.594-8 8 0 4.438 3.563 8 8 8 4.406 0 8-3.563 8-8 0-4.406-3.594-8-8-8Z"
      fill="currentColor"
    />
    <path
      d="M9.25 10.25v-3.5A.74.74 0 0 1 10 6a.76.76 0 0 1 .75.75v3.5A.74.74 0 0 1 10 11a.722.722 0 0 1-.75-.75ZM9 13c0-.531.438-1 1-1 .531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1Z"
      fill="#fff"
    />
  </>
);

export default CircleExclamation;
