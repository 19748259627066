export default function initTitleObscurer() {
  const originalTitle = document.title;
  const obscuredTitle = document.body.dataset.obscuredTitle!;

  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      document.title = obscuredTitle;
    } else {
      document.title = originalTitle;
    }
  });
}
