import React from "react";

const Table = () => (
  <path
    d="M16 3C17.0938 3 18 3.90625 18 5V15C18 16.125 17.0938 17 16 17H4C2.875 17 2 16.125 2 15V5C2 3.90625 2.875 3 4 3H16ZM16 4.5H4C3.71875 4.5 3.5 4.75 3.5 5V7H16.5V5C16.5 4.75 16.25 4.5 16 4.5ZM4 15.5H6.5V8.5H3.5V15C3.5 15.2812 3.71875 15.5 4 15.5ZM8 15.5H16C16.25 15.5 16.5 15.2812 16.5 15V8.5H8V15.5Z"
    fill="currentColor"
  />
);

export default Table;
