import React from "react";

const TableCellsLarge = () => (
  <path
    d="M2 5c0-1.094.875-2 2-2h12c1.094 0 2 .906 2 2v10c0 1.125-.906 2-2 2H4c-1.125 0-2-.875-2-2V5Zm8.75 4.25h5.75V5c0-.25-.25-.5-.5-.5h-5.25v4.75ZM9.25 4.5H4c-.281 0-.5.25-.5.5v4.25h5.75V4.5ZM3.5 10.75V15c0 .281.219.5.5.5h5.25v-4.75H3.5Zm7.25 4.75H16c.25 0 .5-.219.5-.5v-4.25h-5.75v4.75Z"
    fill="currentColor"
  />
);

export default TableCellsLarge;
