import React from "react";

const FlipHorizontal = () => (
  <>
    <path
      d="M17 16c0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1 .531 0 1 .469 1 1Zm0-3c0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1 .531 0 1 .469 1 1Zm-1-7c.531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1Zm0 3c.531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1Zm0-6c.531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1Zm-2 1c0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1 .531 0 1 .469 1 1Zm-1 11c.531 0 1 .469 1 1 0 .563-.469 1-1 1-.563 0-1-.438-1-1 0-.531.438-1 1-1ZM9.25 2.75A.74.74 0 0 1 10 2a.76.76 0 0 1 .75.75v14.5A.74.74 0 0 1 10 18a.722.722 0 0 1-.75-.75V2.75Z"
      fill="currentColor"
    />
    <path
      d="M9.25 16.75v-1.5H4.5V4.75h4.75v-1.5h-5.5A.75.75 0 0 0 3 4v12c0 .414.336.75.75.75h5.5Z"
      fill="currentColor"
    />
  </>
);

export default FlipHorizontal;
