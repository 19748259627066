import React from "react";

const SquareCheck = () => (
  <>
    <path
      d="M15 3c1.094 0 2 .906 2 2v10c0 1.125-.906 2-2 2H5c-1.125 0-2-.875-2-2V5c0-1.094.875-2 2-2h10Z"
      fill="currentColor"
    />
    <path
      d="M13.594 7.406a.85.85 0 0 1 0 1.219l-4 4a.849.849 0 0 1-1.219 0l-1-1-1-1a.85.85 0 0 1 0-1.219.85.85 0 0 1 1.219 0L9 10.781l3.375-3.375a.85.85 0 0 1 1.219 0Z"
      fill="#fff"
    />
  </>
);

export default SquareCheck;
