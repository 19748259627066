import React from "react";

const File = () => (
  <path
    d="M4 4c0-1.094.875-2 2-2h5.156c.531 0 1.031.219 1.406.594l2.844 2.844c.375.375.594.875.594 1.406V16c0 1.125-.906 2-2 2H6c-1.125 0-2-.875-2-2V4Zm10.5 12V7H12c-.563 0-1-.438-1-1V3.5H6c-.281 0-.5.25-.5.5v12c0 .281.219.5.5.5h8c.25 0 .5-.219.5-.5Z"
    fill="currentColor"
  />
);

export default File;
