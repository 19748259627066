import React from "react";

const Xmark = () => (
  <path
    d="M14.75 13.719c.281.312.281.781 0 1.062-.313.313-.781.313-1.063 0L10 11.062l-3.719 3.72c-.312.312-.781.312-1.062 0-.313-.282-.313-.75 0-1.063L8.937 10 5.22 6.281c-.313-.312-.313-.781 0-1.062a.684.684 0 0 1 1.031 0L10 8.969l3.719-3.719a.684.684 0 0 1 1.031 0c.313.281.313.75 0 1.063L11.031 10l3.719 3.719Z"
    fill="currentColor"
  />
);

export default Xmark;
