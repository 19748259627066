import { useEffect, useState } from "react";

// Keep the loading spinner around for a while after loading completes so buttons feel like they're doing something

export default function useLoadingDelay({
  disable,
  loading,
}: {
  // These can be undefined for convenience, but must be supplied:
  disable: boolean | undefined;
  loading: boolean | undefined;
}) {
  // delayedLoading holds a (truthy) timer ID for 800ms after the "loading" flag is set to true:
  const [delayedLoading, setDelayedLoading] = useState<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (loading) {
      setDelayedLoading((currentTimer) => {
        if (currentTimer) clearTimeout(currentTimer);
        return setTimeout(() => setDelayedLoading(null), 800);
      });
    }
  }, [loading]);

  return !!(disable ? loading : loading || delayedLoading);
}
