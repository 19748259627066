import React from "react";

const ArrowToBottom = () => (
  <path
    d="M15.25 15.5H4.75a.722.722 0 0 0-.75.75c0 .438.313.75.75.75h10.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75Zm-1.813-7-2.687 2.875V3.781c0-.437-.344-.781-.75-.781-.438 0-.75.344-.75.781v7.594L6.531 8.5A.732.732 0 0 0 6 8.281a.849.849 0 0 0-.531.188c-.313.281-.313.75-.032 1.062l4 4.25a.755.755 0 0 0 1.094 0l4-4.25c.281-.312.281-.781-.031-1.062-.313-.281-.781-.281-1.063.031Z"
    fill="currentColor"
  />
);

export default ArrowToBottom;
