import React from "react";

import { FormInterstitial } from "../../../db/models/Form";
import LoginInterstitial from "./LoginInterstitial";
import RegularInterstitial from "./RegularInterstitial";
import ReviewInterstitial from "./ReviewInterstitial";

// TODO Load this lazily so that partners who don't have this feature enabled don't have to wait for the entire AWS SDK to download
// const LoginInterstitial = lazy(() => import("./LoginInterstitial"));

export default function Interstitial({ page }: { page: FormInterstitial }) {
  if (page.hasLoginGate) {
    // This should always be page 1
    return <LoginInterstitial page={page} />;
  }

  if (page.showReview) {
    // This should always be the last page
    return <ReviewInterstitial page={page} />;
  }

  // This includes captchas and "skip to end" buttons
  return <RegularInterstitial page={page} />;
}
