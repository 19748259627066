import React, { FunctionComponent, PropsWithChildren, useContext, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";

import Toaster from ".";
import { ToastContent, ToastOptions } from "./types";

const TOAST_REMOVAL_DELAY = 350;
const SUCCESS_TOAST_TIMEOUT = 8000;

export enum RemoveReason {
  MANUAL,
  OVERFLOW,
  TIMEOUT,
}

export interface ToastData {
  closed?: boolean;
  id: string;
  content: ToastContent;
  options: ToastOptions;
}

type VariantOptions = Omit<ToastOptions, "variant">;

export interface ToastMethods {
  show(content: ToastContent, options?: ToastOptions): string;
  remove(id: string): void;
  success(content: ToastContent, options?: VariantOptions): string;
  danger(content: ToastContent, options?: VariantOptions): string;
  info(content: ToastContent, options?: VariantOptions): string;
  warning(content: ToastContent, options?: VariantOptions): string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(error: any, options?: VariantOptions): string;
  showExtra<T>(extra: FunctionComponent<T>, props: T): string;
  removeExtra(extra: string): void;
}

export interface ToastExtra<T extends object = object> {
  Component: FunctionComponent<T>;
  props: T;
  id: string;
}

const context = React.createContext<ToastMethods | null>(null);

export function useToast(): ToastMethods {
  const payload = useContext(context)!;
  if (payload) return payload;

  if (process.env.NODE_ENV === "test") {
    let id = 0;
    return {
      show: () => (id++).toString(),
      remove: () => null,
      success: () => (id++).toString(),
      danger: () => (id++).toString(),
      info: () => (id++).toString(),
      warning: () => (id++).toString(),
      error: () => (id++).toString(),
      showExtra: () => "",
      removeExtra: () => null,
    };
  }

  throw new Error("Do not call useToast outside the ToastProvider");
}

export function ToastProvider({
  children,
  onDismiss,
}: PropsWithChildren<{ onDismiss?: (toast: ToastData, reason: RemoveReason) => void }>) {
  const [toasts, setToasts] = useState<ToastData[]>([]);
  const [extras, setExtras] = useState<ToastExtra[]>([]);

  const methods = useMemo<ToastMethods>(() => {
    function hardRemove(id: string) {
      setToasts((toasts) => {
        if (!toasts.some((toast) => toast.id === id)) return toasts;
        return toasts.filter((toast) => toast.id !== id);
      });
    }

    function remove(id: string, reason: RemoveReason) {
      setToasts((toasts) => {
        const toast = toasts.find((toast) => toast.id === id);
        if (!toast || toast.closed) return toasts;
        onDismiss?.(toast, reason);
        setTimeout(() => hardRemove(id), TOAST_REMOVAL_DELAY);
        return toasts.map((toast) => (toast.id === id ? { closed: true, ...toast } : toast));
      });
    }

    function show(content: ToastContent, options: ToastOptions = {}) {
      const id = uuid();
      setToasts((toasts) => {
        if (options.variant === "success" && !options.persistent) {
          setTimeout(() => remove(id, RemoveReason.TIMEOUT), SUCCESS_TOAST_TIMEOUT);
        }
        for (let i = 3; i < toasts.length; ++i) {
          const {
            id,
            options: { persistent },
          } = toasts[i];
          if (!persistent) {
            setImmediate(() => remove(id, RemoveReason.OVERFLOW));
          }
        }
        return [{ id, content, options }, ...toasts];
      });
      return id;
    }

    return {
      show,
      remove: (id: string) => remove(id, RemoveReason.MANUAL),
      success(content, options) {
        return show(content, { ...options, variant: "success" });
      },
      danger(content, options) {
        return show(content, { ...options, variant: "danger" });
      },
      info(content, options) {
        return show(content, { ...options, variant: "info" });
      },
      warning(content, options) {
        return show(content, { ...options, variant: "warning" });
      },
      // Default helper for toasting thrown errors,
      // because try/catch makes your error object an unknown
      // and handling that here is better than handling it in every try/catch block.
      error(error, options) {
        return show(error?.message ?? error?.toString() ?? "An unknown error occurred.", {
          ...options,
          variant: "danger",
        });
      },
      showExtra(Component, props) {
        const id = uuid();
        // @ts-ignore: Not sure why TS doesn't accept this but it's fine I think?
        setExtras((extras) => [...extras, { id, Component, props }]);
        return id;
      },
      removeExtra(id: string) {
        setExtras((extras) => extras.filter((extra) => extra.id !== id));
      },
    };
  }, [onDismiss]);

  return (
    <context.Provider value={methods}>
      {children}
      <Toaster toasts={toasts} extras={extras} remove={methods.remove} />
    </context.Provider>
  );
}
