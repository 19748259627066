import React from "react";

const CircleCheck = () => (
  <>
    <path
      d="M2 10c0-4.406 3.563-8 8-8 4.406 0 8 3.594 8 8 0 4.438-3.594 8-8 8-4.438 0-8-3.563-8-8Z"
      fill="currentColor"
    />
    <path
      d="M13.594 7.406a.85.85 0 0 1 0 1.219l-4 4a.849.849 0 0 1-1.219 0l-2-2a.85.85 0 0 1 0-1.219.85.85 0 0 1 1.219 0L9 10.781l3.375-3.375a.85.85 0 0 1 1.219 0Z"
      fill="#fff"
    />
  </>
);

export default CircleCheck;
