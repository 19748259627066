import React, { FunctionComponent, PropsWithChildren } from "react";

import LoadingSpinner from "../../../../../shared/components/design-system/LoadingSpinner";
import "./styles.scss";

type LoadingOverlayProps = PropsWithChildren<{
  /** When true, overlay will take full width/height of the first parent with a relative position and overlay all of its content */
  visible: boolean;
}>;

const LoadingOverlay: FunctionComponent<LoadingOverlayProps> = ({ visible, children }) => (
  <div className={`c-loading-overlay ${visible ? "is-visible" : ""}`}>
    <LoadingSpinner size="large">{children}</LoadingSpinner>
  </div>
);

export default LoadingOverlay;
