import React, { FunctionComponent, PropsWithChildren } from "react";

import "./styles.scss";

export type LoadingSpinnerProps = PropsWithChildren<{
  size?: "extra-small" | "small" | "medium" | "large";
  className?: string;
}>;

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({ size = "medium", className, children }) => (
  <div className={`c-loading-spinner ${size ? "is-" + size : ""} ${className ?? ""}`}>
    <div className="c-loading-spinner__spinner"></div>
    {children ? <p className="c-loading-spinner__text">{children}</p> : null}
  </div>
);

export default LoadingSpinner;
