import React, { PropsWithChildren } from "react";

import { TranslationSet } from "../../../db/models/Question";
import { useLanguage } from "../../../shared/components/translation";

type Props = PropsWithChildren<{
  translations: TranslationSet | undefined;
}>;

export function translateDbString(translations: TranslationSet | undefined, language: string) {
  if (!translations) {
    return null;
  }
  return translations.find((translation) => translation.language === language)?.text ?? null;
}

export function Translated({ translations, children }: Props) {
  const language = useLanguage();
  return <>{translateDbString(translations, language) ?? children ?? null}</>;
}
