import HttpError from "../../HttpError";

async function request<T>(url: string, options: RequestInit) {
  const res = await fetch(url, options);

  if (!res.ok) {
    try {
      const json = await res.json();
      throw new HttpError(res.status, json?.error ?? res.statusText);
    } catch {
      throw new HttpError(res.status, res.statusText);
    }
  }

  return (await res.json()) as T;
}

export function get<T>(endpoint: string, authCode: string) {
  return request<T>(`/api${endpoint}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${authCode}`,
    },
  });
}

export function post<T, Body = unknown>(endpoint: string, authCode: string, body: Body) {
  return request<T>(`/api${endpoint}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${authCode}`,
    },
    body: JSON.stringify(body),
  });
}

export function del<T>(endpoint: string, authCode: string) {
  return request<T>(`/api${endpoint}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${authCode}`,
    },
  });
}
