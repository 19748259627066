import React from "react";

import { Message as MessageType } from "../../hooks/useMessages";
import Message from "../Message/Message";
import styles from "./styles.module.scss";

function MessagingPanel({ messages }: { messages: MessageType[] }) {
  return (
    <div className={styles.messagesContainer}>
      <div className={styles.messagesList}>
        {messages.map((message) => (
          <Message message={message} key={message.id} />
        ))}
      </div>
    </div>
  );
}

export default MessagingPanel;
