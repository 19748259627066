// Probably some of these are defined in Lexical but that's not currently a dependency, absolutely feel free to import its types when it is
import { BlockProperties, ChildNode } from "./base-types";
import { InDetailChartBlock, OverTimeChartBlock } from "./chart-block-types";

export const UNFORMATTED = 0;
export const BOLD = 1;
export const ITALIC = 2;
export const UNDERLINE = 8;
// 4 is presumably STRIKETHROUGH but I don't think we want to implement that. I don't particularly want to implement underline either but there's a guessable keyboard shortcut for it so until we work out how to turn it off I think we're stuck with it.
// TODO: there's some discussion of how to disable in-built features at https://lightrun.com/answers/facebook-lexical-featurequestion-unclear-how-to-customize-official-plugins

export interface StyledTextBlock extends ChildNode {
  type: "text";
  version: 1;
  detail: 0;
  mode: "normal";
  style: "";
  format: number;
  text: string;
}

// This acts as a block because it has children, which means it needs to have all the other block properties too (as per Lexical's typings) but its other block properties can be ignored.
export interface Link extends BlockProperties<StyledTextBlock> {
  type: "link";
  version: 1;
  rel: null;
  target: null;
  url: string;
  text: string;
}

export interface LineBreak {
  type: "linebreak";
  version: 1;
  text?: string;
}

export type TextBlock = StyledTextBlock | LineBreak | Link;

// These are particular node types that use the block generator in the util.tsx file and store all their data in a csConfig property
export type BlockBlock = InDetailChartBlock | OverTimeChartBlock;

export interface Paragraph extends BlockProperties<TextBlock> {
  type: "paragraph";
  version: 1;
}

export interface Heading extends BlockProperties<TextBlock> {
  type: "heading";
  version: 1;
  tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

export interface ListItem extends BlockProperties<TextBlock | List> {
  type: "listitem";
  version: 1;
  children: [List] | TextBlock[];
  value: number;
}

// In Lexical, listType can also be "check" for Markdown-style checklists, but we don't support that here
interface ListBase extends BlockProperties<ListItem> {
  type: "list";
  version: 1;
  start: 1;
}
export interface UnorderedList extends ListBase {
  listType: "bullet";
  tag: "ul";
}

interface NestedEditor {
  //storing the content of nested editor via string is the supported way of doing this. Trying to store it in a strongly typed way would look neater but would probably cause problems
  json: string;
  direction: "ltr";
  format: "";
  version: number;
  indent: number;
  id: string;
  type: string;
}

export interface Table {
  type: "table";
  version: 1;
  rows: TableRow[];
  indent: number;
  rowHeader: boolean;
  columnHeader: boolean;
  caption: Caption;
}
export interface TableRow {
  version: 1;
  cells: TableCell[];
  id: string;
}
export interface TableCell extends NestedEditor {
  type: "cell";
}

export interface Image {
  type: "image";
  version: 1;
  src: string;
  altText: string;
  indent: number;
  caption: Caption;
}

export interface HtmlSnippet {
  type: "html-snippet";
  version: 1;
  src: string;
  indent: number;
}

export interface Caption extends NestedEditor {
  type: "caption";
}

export interface OrderedList extends ListBase {
  listType: "number";
  tag: "ol";
}
export type List = UnorderedList | OrderedList;

export type TopLevelNode = Heading | Paragraph | List | BlockBlock | Table | Image | HtmlSnippet;
export type Block = TopLevelNode | TextBlock;

export interface RootNode extends BlockProperties<TopLevelNode> {
  type: "root";
  version: 1;
  indent: 0;
}

export interface RichTextDocument {
  version: 1;
  root: RootNode;
}

export const EmptyDocument: RichTextDocument = {
  version: 1,
  root: {
    children: [
      {
        children: [],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
      },
    ],
    direction: "ltr",
    format: "",
    indent: 0,
    type: "root",
    version: 1,
  },
};

// Basic helper methods to turn (lists of) strings into Lexical documents.

export function unformattedDocument(...paragraphs: string[]): RichTextDocument {
  return document(...paragraphs.map(unformattedParagraph));
}

export function unformattedParagraph(text: string): Paragraph {
  return {
    type: "paragraph",
    version: 1,
    children: [textBlock(text)],
    direction: "ltr",
    format: "",
    indent: 0,
  };
}

export function paragraph(...children: TextBlock[]): Paragraph {
  return {
    type: "paragraph",
    version: 1,
    children,
    direction: "ltr",
    format: "",
    indent: 0,
  };
}

export function unformattedList(...items: string[]): List {
  return {
    type: "list",
    version: 1,
    listType: "bullet",
    start: 1,
    children: items.map((text, i) => unformattedListItem(text, i + 1)),
    direction: "ltr",
    format: "",
    indent: 0,
    tag: "ul",
  };
}

export function unformattedListItem(text: string, value: number): ListItem {
  return {
    type: "listitem",
    version: 1,
    children: [textBlock(text)],
    value,
    direction: "ltr",
    format: "",
    indent: 0,
  };
}

export function textBlock(text: string, format = UNFORMATTED): StyledTextBlock {
  return {
    type: "text",
    version: 1,
    format,
    text,
    detail: 0,
    mode: "normal",
    style: "",
  };
}

export function document(...children: TopLevelNode[]): RichTextDocument {
  return {
    version: 1,
    root: {
      type: "root",
      version: 1,
      children,
      direction: "ltr",
      format: "",
      indent: 0,
    },
  };
}
