import React, { PropsWithChildren, useContext } from "react";

import { Translatable, Translation } from "../../translation";
import Button from "../Button";
import { dialogContext } from "../Dialog";
import { ActionGroup } from "./ActionGroup";
import styles from "./styles.module.scss";

export type SectionHeaderProps = PropsWithChildren<{
  title: Translatable;
  visuallyHideTitle?: boolean;
  className?: string;
}>;

export function SectionHeader({ title, visuallyHideTitle, children, className = "" }: SectionHeaderProps) {
  const { dialogId, onClickClose, titleRef } = useContext(dialogContext);
  return (
    <header className={`${styles.Header} ${className}`}>
      <div className={styles.TitleWithActionButtons}>
        <h2
          tabIndex={-1}
          id={dialogId ? `modal-${dialogId}-title` : undefined}
          className={`
            ds-h3
            ds-m-0
            ${styles.Title}
            ${visuallyHideTitle ? "is-sr-only" : ""}
          `}
          ref={titleRef ?? undefined}
        >
          <Translation props={title} />
        </h2>
        <div>
          <ActionGroup className={styles.ActionButtons}>{children}</ActionGroup>
        </div>
      </div>

      {onClickClose ? (
        <Button
          variant="ghost"
          iconButton
          icon="xmarkLarge"
          label="Close"
          onClick={onClickClose}
          className={styles.CloseButton}
          testId="dialog-close"
          size="small"
        />
      ) : null}
    </header>
  );
}

export function SectionFooter({ children }: PropsWithChildren<unknown>) {
  return <footer className={styles.Footer}>{children}</footer>;
}
