import React from "react";

const FilePlus = () => (
  <path
    d="m15.406 4.938-2.344-2.344A1.988 1.988 0 0 0 11.656 2H6c-1.125 0-2 .906-2 2v12c0 1.125.875 2 2 2h8c1.094 0 2-.875 2-2V6.344c0-.532-.219-1.032-.594-1.407ZM14.5 16c0 .281-.25.5-.5.5H6a.494.494 0 0 1-.5-.5V4.031c0-.281.219-.5.5-.5h5V6c0 .563.438 1 1 1h2.5v9Zm-3.75-7a.76.76 0 0 0-.75-.75.74.74 0 0 0-.75.75v1.75H7.5a.74.74 0 0 0-.75.75c0 .406.313.719.75.719h1.75v1.75c0 .469.313.781.75.781.406 0 .75-.313.75-.719V12.25h1.75a.76.76 0 0 0 .75-.75c0-.375-.344-.719-.75-.719h-1.75V9Z"
    fill="currentColor"
  />
);

export default FilePlus;
