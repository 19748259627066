import React from "react";

const SortDescendingAlphabetical = () => (
  <path
    d="M16.9063 7.9375L14.6875 3.40625C14.4375 2.90625 13.5938 2.90625 13.3438 3.40625L11.125 7.9375C10.9375 8.28125 11.0938 8.75 11.4688 8.9375C11.8438 9.125 12.2813 8.96875 12.4688 8.59375L12.625 8.25H15.4375L15.625 8.59375C15.75 8.84375 16.0313 9 16.2813 9C16.4063 9 16.5313 9 16.625 8.9375C16.9375 8.75 17.0938 8.28125 16.9063 7.9375ZM13.3125 6.75L13.9688 5.4375L14.6563 6.75H13.3125ZM15.7188 15.5H13.7813L16.3125 12.1875C16.5 11.9688 16.5313 11.6562 16.4063 11.4062C16.2813 11.1562 16.0313 11 15.75 11H12.25C11.8438 11 11.5313 11.3125 11.5313 11.75C11.5313 12.1562 11.875 12.5 12.25 12.5H14.2188L11.6563 15.8125C11.4688 16.0312 11.4375 16.3438 11.5625 16.5938C11.6875 16.8438 11.9375 17 12.2188 17H15.6875C16.0938 17 16.4063 16.6875 16.4063 16.25C16.4063 15.8438 16.1563 15.5 15.7188 15.5ZM6.53125 3.25C6.25 2.9375 5.71875 2.9375 5.4375 3.25L2.4375 6.5C2.15625 6.8125 2.15625 7.28125 2.46875 7.5625C2.625 7.6875 2.8125 7.75 3 7.75C3.1875 7.75 3.375 7.6875 3.53125 7.53125L5.25 5.6875V16.25C5.25 16.6875 5.5625 17 6 17C6.40625 17 6.75 16.6875 6.75 16.25V5.6875L8.4375 7.53125C8.71875 7.8125 9.1875 7.84375 9.5 7.5625C9.8125 7.28125 9.8125 6.8125 9.53125 6.5L6.53125 3.25Z"
    fill="currentColor"
  />
);

export default SortDescendingAlphabetical;
