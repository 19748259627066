import React from "react";

const OctogonExclamation = () => (
  <>
    <path
      d="M6.384 2.658A2.225 2.225 0 0 1 7.98 2h4.04c.595 0 1.158.22 1.596.658l3.727 3.726c.438.438.657 1.002.657 1.596v4.04c0 .595-.22 1.158-.657 1.596l-3.727 3.727A2.225 2.225 0 0 1 12.02 18H7.98a2.23 2.23 0 0 1-1.596-.657l-3.726-3.727A2.225 2.225 0 0 1 2 12.02V7.98c0-.594.22-1.158.658-1.596l3.726-3.726Z"
      fill="currentColor"
    />
    <path
      d="M9.264 10.235V6.728c0-.407.313-.752.752-.752.407 0 .751.345.751.752v3.507a.742.742 0 0 1-.751.751.724.724 0 0 1-.752-.751Zm-.25 2.755c0-.532.438-1.002 1.002-1.002.532 0 1.002.47 1.002 1.002 0 .564-.47 1.002-1.002 1.002a.99.99 0 0 1-1.002-1.002Z"
      fill="#fff"
    />
  </>
);

export default OctogonExclamation;
