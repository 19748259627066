import React, { FunctionComponent } from "react";

const ArrowRightIcon: FunctionComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 21">
    <polyline
      points="1.5 1.5 10.5 10.5 1.5 19.5"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "3px",
      }}
    />
  </svg>
);

export default ArrowRightIcon;
