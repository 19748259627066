import React from "react";

const Clone = () => (
  <path
    d="M4 16.5h7c.25 0 .5-.219.5-.5v-2H13v2c0 1.125-.906 2-2 2H4c-1.125 0-2-.875-2-2V9c0-1.094.875-2 2-2h2v1.5H4c-.281 0-.5.25-.5.5v7c0 .281.219.5.5.5ZM7 4c0-1.094.875-2 2-2h7c1.094 0 2 .906 2 2v7c0 1.125-.906 2-2 2H9c-1.125 0-2-.875-2-2V4Zm2 7.5h7c.25 0 .5-.219.5-.5V4c0-.25-.25-.5-.5-.5H9c-.281 0-.5.25-.5.5v7c0 .281.219.5.5.5Z"
    fill="currentColor"
  />
);

export default Clone;
