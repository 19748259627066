import React, { CSSProperties, useEffect, useRef, useState } from "react";

import { GetLinkFunction } from "../../InDetailTable";
import { InDetailChartOptions, InDetailData } from "../../types";
import ChartRow from "./ChartRow";

const MIN_GRIDLINE_SPACING = 100;

// This should be enough for orgs with <12,000,000 reports but we might have to replace it with a function that generates numbers if we allow percentages because they can be arbitrarily small. They might need a different approach.
const GRIDLINE_INTERVALS = [
  1, 2, 5, 10, 20, 25, 40, 50, 100, 200, 250, 400, 1000, 2000, 2500, 4000, 5000, 10000, 20000, 25000, 40000, 50000,
  100000, 200000, 250000, 400000, 500000, 1000000,
];

function* placeGridlines(maxValue: number, length: number) {
  const maxGridlines = length / MIN_GRIDLINE_SPACING;
  const interval = GRIDLINE_INTERVALS.find((x) => x * maxGridlines > maxValue) ?? 2000000;
  for (let x = interval; x <= maxValue; x += interval) yield x;
}

export default function ChartBody({
  data,
  chartOptions,
  getLink,
}: {
  data: InDetailData;
  chartOptions: InDetailChartOptions;
  getLink?: GetLinkFunction;
}) {
  const ref = useRef<HTMLDivElement>(null);

  const maxValue = getMaxValue(data, chartOptions.showOuterBars);

  const [gridlines, setGridlines] = useState<number[]>([...placeGridlines(maxValue, 400)]);
  useEffect(() => {
    function listener() {
      const referenceElement = ref.current;
      if (!referenceElement) {
        setGridlines([]);
        return;
      }
      // TODO: it'd be nice to include a gridline within the right margin if one fits
      setGridlines([...placeGridlines(maxValue, referenceElement.clientWidth)]);
    }
    window.addEventListener("resize", listener);
    requestAnimationFrame(listener);
    return () => window.removeEventListener("resize", listener);
  }, [maxValue]);

  return (
    <div
      className={`
        c-in-detail-bar-chart__body-container
        ${chartOptions.showOuterBars ? "c-in-detail-bar-chart__body-container--with-outer-bars" : ""}
        ${chartOptions.showNumbers ? "c-in-detail-bar-chart__body-container--with-numbers" : ""}
        ${chartOptions.showPercentages ? "c-in-detail-bar-chart__body-container--with-percentages" : ""}
      `}
      style={{ "--max-value": maxValue } as CSSProperties}
    >
      <div className="c-in-detail-bar-chart__gridline-label-gutter" ref={ref}>
        <div className="c-in-detail-bar-chart__gridline-label" style={{ "--value": "0" } as CSSProperties}>
          0
        </div>
        {gridlines.map((n) => (
          <div key={n} className="c-in-detail-bar-chart__gridline-label" style={{ "--value": n } as CSSProperties}>
            {n}
          </div>
        ))}
      </div>
      <div className="c-in-detail-bar-chart__chart-body">
        {gridlines.map((n) => (
          <div key={n} className="c-in-detail-bar-chart__gridline" style={{ "--value": n } as CSSProperties} />
        ))}
        {data.categories.map((category, i) => (
          <ChartRow key={i} data={data} categoryIndex={i} options={chartOptions} link={getLink?.(i)} />
        ))}
      </div>
    </div>
  );
}

function getMaxValue(data: InDetailData, showOuterBars: boolean) {
  if (showOuterBars || !data.splits) {
    return Math.max(...data.categories.map((category) => category.count));
  }

  return Math.max(
    ...data.categories.flatMap((category) => category.segments?.map((split) => split.count) ?? [category.count]),
  );
}
