import React from "react";

const Sliders = () => (
  <path
    d="M2 13.5a.74.74 0 0 1 .75-.75h1.844C4.906 11.75 5.875 11 7 11c1.094 0 2.063.75 2.375 1.75h7.875a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75H9.375A2.485 2.485 0 0 1 7 16c-1.125 0-2.094-.719-2.406-1.75H2.75A.722.722 0 0 1 2 13.5Zm6 0c0-.531-.469-1-1-1-.563 0-1 .469-1 1 0 .563.438 1 1 1 .531 0 1-.438 1-1ZM13 4c1.094 0 2.063.75 2.375 1.75h1.875a.76.76 0 0 1 .75.75.74.74 0 0 1-.75.75h-1.875A2.485 2.485 0 0 1 13 9c-1.125 0-2.094-.719-2.406-1.75H2.75A.722.722 0 0 1 2 6.5a.74.74 0 0 1 .75-.75h7.844C10.906 4.75 11.875 4 13 4Zm1 2.5c0-.531-.469-1-1-1-.563 0-1 .469-1 1 0 .563.438 1 1 1 .531 0 1-.438 1-1Z"
    fill="currentColor"
  />
);

export default Sliders;
