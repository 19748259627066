import React from "react";

const Link = () => (
  <path
    d="M0 10C0 7.25 2.21875 5 5 5H8.25C8.65625 5 9 5.34375 9 5.75C9 6.1875 8.65625 6.5 8.25 6.5H5C3.0625 6.5 1.5 8.09375 1.5 10C1.5 11.9375 3.0625 13.5 5 13.5H8.25C8.65625 13.5 9 13.8438 9 14.25C9 14.6875 8.65625 15 8.25 15H5C2.21875 15 0 12.7812 0 10ZM15 15H11.75C11.3125 15 11 14.6875 11 14.25C11 13.8438 11.3125 13.5 11.75 13.5H15C16.9062 13.5 18.5 11.9375 18.5 10C18.5 8.09375 16.9062 6.5 15 6.5H11.75C11.3125 6.5 11 6.1875 11 5.75C11 5.34375 11.3125 5 11.75 5H15C17.75 5 20 7.25 20 10C20 12.7812 17.75 15 15 15ZM13.25 9.25C13.6562 9.25 14 9.59375 14 10C14 10.4375 13.6562 10.75 13.25 10.75H6.75C6.3125 10.75 6 10.4375 6 10C6 9.59375 6.3125 9.25 6.75 9.25H13.25Z"
    fill="currentColor"
  />
);

export default Link;
