export default function sendBeacon(
  objectType: string,
  objectId: string,
  page: number | null,
  fromPage: number | null,
  sessionId: string | null,
) {
  if (!navigator.sendBeacon) {
    return;
  }

  const beaconData = new URLSearchParams();
  beaconData.set("objectType", objectType);
  beaconData.set("objectId", objectId);
  if (sessionId) {
    beaconData.set("sessionId", sessionId);
  }
  if (page) {
    beaconData.set("page", page.toString());
  }
  if (fromPage) {
    beaconData.set("fromPage", fromPage.toString());
  }
  const search = new URLSearchParams(window.location.search);
  const source = search.get("source");
  if (source) beaconData.set("source", source);
  // Workaround https://bugs.chromium.org/p/chromium/issues/detail?id=747787
  navigator.sendBeacon("/beacon", new Blob([beaconData.toString()], { type: "application/x-www-form-urlencoded" }));
}
