import React from "react";

const Check = () => (
  <path
    d="M16.688 5.313a.964.964 0 0 1 0 1.406l-8 8a.964.964 0 0 1-1.407 0l-4-4a.964.964 0 0 1 0-1.406.964.964 0 0 1 1.406 0l3.282 3.28 7.312-7.28a.964.964 0 0 1 1.406 0Z"
    fill="currentColor"
  />
);

export default Check;
