import React from "react";

const MessageStrikeThrough = () => (
  <path
    d="M1.20774 2.21167L2.26968 3.02417C2.61325 2.43042 3.26916 2.02417 4.01876 2.02417H16.0125C17.1056 2.02417 18.0114 2.93042 18.0114 4.02417V13.0242C18.0114 13.7117 17.6679 14.3054 17.1369 14.6804L19.698 16.7117C20.0416 16.9617 20.1041 17.4304 19.823 17.7429C19.5731 18.0867 19.1046 18.1492 18.7923 17.8679L0.301964 3.36792C-0.0416058 3.11792 -0.104073 2.64917 0.177029 2.33667C0.426898 1.99292 0.895402 1.93042 1.20774 2.21167ZM3.51903 3.99292L15.6689 13.5242H16.0125C16.2623 13.5242 16.5122 13.3054 16.5122 13.0242V4.02417C16.5122 3.77417 16.2623 3.52417 16.0125 3.52417H4.01876C3.73766 3.52417 3.51903 3.74292 3.51903 3.99292ZM12.7017 15.0242H11.671L8.5164 17.3992L8.48517 17.4304L8.329 17.5242L7.79803 17.9304C7.64186 18.0554 7.45446 18.0554 7.26706 17.9929C7.11089 17.8992 7.01719 17.7429 7.01719 17.5242V15.0242H4.01876C2.89435 15.0242 2.01981 14.1492 2.01981 13.0242V6.61792L3.51903 7.80542V13.0242C3.51903 13.3054 3.73766 13.5242 4.01876 13.5242H7.01719C7.82926 13.5242 8.5164 14.2117 8.5164 15.0242V15.5242L10.7652 13.8367C10.8277 13.7742 10.9214 13.7429 10.9839 13.7117L12.7017 15.0242Z"
    fill="currentColor"
  />
);

export default MessageStrikeThrough;
