import React, { Fragment } from "react";

import { DeletedOption, OptionGroup } from "../../../../db/models/Question";
import Badge from "../../../../shared/components/design-system/Badge";
import Icon from "../../../../shared/components/design-system/Icon";
import { Translated } from "../../../translation/Translated";
import { useTranslate } from "../../../translation/frontend";
import { QuestionType, ReclassificationStatus } from "../../../utils/types";
import { AnswerList } from "./AnsweredQuestion";
import styles from "./styles.module.scss";

function isOptionGroup(option: unknown): option is OptionGroup {
  return (option as OptionGroup).options !== undefined;
}

function isDeletedOption(option: OptionGroup | DeletedOption): option is DeletedOption {
  return !("options" in option);
}

export default function OptionsAnswer({ answers, questionType }: { answers: AnswerList; questionType: QuestionType }) {
  const translate = useTranslate();

  return (
    <div className={styles.reportAnswers}>
      {answers.map(({ title, answers }, i) => (
        <Fragment key={i}>
          {answers.map((answer, index) => {
            const optionTranslationValue =
              title && isDeletedOption(title) && title.value === answer.optionText && title.valueTranslations
                ? title.valueTranslations
                : title && isOptionGroup(title)
                ? title.options?.find(({ value }) => value === answer.optionText)?.valueTranslations
                : undefined;

            return (
              <div className={styles.answerWrapper} key={index}>
                {answer.redacted || answer.reclassifiedStatus === ReclassificationStatus.Removed ? (
                  <Icon icon={null} size={20} className={styles.reportAnswerIcon} />
                ) : (
                  <Icon
                    icon={questionType === QuestionType.Radio ? "radioSelection" : "tick"}
                    size={20}
                    className={`${styles.reportAnswerIcon} ${
                      questionType === QuestionType.Radio ? styles.circular : null
                    }`}
                  />
                )}

                <div className={styles.redactableReportAnswer}>
                  {answer.redacted ? (
                    <Badge label={translate("redacted")} variant="warning" />
                  ) : (
                    <>
                      <span
                        className={`${styles.reportAnswer__text} ${
                          answer.reclassifiedStatus === ReclassificationStatus.Removed ? styles.removed : ""
                        }`}
                      >
                        {title && isOptionGroup(title) && title?.groupName ? (
                          <span>
                            <Translated translations={title.groupNameTranslations}>{title.groupName}</Translated>
                            <span className={styles.reportAnswer__textSeparator}>/</span>
                          </span>
                        ) : null}

                        <Translated translations={optionTranslationValue}>{answer.optionText}</Translated>

                        {answer.freeText ? (
                          <span>
                            <span className={styles.reportAnswer__textSeparator}>:</span> {answer.freeText}
                          </span>
                        ) : null}
                      </span>
                      <div className={styles.reportAnswer__badges}>
                        {answer.reclassifiedStatus !== ReclassificationStatus.AsReported ? (
                          <Badge
                            variant="warning"
                            label={
                              answer.reclassifiedStatus === ReclassificationStatus.Added
                                ? translate("added")
                                : translate("removed")
                            }
                          />
                        ) : null}
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </Fragment>
      ))}
    </div>
  );
}
