import React from "react";

const RadioSelection = () => (
  <path
    d="M14 10C14 12.225 12.2 14 10 14C7.775 14 6 12.225 6 10C6 7.8 7.775 6 10 6C12.2 6 14 7.8 14 10Z"
    fill="currentColour"
  />
);

export default RadioSelection;
