import fullSize from "../icons";
import Minus from "./Minus";
import Plus from "./Plus";

const iconComponents: Partial<typeof fullSize> = {
  minus: Minus,
  plus: Plus,
};

export default iconComponents;
