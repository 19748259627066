import React from "react";

const Folder = () => (
  <path
    d="M15.9688 5H10.5938L9.0625 3.59375C8.6875 3.21875 8.1875 3 7.65625 3H3.96875C2.84375 3 1.96875 3.90625 1.96875 5V15C1.96875 16.125 2.84375 17 3.96875 17H15.9688C17.0625 17 17.9688 16.125 17.9688 15V7C17.9688 5.90625 17.0938 5 15.9688 5ZM16.4688 15C16.4688 15.2812 16.2188 15.5 15.9688 15.5H3.96875C3.6875 15.5 3.46875 15.2812 3.46875 15V5C3.46875 4.75 3.6875 4.5 3.96875 4.5H7.625C7.75 4.5 7.875 4.5625 7.96875 4.65625L9.96875 6.5H15.9688C16.2188 6.5 16.4688 6.75 16.4688 7V15Z"
    fill="#383351"
  />
);

export default Folder;
