import React from "react";

const SortAscendingNumeric = () => (
  <path
    d="M13 7.5C12.5625 7.5 12.25 7.8125 12.25 8.25C12.25 8.65625 12.5625 9 12.9687 9H15.5C15.9062 9 16.25 8.65625 16.25 8.25C16.25 7.8125 15.9062 7.5 15.5 7.5H15V3.75C15 3.46875 14.8437 3.21875 14.5937 3.09375C14.375 2.9375 14.0937 2.96875 13.8437 3.09375L12.5937 3.84375C12.25 4.0625 12.125 4.53125 12.3437 4.875C12.5625 5.21875 13 5.34375 13.375 5.125L13.4687 5.0625V7.5H13ZM14.25 10C12.7187 10 11.5 11.25 11.5 12.75C11.5 14.0937 12.4062 15.1875 13.625 15.4375L13.1562 16.0312C12.9062 16.375 12.9375 16.8437 13.25 17.0937C13.4062 17.2187 13.5625 17.25 13.75 17.25C13.9375 17.25 14.1562 17.1562 14.3125 17C16.5312 14.2812 17 13.9687 17 12.75C17 11.25 15.75 10 14.25 10ZM15.1562 13.5937L15.0937 13.6562C14.875 13.875 14.5625 14 14.25 14C13.5312 14 13 13.4687 13 12.75C13 12.0625 13.5312 11.5 14.25 11.5C14.9375 11.5 15.5 12.0625 15.5 12.75C15.5 13.0937 15.3437 13.375 15.1562 13.5937ZM8.43747 12.5L6.74997 14.3437V3.78125C6.74997 3.34375 6.40622 3 5.99997 3C5.56247 3 5.24997 3.34375 5.24997 3.78125V14.3437L3.53122 12.5C3.37497 12.3437 3.18747 12.2812 2.99997 12.2812C2.81247 12.2812 2.62497 12.3437 2.46872 12.4687C2.15622 12.75 2.15622 13.2187 2.43747 13.5312L5.40622 16.7812C5.68747 17.0937 6.21872 17.0937 6.49997 16.7812L9.46872 13.5312C9.74997 13.2187 9.74997 12.75 9.43747 12.4687C9.18747 12.1875 8.71872 12.2187 8.43747 12.5Z"
    fill="currentColor"
  />
);

export default SortAscendingNumeric;
