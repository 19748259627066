// @ts-ignore - on the server we force this to become @sentry/node which confuses TypeScript
import * as Sentry from "@sentry/browser";
import React, { MouseEvent, useCallback } from "react";

import { useTranslate } from "../translation/frontend";
import { useForm } from "./context";
import ArrowRightIcon from "./icons/ArrowRightIcon";

export default function SkipToEndButton({ disabled }: { disabled: boolean }) {
  const translate = useTranslate();
  const { advanceToPage, page, pages } = useForm();

  const targetPage = pages.findIndex((p) => p.id === page.allowSkipToPage) + 1;

  // In therory the only value page.allowSkipToPage should be able to have when we get here that isn't a page ID is "submit". If we don't find a matching ID then assume it's that — if it's not then this is probably a reasonable fallback behaviour.
  const submitImmediately = !targetPage;
  if (submitImmediately && page.allowSkipToPage !== "submit") {
    Sentry.captureMessage("Unexpected value for allowSkipToPage", { extra: { allowSkipToPage: page.allowSkipToPage } });
  }

  const onClick = useCallback(
    async (ev: MouseEvent<HTMLButtonElement>) => {
      Sentry.addBreadcrumb({ category: "clickedSkipToEnd", data: { targetPage, submitImmediately } });

      if (submitImmediately) {
        // @ts-ignore Set the same flag from onBack, so that the onSubmit handler doesn't override the form's default submit behaviour and take us to the next page.
        window.formEventHandled = true;
        return;
      }

      // We're skipping to a real page — don't let the browser submit the form, just do it with Javascript
      ev.preventDefault();
      await advanceToPage(targetPage);
    },
    [targetPage, submitImmediately, advanceToPage],
  );

  return (
    <button
      className="btn btn--skip-to-end btn--swipe btn--arrow"
      name="_action"
      value="skip-to-end"
      onClick={onClick}
      formNoValidate
      disabled={disabled}
    >
      <span>{translate(submitImmediately ? "reportNowLabel" : "skipToEndLabel")}</span>
      <span className="btn__arrow">
        <ArrowRightIcon />
      </span>
    </button>
  );
}
