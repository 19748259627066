import React, { FunctionComponent } from "react";

const EmptyStateIcon: FunctionComponent<{ colour?: string }> = ({ colour }) => (
  <svg width="168" height="74" viewBox="0 0 168 74" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M160.5 2.35034C164.366 2.35034 167.5 5.48435 167.5 9.35034C167.5 13.2163 164.366 16.3503 160.5 16.3503H120.5C124.366 16.3503 127.5 19.4843 127.5 23.3503C127.5 27.2163 124.366 30.3503 120.5 30.3503H142.5C146.366 30.3503 149.5 33.4843 149.5 37.3503C149.5 41.2163 146.366 44.3503 142.5 44.3503H132.326C127.452 44.3503 123.5 47.4843 123.5 51.3503C123.5 53.9277 125.5 56.261 129.5 58.3503C133.366 58.3503 136.5 61.4843 136.5 65.3503C136.5 69.2163 133.366 72.3503 129.5 72.3503H46.5C42.634 72.3503 39.5 69.2163 39.5 65.3503C39.5 61.4843 42.634 58.3503 46.5 58.3503H7.5C3.63401 58.3503 0.5 55.2163 0.5 51.3503C0.5 47.4843 3.63401 44.3503 7.5 44.3503H47.5C51.366 44.3503 54.5 41.2163 54.5 37.3503C54.5 33.4843 51.366 30.3503 47.5 30.3503H22.5C18.634 30.3503 15.5 27.2163 15.5 23.3503C15.5 19.4843 18.634 16.3503 22.5 16.3503H62.5C58.634 16.3503 55.5 13.2163 55.5 9.35034C55.5 5.48435 58.634 2.35034 62.5 2.35034H160.5ZM160.5 30.3503C164.366 30.3503 167.5 33.4843 167.5 37.3503C167.5 41.2163 164.366 44.3503 160.5 44.3503C156.634 44.3503 153.5 41.2163 153.5 37.3503C153.5 33.4843 156.634 30.3503 160.5 30.3503Z"
      fill="#E5E3F6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M47.7643 72.4816H43.2444C41.0352 72.4816 39.2444 70.6908 39.2444 68.4816C39.2444 67.7288 39.4569 66.9912 39.8574 66.3537L79.1129 3.87255C80.2881 2.00197 82.7573 1.43828 84.6278 2.61353C85.1368 2.93328 85.5671 3.36362 85.8869 3.87255L125.142 66.3537C126.318 68.2242 125.754 70.6934 123.883 71.8686C123.246 72.2692 122.508 72.4816 121.755 72.4816H61.4135H47.7643ZM57.138 72.4816H51.5465H57.138Z"
      fill="white"
    />
    <path
      d="M57.138 72.4816H51.5465M47.7643 72.4816H43.2444C41.0352 72.4816 39.2444 70.6908 39.2444 68.4816C39.2444 67.7288 39.4569 66.9912 39.8574 66.3537L79.1129 3.87255C80.2881 2.00197 82.7573 1.43828 84.6278 2.61353C85.1368 2.93328 85.5671 3.36362 85.8869 3.87255L125.142 66.3537C126.318 68.2242 125.754 70.6934 123.883 71.8686C123.246 72.2692 122.508 72.4816 121.755 72.4816H61.4135H47.7643Z"
      stroke="#7268CA"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M113.564 55.2121L121.138 67.4556C121.428 67.9253 121.283 68.5416 120.813 68.8321C120.655 68.9298 120.473 68.9816 120.287 68.9816H44.7126C44.1604 68.9816 43.7126 68.5339 43.7126 67.9816C43.7126 67.7958 43.7644 67.6136 43.8622 67.4556L66.2157 31.3125C77.3022 45.7103 94.3747 55.0042 113.564 55.2121Z"
      fill="#E5E3F6"
    />
    <path
      d="M85.7499 47.1003V25.1003C85.7499 23.3054 84.2948 21.8503 82.4999 21.8503C80.705 21.8503 79.2499 23.3054 79.2499 25.1003V47.1003C79.2499 48.8953 80.705 50.3503 82.4999 50.3503C84.2948 50.3503 85.7499 48.8953 85.7499 47.1003Z"
      fill="#E5E3F6"
      stroke="#7268CA"
      stroke-width="2.5"
      stroke-linecap="round"
    />
    <path
      d="M82.4999 61.8503C84.2948 61.8503 85.7499 60.3953 85.7499 58.6003C85.7499 56.8054 84.2948 55.3503 82.4999 55.3503C80.705 55.3503 79.2499 56.8054 79.2499 58.6003C79.2499 60.3953 80.705 61.8503 82.4999 61.8503Z"
      fill="#E5E3F6"
      stroke="#7268CA"
      stroke-width="2.5"
      stroke-linecap="round"
    />
    <path
      d="M113.352 15.2542C115.008 15.2542 116.352 13.911 116.352 12.2542C116.352 10.5973 115.008 9.25415 113.352 9.25415C111.695 9.25415 110.352 10.5973 110.352 12.2542C110.352 13.911 111.695 15.2542 113.352 15.2542Z"
      stroke="#ADA7E0"
      stroke-width="2"
    />
    <path
      d="M138.16 26.5236C139.817 26.5236 141.16 25.1804 141.16 23.5236C141.16 21.8667 139.817 20.5236 138.16 20.5236C136.503 20.5236 135.16 21.8667 135.16 23.5236C135.16 25.1804 136.503 26.5236 138.16 26.5236Z"
      fill="#ADA7E0"
    />
    <path
      d="M116.705 28.402L125.063 36.7602M125.19 28.402L116.832 36.7602L125.19 28.402Z"
      stroke="#ADA7E0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.4146 34.7159L41.4145 40.7159M41.4145 34.7159L35.4146 40.7159L41.4145 34.7159Z"
      stroke="#ADA7E0"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M49.0107 28.1198C50.6676 28.1198 52.0107 26.7766 52.0107 25.1198C52.0107 23.4629 50.6676 22.1198 49.0107 22.1198C47.3539 22.1198 46.0107 23.4629 46.0107 25.1198C46.0107 26.7766 47.3539 28.1198 49.0107 28.1198Z"
      fill="#ADA7E0"
    />
  </svg>
);

export default EmptyStateIcon;
