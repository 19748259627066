import React from "react";

const Underline = () => (
  <path
    d="M5.15 3.5h.75v6c0 2.781 2.219 5 5 5 2.75 0 5-2.219 5-5v-6h.75a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75h-3a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h.75v6c0 1.938-1.594 3.5-3.5 3.5a3.494 3.494 0 0 1-3.5-3.5v-6h.75a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75h-3a.74.74 0 0 0-.75.75c0 .438.313.75.75.75Zm12 13H4.65a.74.74 0 0 0-.75.75c0 .438.313.75.75.75h12.5a.74.74 0 0 0 .75-.75.76.76 0 0 0-.75-.75Z"
    fill="currentColor"
  />
);

export default Underline;
