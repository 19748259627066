import React, { forwardRef, PropsWithChildren } from "react";

import "./styles.scss";

export interface ActionGroupProps {
  className?: string;
}

const ActionGroup = forwardRef<HTMLDivElement, PropsWithChildren<ActionGroupProps>>(({ children, className }, ref) => (
  <div className={`c-action-group ${className}`} ref={ref}>
    {children}
  </div>
));

export { ActionGroup };
