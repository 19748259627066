import React, { CSSProperties, forwardRef } from "react";

import { useTranslate } from "../../translation";
import { getBarColour } from "../InDetailBarChart/ChartBody/ChartRow";
import { InDetailSegmentDefinition } from "../types";

export const NO_TITLE = Symbol("No title");
export const DEFAULT_TITLE = Symbol("Default title");
export type LegendTitle = string | typeof NO_TITLE | typeof DEFAULT_TITLE;

const ChartLegend = forwardRef<HTMLDivElement, { splits: InDetailSegmentDefinition[]; title: LegendTitle }>(
  ({ splits, title }, ref) => {
    const translate = useTranslate();
    if (splits.length === 0) return null;

    return (
      <div ref={ref} className="c-chart-legend" aria-hidden>
        {title === NO_TITLE ? null : (
          <h3 className="c-chart-legend__header">{translate(title === DEFAULT_TITLE ? "chart:legendTitle" : title)}</h3>
        )}
        {splits.map(({ label }, i) => (
          <div key={i} className="c-chart-legend__item" style={{ "--colour": getBarColour(i) } as CSSProperties}>
            {label}
          </div>
        ))}
      </div>
    );
  },
);

export default ChartLegend;
