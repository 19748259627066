import React, { FunctionComponent } from "react";

const AlertInfoIcon: FunctionComponent<{ colour?: string }> = ({ colour }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <circle cx="12" cy="12" r="12" fill={colour ?? "#51BECA"} />
    <rect width="2" height="7" fill="#fff" rx="1" transform="translate(11 11)" />
    <rect width="2" height="3" fill="#fff" rx="1" transform="translate(11 6)" />
  </svg>
);

export default AlertInfoIcon;
